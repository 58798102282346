import React, { Component } from 'react';
import './ButtonDeletePost.scss';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
/*
import { UserRoute } from '../../Routing';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
*/
import DeleteIcon from '@material-ui/icons/Delete';
import DeletePostModal from './DeletePostModal/DeletePostModal';
import Utils from '../../utils/Utils';

class ButtonDeletePost extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this)
    }

    state = {
        dialogOpen: false,
    };

    async handleClose(success) {
        this.setState({ dialogOpen: false });
        let sub = await Utils.getCurrentUserSub();
        if (success) {
            // Redirect to the 
            window.location.replace("/user/" + sub);
        }
    }

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        const { dialogOpen } = this.state;
        const { postId } = this.props;
        return (
            <Box>
                <Button variant="contained" size="small" color="secondary" style={{ backgroundColor: "#CC0000" }} onClick={() => this.handleOpen()} >
                    <DeleteIcon />
                </Button>
                <DeletePostModal
                    open={dialogOpen}
                    postid={postId}
                    onClose={this.handleClose} />
            </Box>
        );
    }
}


ButtonDeletePost.propTypes = {
    postId: PropTypes.string,
};

export default ButtonDeletePost;
