// DB
import { API, graphqlOperation, Auth } from "aws-amplify";
import * as queries from '../graphql/queries';
import Utils from "./Utils";

class userHelper {
    static getUserObj(userSub) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.getUser, { cognitoSub: userSub }))
                .then(result => {
                    let user = Utils.checkNested(result, "data", "getUser");
                    resolve(user)

                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static async isWriter() {
        let session = await Auth.currentSession();
        let groups = Utils.checkNested(session, "idToken", "payload", "cognito:groups");
        if (groups !== null && groups.length > 0) {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === "Writer") {
                    return true
                }
            }
        }
        return false
    }

    static async isModo() {
        let session = await Auth.currentSession();
        let groups = Utils.checkNested(session, "idToken", "payload", "cognito:groups");
        if (groups !== null && groups.length > 0) {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === "BlogModo") {
                    return true
                }
            }
        }
        return false
    }
}

export default userHelper;