import React, { Component } from 'react';
import './UserMain.scss';
import Box from '@material-ui/core/Box';

import Utils from '../../utils/Utils';
import UserDetail from '../UserDetail/UserDetail';
import UserGallery from '../UserGallery/UserGallery';

import PropTypes from 'prop-types';

class UserMain extends Component {
    render() {
        const { ownProfile, userInfoToDisplay, loading } = this.props;
        return (
            <Box id="user-main">
                <Box className="flex-user-full">
                    <UserDetail ownProfile={ownProfile} loading={loading} userInfo={userInfoToDisplay} />
                </Box>
                <Box className="flex-user-gallery">
                    <UserGallery userSub={Utils.checkNested(userInfoToDisplay, 'cognitoSub')} isOwner={ownProfile} />
                </Box>
            </Box>
        );
    }
}

UserMain.propTypes = {
    userInfoToDisplay: PropTypes.object,
    ownProfile: PropTypes.bool,
    loading: PropTypes.bool,
};

export default UserMain;