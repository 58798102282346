import React, { Component } from 'react';
import './PostGalleryWrapper.scss';
import Box from '@material-ui/core/Box';
import Gallery from '../gallery/Gallery';
import Utils from '../../utils/Utils';

import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../customGraphql/mutations';

import PropTypes from 'prop-types';

class PostGalleryWrapper extends Component {
    state = {
        listImgKey: [],
    }

    constructor() {
        super();
        this.onAddImg = this.onAddImg.bind(this)
        this.onDeleteImg = this.onDeleteImg.bind(this)
    }

    componentDidMount() {
        this.setFromProps(null)
        if (Utils.checkNested(this.props, "listImgKey")) {
            this.setFromProps(this.props.listImgKey);
        }
    }

    componentDidUpdate(prevProps) {
        let listImgKeyFromProps = Utils.checkNested(this, "props", "listImgKey");
        let listImgKeyFromPrevProps = Utils.checkNested(prevProps, 'listImgKey');

        if (listImgKeyFromProps !== listImgKeyFromPrevProps) {
            this.setFromProps(listImgKeyFromProps);
        }
    }

    setFromProps(listImgKey) {
        let listToPush = [];
        if (listImgKey) listToPush = listImgKey;
        this.setState({
            listImgKey: listToPush,
        })
    }

    async onAddImg(newKey) {
        if (!this.props.postId) return

        let date = new Date().toISOString();
        let newList = this.state.listImgKey;
        let input = {
            id: this.props.postId,
            imageKey: newList,

            // The sort key has to be provide, and I want to update the updatedAt field so I have to provide all sort fields
            country: this.props.country,
            city: this.props.cityId,
            category: this.props.category,
            tag: this.props.tagId,
            subTag: this.props.subtagId,
            updatedAt: date,
        }
        try {
            await API.graphql(graphqlOperation(mutations.updateServiceImg, { input: input }));
        } catch (e) {
            console.log(e)
        }
    }

    async onDeleteImg(deletedKey) {
        if (!this.props.postId) return

        let date = new Date().toISOString();
        let newList = this.state.listImgKey;

        let input = {
            id: this.props.postId,
            imageKey: newList,

            // The sort key has to be provide, and I want to update the updatedAt field so I have to provide all sort fields
            country: this.props.country,
            city: this.props.cityId,
            category: this.props.category,
            tag: this.props.tagId,
            subTag: this.props.subtagId,
            updatedAt: date,
        }

        try {
            await API.graphql(graphqlOperation(mutations.updateServiceImg, { input: input }));
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { listImgKey } = this.state;
        const { isOwner, identityId } = this.props;
        return (
            <Box className="PostGalleryWrapper">
                <Gallery
                    onAdd={this.onAddImg}
                    onDelete={this.onDeleteImg}
                    listImgKey={listImgKey}
                    allowAdd={isOwner}
                    allowDelete={isOwner}
                    maxImgAllowed={5}
                    identityId={identityId}
                />
            </Box>
        );
    }
}

PostGalleryWrapper.propTypes = {
    postId: PropTypes.string,
    listImgKey: PropTypes.array,
    identityId: PropTypes.string,
    isOwner: PropTypes.bool,

    country: PropTypes.string, // Needed for the update
    cityId: PropTypes.string, // Needed for the update
    category: PropTypes.string, // Needed for the update
    tagId: PropTypes.string, // Needed for the update
    subtagId: PropTypes.string, // Needed for the update
};

export default PostGalleryWrapper;