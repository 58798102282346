import React, { Component } from 'react';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { Storage } from "aws-amplify";

// UI
import "./DeletePostModal.scss";
import { Box } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from '../../../utils/Utils';

class DeletePostModal extends Component {
    state = {
        loading: false,
        error: null,
    };
    handleCancel = () => {
        this.setState({ loading: false });
        this.props.onClose(false);
    };

    async handleDelete() {
        if (!this.props.postid) {
            this.setState({
                error: "Can not find the post to delete.",
            })
        }
        this.setState({ loading: true });

        try {
            // First get the image list
            const getService = await API.graphql(graphqlOperation(queries.getService, { id: this.props.postid }));
            if (!Utils.checkNested(getService, "data", "getService")) {
                this.setState({
                    loading: false,
                    error: "Can not find the post to delete.",
                })
                return
            }


            let listImgToDelete = getService.data.getService.imageKey;
            if (listImgToDelete && listImgToDelete.length > 0) {
                // Delete image on s3
                for (let i = 0; i < listImgToDelete.length; i++) {
                    let isDeleted = await Storage.remove(listImgToDelete[i], { level: 'protected' })
                        .then(result => { return result })
                        .catch(err => { return err });
                    console.log(isDeleted)
                }
            }
            // Now that all images are deleted on s3 delete the post on the db
            let deleteService = await API.graphql(graphqlOperation(mutations.deleteService, { input: { id: this.props.postid } }));
            console.log(deleteService)
        } catch (e) {
            console.log(e)
            this.setState({
                loading: false,
                error: "Unable to delete this post, please try later.",
            })
            return
        }

        this.setState({ loading: false });
        this.props.onClose(true);
    }

    render() {
        const {
            loading,
            error,
        } = this.state;
        const { ...other } = this.props;
        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                fullWidth
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
                className="DeletePostModal"
            >
                <DialogTitle className="modal-title">Are you sur you want to delete this post? This action cannot be undone.</DialogTitle>
                {loadingDisplay}
                {
                    error ?
                        <Box>{error}</Box> :
                        null
                }
                <DialogActions>
                    <Button variant="contained" size="small" color="secondary" style={{ float: 'left' }} onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" color="secondary" style={{ float: 'right', backgroundColor: '#CC0000' }} onClick={() => this.handleDelete()} >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

DeletePostModal.propTypes = {
    onClose: PropTypes.func,
    postid: PropTypes.string,
};

export default DeletePostModal;