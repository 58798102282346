import React, { Component } from 'react';
import './PostPriceWrapper.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';
import SetPrice from '../SetPrice/SetPrice';

class PostPriceWrapper extends Component {
    state = {
        price: null,
        currency: null,
        label: null,
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "price") ||
            Utils.checkNested(this, "props", "currency") ||
            Utils.checkNested(this, "props", "label") ||
            Utils.checkNested(this, "props", "editable")) {
            this.resetFromProps()
        }
    }

    componentDidUpdate(prevProps) {
        let priceFromProps = Utils.checkNested(this, "props", "price");
        let priceFromPrevProps = Utils.checkNested(prevProps, 'price');

        let currencyFromProps = Utils.checkNested(this, "props", "currency");
        let currencyFromPrevProps = Utils.checkNested(prevProps, 'currency');

        let labelFromProps = Utils.checkNested(this, "props", "label");
        let labelFromPrevProps = Utils.checkNested(prevProps, 'label');

        let editableFromProps = Utils.checkNested(this, "props", "editable");
        let editableFromPrevProps = Utils.checkNested(prevProps, 'editable');

        if (priceFromProps !== priceFromPrevProps ||
            currencyFromProps !== currencyFromPrevProps ||
            labelFromProps !== labelFromPrevProps ||
            editableFromProps !== editableFromPrevProps) {
            this.resetFromProps();
        }
    }

    resetFromProps() {
        this.setState({
            price: this.props.price,
            currency: this.props.currency,
            label: this.props.label,
            editable: this.props.editable,
        })
    }

    async onUpdate(priceObj) {
        if (!Utils.checkNested(this.props, 'postId')) return
        if (!Utils.checkNested(this.props, 'category')) return
        if (!Utils.checkNested(this.props, 'country')) return
        let date = new Date().toISOString();
        try {
            let input = {
                id: this.props.postId,
                country: this.props.country,
                city: this.props.cityId,
                category: this.props.category,
                tag: this.props.tagId,
                subTag: this.props.subtagId,
                price: priceObj.price,
                currency: priceObj.currency,
                priceLabel: priceObj.label,
                updatedAt: date,
            }

            let updatePrice = await API.graphql(graphqlOperation(mutations.updateServicePrice, { input: input }));

            if (Utils.checkNested(updatePrice, "data", "updateService", "price")) {
                this.setState({
                    price: updatePrice.data.updateService.price,
                    currency: updatePrice.data.updateService.currency,
                    label: updatePrice.data.updateService.priceLabel,
                })

                if (this.props.onUpdate) {
                    this.props.onUpdate(updatePrice.data.updateService)
                }
            }
        } catch (e) {
            console.log("Fail on update price")
            console.log(e)
        }
    }

    render() {
        const { price, currency, label } = this.state;
        const { editable } = this.props;

        let priceString = null;
        if (price) {
            priceString = price.toString();
        }

        return (
            <Box className="PostLocationWrapper" >
                <SetPrice
                    onChange={this.onUpdate}
                    price={priceString}
                    currency={currency}
                    label={label}
                    editable={editable}
                    loading={false}
                />
            </Box>
        );
    }
}

PostPriceWrapper.propTypes = {
    postId: PropTypes.string,
    editable: PropTypes.bool,
    price: PropTypes.string,
    currency: PropTypes.string,
    label: PropTypes.string,
    country: PropTypes.string, // Needed for the update
    cityId: PropTypes.string, // Needed for the update
    category: PropTypes.string, // Needed for the update
    tagId: PropTypes.string, // Needed for the update
    subtagId: PropTypes.string, // Needed for the update

    onUpdate: PropTypes.func,
};

export default PostPriceWrapper;