import React, { Component } from 'react';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../../graphql/mutations';

// UI
import "./AddPostModal.scss";
import { TextField, Box, Typography, Divider } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import SelectCountryCity from '../../SelectCountryCity/SelectCountryCity';
import SelectCategory from '../../SelectCategory/SelectCategory';
import SetPrice from '../../SetPrice/SetPrice';
import Utils from '../../../utils/Utils';
import uuid from "react-uuid";
import Countdown from '../../Countdown/Countdown';

class AddPostModal extends Component {
    state = {
        title: "",
        description: "",

        selectedCategory: null,
        selectedTag: null,
        selectedSubtag: null,

        price: "",
        priceCurrency: "",
        priceLabel: "",

        selectedCountry: null,
        selectedCityId: null,

        errors: [],
        loading: false,
        forceValidate: null,
    };

    constructor() {
        super();
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleCategoryChange = this.handleCategoryChange.bind(this)
        this.handlePriceChange = this.handlePriceChange.bind(this)
    }

    forceValidateForms() {
        this.setState({ forceValidate: !this.state.forceValidate });
    }

    handleCancel = () => {
        this.setState({ loading: false });
        this.props.onClose();
    };

    async handleConfirmation() {
        this.forceValidateForms();
        this.setState({ loading: true });
        // Let the time to the form to be passed from the child in case it wasn't closed
        setTimeout(function () {
            this.validateAndClose();
        }.bind(this), 1000);
    }

    async validateAndClose() {
        let errors = [];
        // Validate data
        // Price (nothing mandatory)
        // Location (At least a country)
        if (!this.state.selectedCountry) {
            errors.push("You need to define at least a country for your post.")
        }
        // Tags (At least a category)
        if (!this.state.selectedCategory) {
            errors.push("You need to define a category for your post.")
        }
        // Title
        if (this.state.title.length < 20) {
            errors.push("You need to define a title (at least 20 characters).")
        }
        if (errors.length > 0) {
            console.log(errors)
            this.setState({
                loading: false,
                errors: errors,
            })
        } else {
            // Push
            try {
                // Validate data
                let cognitoSub = await Utils.getCurrentUserSub();
                if (!cognitoSub) return;
                let title = this.state.title;
                if (!title || title === "") return
                let description = this.state.description;
                if (!description || description === "") description = " ";
                let country = this.state.selectedCountry;
                if (!country || country === "") return;
                let cityId = this.state.selectedCityId;
                if (!cityId || cityId === "") cityId = null;
                let price = this.state.price;
                if (!price || price === "") price = null;
                let currency = this.state.priceCurrency;
                if (!currency || currency === "") currency = null;
                let priceLabel = this.state.priceLabel;
                if (!priceLabel || priceLabel === "") priceLabel = " ";
                let category = this.state.selectedCategory;
                if (!category || category === "") return
                let tag = this.state.selectedTag;
                if (!tag || tag === "") tag = null;
                let subtag = this.state.selectedSubtag;
                if (!subtag || subtag === "") subtag = null;
                let date = new Date().toISOString();
                let timestamp = new Date().getTime();
                // Add price currency
                let input = {
                    id: timestamp.toString() + uuid(),
                    user: cognitoSub,
                    title: title,
                    description: description,
                    country: country,
                    city: cityId,
                    price: price,
                    currency: currency,
                    priceLabel: priceLabel,
                    category: category,
                    tag: tag,
                    subTag: subtag,
                    updatedAt: date,
                }

                let createService = await API.graphql(graphqlOperation(mutations.createService, { input: input }));

                console.log(createService)
            } catch (e) {
                console.log("Fail on update title")
                console.log(e)
            }
            // Stop loading
            this.setState({ loading: false });
            // Close
            this.props.onClose();
        }
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleEntering = () => {

    }

    handleCityChange(countryCityInfo) {
        if (countryCityInfo.city) {
            this.setState({ "selectedCountry": countryCityInfo.city.country, "selectedCityId": countryCityInfo.city.id });
        } else if (countryCityInfo.country) {
            this.setState({ "selectedCountry": countryCityInfo.country, "selectedCityId": null });
        }
    }

    handleCategoryChange(categoryInfo) {
        if (categoryInfo.subtag && categoryInfo.tag && categoryInfo.category) {
            this.setState({
                "selectedCategory": categoryInfo.category.value,
                "selectedTag": categoryInfo.tag.id,
                "selectedSubtag": categoryInfo.subtag.id,
            });
        } else if (categoryInfo.tag && categoryInfo.category) {
            this.setState({
                "selectedCategory": categoryInfo.category.value,
                "selectedTag": categoryInfo.tag.id,
                "selectedSubtag": null,
            });
        } else if (categoryInfo.category) {
            this.setState({
                "selectedCategory": categoryInfo.category.value,
                "selectedTag": null,
                "selectedSubtag": null,
            });
        }
    }

    handlePriceChange(priceObj) {
        this.setState({
            "price": priceObj.price,
            "priceCurrency": priceObj.currency,
            "priceLabel": priceObj.label,
        });
    }

    render() {
        const {
            title,
            description,

            price,
            priceCurrency,
            priceLabel,

            selectedCategory,
            selectedTag,
            selectedSubtag,

            selectedCountry,
            selectedCityId,

            errors,
            loading,
            forceValidate,
        } = this.state;
        const { isearlyadopter, ...other } = this.props;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                fullWidth
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
                className="AddPostModal"
            >
                <DialogTitle className="modal-title">Add a new service</DialogTitle>
                {
                    isearlyadopter ?
                        <DialogContent className="main-content">
                            <Typography className="headline selectors" >Select what define best your service, it will help people to find you:</Typography>
                            <SelectCategory
                                onChange={this.handleCategoryChange}
                                category={selectedCategory}
                                tagId={selectedTag}
                                subtagId={selectedSubtag}
                                alwaysOpen={true}
                                loading={loading}
                                forceValidate={forceValidate}
                            />

                            <Divider className="divider" />
                            <Typography className="headline selectors" >Where do you do it, if you can move in a whole country then don't specify the city:</Typography>
                            <SelectCountryCity
                                onChange={this.handleCityChange}
                                country={selectedCountry}
                                alwaysOpen={true}
                                cityId={selectedCityId}
                                loading={loading}
                                forceValidate={forceValidate}
                            />

                            <Divider className="divider" />
                            <Typography className="headline selectors" >Define your price:</Typography>
                            <SetPrice
                                onChange={this.handlePriceChange}
                                price={price}
                                currency={priceCurrency}
                                label={priceLabel}
                                editable={true}
                                loading={loading}
                                forceValidate={forceValidate}
                            />

                            <Divider className="divider" />
                            <Typography className="headline" >Add a title, be short and precise:</Typography>
                            <TextField
                                disabled={loading}
                                id="title-post"
                                label="Title"
                                value={title}
                                onChange={this.handleChange("title")}
                                margin="normal"
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 180 }}
                            />

                            <Divider className="divider" />
                            <Typography className="headline" >Here you can give more details:</Typography>
                            <TextField
                                disabled={loading}
                                id="description-post"
                                label="Description"
                                value={description}
                                onChange={this.handleChange("description")}
                                margin="normal"
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 3000 }}
                                rows={10}
                            />
                            <Divider className="divider" />
                            <Typography >Images can be added afterward</Typography>

                        </DialogContent>
                        :
                        <DialogContent>
                            <Typography className="headline selectors" >
                                Only early adopters and backers are allowed to offer their services during the first month.
                                <br />
                                <br />
                                You can either visit <a className="kickLink" href="https://www.kickstarter.com">our campaign</a> to become a backer and offer your service before everyone else.
                                Or you can wait the public opening at the end of the campaign 😊
                            </Typography>
                            <Box className='countdown'>
                                <Countdown title="Unlocking in"
                                    timeTillDate="12 25 2019, 6:00 am"
                                    timeFormat="MM DD YYYY, h:mm a" />
                            </Box>
                        </DialogContent>
                }
                <DialogContent>
                    {loadingDisplay}
                    <Box color="red">
                        <ul>
                            {errors.map((err, index) => {
                                return <li key={index}>{err}</li>
                            })}
                        </ul>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" size="small" color="secondary" style={{ float: 'left', backgroundColor: '#CC0000' }} onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button variant="contained" size="small" color="secondary" style={{ float: 'right' }} onClick={() => this.handleConfirmation()} >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddPostModal.propTypes = {
    onClose: PropTypes.func,
    isearlyadopter: PropTypes.bool,
};

export default AddPostModal;