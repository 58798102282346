import React, { Component } from 'react';
import './UserShort.scss';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Utils from '../../utils/Utils';
import UserNameTextField from '../UserNameTextField/UserNameTextField';
import DisplayStars from '../DisplayStars/DisplayStars';
import UserLocationWrapper from '../UserLocationWrapper/UserLocationWrapper';
import ButtonContact from '../ButtonContact/ButtonContact';

const styles = theme => ({
    nbrMissions: {
        marginLeft: theme.spacing(1),
    },
    alignRight: {
        textAlign: "right",
    },
});

class UserShort extends Component {
    render() {
        const { classes, loading, ownProfile, userInfo } = this.props;
        let cityId = null;
        if (Utils.checkNested(userInfo, "city") !== null) {
            cityId = userInfo.city;
        }

        let country = null;
        if (Utils.checkNested(userInfo, "country") !== null) {
            country = userInfo.country;
        }

        let username = null;
        if (Utils.checkNested(userInfo, "userName") !== null) {
            username = userInfo.userName;
        }

        let userId = null;
        if (Utils.checkNested(userInfo, "cognitoSub") !== null) {
            userId = userInfo.cognitoSub;
        }


        let rating = null;
        if (Utils.checkNested(userInfo, "rating") !== null) {
            rating = userInfo.rating;
        }

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" height="100%" id="pilot-short">
                {
                    loading === true ?
                        <div>Loading</div> :
                        <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" alignItems="center">

                            <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                                <Box flexGrow={1} className="">
                                    <UserNameTextField username={username} editable={ownProfile} />
                                </Box>
                                <Box className={classes.alignRight}>
                                    <DisplayStars rating={rating} />
                                </Box>
                            </Box>
                            <UserLocationWrapper
                                userId={userId}
                                country={country}
                                cityId={cityId}
                                editable={ownProfile}
                            />

                            {
                                ownProfile ?
                                    null
                                    :
                                    <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                                        <Box flexGrow={1} className={classes.alignRight}>
                                            <ButtonContact userIdToContact={userId} disabled={ownProfile} />
                                        </Box>
                                    </Box>
                            }

                        </Box>
                }
            </Box>
        );
    }
}

export default withStyles(styles)(UserShort);