import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import ClearIcon from '@material-ui/icons/Clear'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'
import MessageIcon from '@material-ui/icons/Message'
import DescriptionIcon from '@material-ui/icons/Description';
import HelpIcon from '@material-ui/icons/Help';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

// Routing
import { PostsRoute, UserRoute, ConversationsRoute, AgreementsRoute, LoginRoute, LandingRoute, HelpRoute, BlogRoute } from '../../Routing.js'

// Css
import './MainLayout.scss';

// Import react router
import { Link } from "react-router-dom";

// Need to log out in the drawer
import { Auth, Hub } from 'aws-amplify';
import ImgDisplay from '../../componants/imgDisplay/ImgDisplay';
import Utils from '../../utils/Utils';
//import defaultAvatar from '../../utils/defaultAvatar.png'
import defaultAvatar from '../../img/logoTextSquare.png'
import { guestUsername, guestPassword, guestSub } from '../../utils/guestInfo.js';
import { Box, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

const drawerWidth = 300;

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            //main: '#3AAFA9',
            main: '#008EAA',

            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#17252A',
        },
    },
});

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: "transparent",
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        marginLeft: drawerWidth,
    },
    title: {
        flexGrow: 1,
        textAlign: "right",
        fontFamily: '"Poppins", sans-serif',
        fontWeight: '700',
        textTransform: "uppercase",
        letterSpacing: "3px",
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },

    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        //backgroundColor: "#17252A",
        backgroundColor: "rgba(23, 37, 42, 1)",
        color: "#fefefe",
        border: "none",
        [theme.breakpoints.up('md')]: {
            backgroundColor: "rgba(23, 37, 42, 0.5)",
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        //backgroundColor: "#3AAFA9",
        //padding: theme.spacing.unit * 3,
        minHeight: "100vh",
    },
});

class MainLayout extends React.Component {
    state = {
        mobileOpen: false,
        currentMenu: "",
        userSub: null,
        identityId: null,
        isLogged: false,
    };

    componentDidMount() {
        this.getUserSub();
        Hub.listen('auth', this.getUserSub);
    }

    componentWillUnmount() {
        Hub.remove('auth', this.getUserSub);
    }

    async getUserSub() {
        let sub = await Utils.getCurrentUserSub();
        if (!sub) {
            await this.logAsGuest();
            sub = await Utils.getCurrentUserSub();
        }
        let identityId = await Utils.getIdentityIdFromSub(sub);
        let isLogged = false
        if (sub && sub !== guestSub) {
            isLogged = true;
        }
        try {
            this.setState({ userSub: sub, identityId: identityId, isLogged: isLogged });
        } catch (e) {
            console.log(e)
        }
    }

    async logAsGuest() {
        await Auth.signIn(guestUsername, guestPassword)
    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    async onSignOutClick() {
        await Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
        window.location.replace(PostsRoute);
    }

    render() {
        const { classes } = this.props;
        const { userSub, identityId, isLogged } = this.state;
        const drawer = (
            <div id="drawer-container">
                <div className="avatar-container">
                    <Link to={LandingRoute}>
                        <ImgDisplay defaultImgPath={defaultAvatar} imgKey={this.state.userSub} identityId={identityId} />
                    </Link>
                </div>
                <List>
                    <ListItem button component={Link} to={PostsRoute}>
                        <ListItemIcon><SearchIcon className={classes.icon} color="primary" /></ListItemIcon>
                        <ListItemText primary="Find a pilot" />
                    </ListItem>
                </List>
                {
                    isLogged ?
                        <div>
                            <Divider />
                            <List>
                                <ExpansionPanel style={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    margin: '0',
                                    padding: '0',
                                    border: 'none',
                                }}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: 'white',
                                            margin: '0',
                                            padding: '0 1.5em 0 0',
                                            border: 'none',
                                        }}
                                    >
                                        <ListItem>
                                            <ListItemIcon><PersonIcon className={classes.icon} color="primary" /></ListItemIcon>
                                            <ListItemText primary="My Account" />
                                        </ListItem>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <List>
                                            <ListItem button component={Link} to={UserRoute + userSub}>
                                                <ListItemIcon><PersonIcon className={classes.icon} color="primary" /></ListItemIcon>
                                                <ListItemText primary="Profile" />
                                            </ListItem>
                                            <Divider />
                                            <ListItem button component={Link} to={ConversationsRoute}>
                                                <ListItemIcon><MessageIcon className={classes.icon} color="primary" /></ListItemIcon>
                                                <ListItemText primary="Messages" />
                                            </ListItem>
                                            <Divider />
                                            <ListItem button component={Link} to={AgreementsRoute}>
                                                <ListItemIcon><DescriptionIcon className={classes.icon} color="primary" /></ListItemIcon>
                                                <ListItemText primary="eContracts" />
                                            </ListItem>
                                        </List>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </List>


                            <Divider />
                            <List>
                                <ListItem button component={Link} to={BlogRoute}>
                                    <ListItemIcon><FormatAlignLeftIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Blog" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem button component={Link} to={HelpRoute}>
                                    <ListItemIcon><HelpIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Help" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem>
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem button onClick={() => this.onSignOutClick()}>
                                    <ListItemIcon><ClearIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Log out" />
                                </ListItem>
                            </List>
                            <Divider />
                        </div>
                        :
                        <div>
                            <Divider />
                            <List>
                                <ListItem button component={Link} to={BlogRoute}>
                                    <ListItemIcon><FormatAlignLeftIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Blog" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem button component={Link} to={HelpRoute}>
                                    <ListItemIcon><HelpIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Help" />
                                </ListItem>
                            </List>

                            <Divider />
                            <List>
                                <ListItem>
                                </ListItem>
                            </List>
                            <Divider />
                            <List>
                                <ListItem button component={Link} to={LoginRoute}>
                                    <ListItemIcon><PersonIcon className={classes.icon} color="primary" /></ListItemIcon>
                                    <ListItemText primary="Log in" />
                                </ListItem>
                            </List>
                            <Divider />
                        </div>
                }
            </div>
        );
        return (
            <div>
                <div id="main-background" />
                <div id="main-layout">
                    <MuiThemeProvider theme={theme}>
                        <div className={classes.root}>
                            <CssBaseline />
                            <Hidden mdUp implementation="css">
                                <AppBar position="fixed" color="secondary" className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton
                                            color="inherit"
                                            aria-label="Open drawer"
                                            onClick={this.handleDrawerToggle}
                                            className={classes.menuButton}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography variant="h6" color="inherit" className={classes.title}>
                                            CLICK & DRONE
                                    </Typography>
                                    </Toolbar>
                                </AppBar>
                            </Hidden>
                            <nav className={classes.drawer}>
                                <Hidden mdUp implementation="css">
                                    <Drawer
                                        color="primary"
                                        className={classes.drawer}
                                        variant="temporary"
                                        open={this.state.mobileOpen}
                                        onClose={this.handleDrawerToggle}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        anchor="left"
                                    >
                                        {drawer}
                                    </Drawer>
                                </Hidden>
                                <Hidden smDown implementation="css">
                                    <Drawer
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        variant="permanent"
                                        open
                                    >
                                        {drawer}
                                    </Drawer>
                                </Hidden>
                            </nav>
                            <main className={classes.content} >
                                <Hidden mdUp implementation="css"><div className={classes.toolbar} /></Hidden>
                                {/*<Hidden smDown implementation="css"><div className="logo">CLICK & DRONE</div></Hidden>*/}
                                <Box width="100%" className="mainContent">{this.props.children}</Box>
                            </main>
                        </div>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}

MainLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainLayout);