import React, { Component } from 'react';
import './Footer.scss';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { TouRoute, CreditsRoute } from '../../Routing';
import { Container } from '@material-ui/core';

class Footer extends Component {
    render() {
        return (
            <Box id="footer">
                <Container maxWidth="sm">
                    <footer>
                        <Box display="flex" marginBottom={1.5} marginTop={1}>
                            <Box flexGrow={1} style={{ textAlign: "left" }}>
                                <Link to={TouRoute}>Terms of use</Link>
                            </Box>
                            <Box flexGrow={1} style={{ textAlign: "center" }}>
                                <i className="fa fa-envelope" /> <a href="mailto:contact@clickndrone.com">contact@clickndrone.com</a>
                            </Box>
                            <Box flexGrow={1} style={{ textAlign: "right" }}>
                                <Link to={CreditsRoute}>Credits</Link>
                            </Box>
                        </Box>
                        <div className="copyright">
                            &copy; Copyright <strong>ClicknDrone</strong>. All Rights Reserved
                        </div>
                    </footer>
                </Container>
            </Box>
        );
    }
}

export default Footer;
