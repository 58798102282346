/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReview = `mutation CreateReview($agreementId: ID!, $rating: Float!, $content: String) {
  createReview(agreementId: $agreementId, rating: $rating, content: $content) {
    id
    agreementId
    userFromSub
    userToSub
    postedAt
    content
    rating
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const createService = `mutation CreateService($input: CreateServiceInput!) {
  createService(input: $input) {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const updateService = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const deleteService = `mutation DeleteService($input: DeleteServiceInput!) {
  deleteService(input: $input) {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const createCity = `mutation CreateCity($input: CreateCityInput!) {
  createCity(input: $input) {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const updateCity = `mutation UpdateCity($input: UpdateCityInput!) {
  updateCity(input: $input) {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const deleteCity = `mutation DeleteCity($input: DeleteCityInput!) {
  deleteCity(input: $input) {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const createConversation = `mutation CreateConversation($input: CreateConversationInput!) {
  createConversation(input: $input) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const updateConversation = `mutation UpdateConversation($input: UpdateConversationInput!) {
  updateConversation(input: $input) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const deleteConversation = `mutation DeleteConversation($input: DeleteConversationInput!) {
  deleteConversation(input: $input) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const createMessage = `mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const updateMessage = `mutation UpdateMessage($input: UpdateMessageInput!) {
  updateMessage(input: $input) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const deleteMessage = `mutation DeleteMessage($input: DeleteMessageInput!) {
  deleteMessage(input: $input) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const createAgreement = `mutation CreateAgreement($input: CreateAgreementInput!) {
  createAgreement(input: $input) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const updateAgreement = `mutation UpdateAgreement($input: UpdateAgreementInput!) {
  updateAgreement(input: $input) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const deleteAgreement = `mutation DeleteAgreement($input: DeleteAgreementInput!) {
  deleteAgreement(input: $input) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const createArticle = `mutation CreateArticle($input: CreateArticleInput!) {
  createArticle(input: $input) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const updateArticle = `mutation UpdateArticle($input: UpdateArticleInput!) {
  updateArticle(input: $input) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const deleteArticle = `mutation DeleteArticle($input: DeleteArticleInput!) {
  deleteArticle(input: $input) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const createArticleComment = `mutation CreateArticleComment($input: CreateArticleCommentInput!) {
  createArticleComment(input: $input) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
export const updateArticleComment = `mutation UpdateArticleComment($input: UpdateArticleCommentInput!) {
  updateArticleComment(input: $input) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
export const deleteArticleComment = `mutation DeleteArticleComment($input: DeleteArticleCommentInput!) {
  deleteArticleComment(input: $input) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
