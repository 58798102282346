import React, { Component } from 'react';
import './User.scss';
import Box from '@material-ui/core/Box';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import UserMain from '../../componants/userMain/UserMain';
import UserHeader from '../../componants/UserHeader/UserHeader';

import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../customGraphql/queries';
import * as mutations from '../../graphql/mutations';
import Utils from '../../utils/Utils';

import StarIcon from '@material-ui/icons/Star';
import PublicIcon from '@material-ui/icons/Public';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import UserListPosts from '../../componants/UserListPosts/UserListPosts';
import ListReviews from '../../componants/ListReviews/ListReviews';
import { Container } from '@material-ui/core';

class User extends Component {
    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
        this.setupUser();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.match.params.id !== nextState.userToDisplay) {
            this.setupUser();
        }
        return true
    }

    state = {
        tabIndex: 0,
        currentUserSub: "",
        userToDisplay: "",
        ownProfile: false,
        loading: true,
        userInfo: null,
    };

    setTab = (event, newValue) => {
        this.setState({
            "tabIndex": newValue,
        });
    };

    async setupUser() {
        await this.getCurrentUser();
        let currentSub = await Utils.getCurrentUserSub()
        let subToDisplay = this.getUserSubToDisplay(currentSub);
        this.getUserInfo(subToDisplay)
    }

    async getCurrentUser() {
        await Auth.currentAuthenticatedUser()
            .then(user => {
                if (user.attributes.sub) {
                    this.setState({
                        "currentUserSub": user.attributes.sub,
                    });
                } else {
                    console.log(user)
                }
            })
            .catch(err => console.log(err));
    }

    getUserSubToDisplay(currentSub) {
        if (this.props.match.params.id) {
            if (this.props.match.params.id === "0" || this.props.match.params.id === currentSub) {
                this.setState({
                    "userToDisplay": currentSub,
                    "ownProfile": true,
                    "loading": false,
                });
                return currentSub
            } else {
                this.setState({
                    "userToDisplay": this.props.match.params.id,
                    "ownProfile": false,
                    "loading": false,
                });
                return this.props.match.params.id
            }
        }
    }

    async getUserInfo(subToDisplay) {
        const oneUser = await API.graphql(graphqlOperation(queries.getUserShort, { cognitoSub: subToDisplay }));
        if (Utils.checkNested(oneUser, "data", "getUser")) {
            this.setState({
                "userInfo": oneUser.data.getUser
            });
            // Check if the identityid is set, otherwise set it
            let identityId = await Utils.getCurrentIdentityId();
            let cognitoSub = await Utils.getCurrentUserSub();
            if (Utils.checkNested(oneUser, "data", "getUser", "cognitoSub") === cognitoSub &&
                Utils.checkNested(oneUser, "data", "getUser", "identityId") !== identityId) {
                let input = {
                    cognitoSub: cognitoSub,
                    identityId: identityId,
                }
                console.log(input)
                API.graphql(graphqlOperation(mutations.updateUser, { input: input }));
            }
        }
    }

    render() {
        const { ownProfile, loading, userInfo, tabIndex } = this.state
        const cognitoSub = Utils.checkNested(userInfo, 'cognitoSub');
        const isEarlyAdopter = Utils.checkNested(userInfo, 'isEarlyAdopter');
        return (
            <Box id="user-page">

                <Container maxWidth="md">
                    <UserHeader ownProfile={ownProfile} loading={loading} userInfoToDisplay={userInfo} />
                </Container>
                <Container maxWidth="md">
                    <Box className="tabsMyMissions">
                        <Tabs
                            value={tabIndex}
                            variant="fullWidth"
                            onChange={this.setTab}
                            aria-label="my-missions-tab"
                        >
                            <Tab icon={<FormatAlignLeftIcon />} label="Details"></Tab>
                            <Tab icon={<PublicIcon />} label="Services" />
                            <Tab icon={<StarIcon />} label="Reviews" />
                        </Tabs>
                    </Box>

                    {tabIndex === 0 && <UserMain ownProfile={ownProfile} loading={loading} userInfoToDisplay={userInfo} />}
                    {tabIndex === 1 && <UserListPosts ownProfile={ownProfile} user={cognitoSub} isEarlyAdopter={isEarlyAdopter} />}
                    {tabIndex === 2 && <ListReviews userSub={cognitoSub} />}
                </Container>
            </Box>
        );
    }
}

export default User;