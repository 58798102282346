import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI
import "./ReviewModal.scss";

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, TextField, Box, IconButton } from '@material-ui/core';
import Utils from '../../../utils/Utils';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../../graphql/mutations';
import DisplayStars from '../../DisplayStars/DisplayStars';

class ReviewModal extends Component {
    state = {
        message: "",
        stars: 5,
        loading: false,
        error: null,
    };

    handleCancel = () => {
        this.setState({ loading: false });
        this.props.onClose();
    };

    async handleConfirmation() {
        this.setState({
            loading: true,
        })
        let error = null;
        if (!this.props.agreementid) {
            console.log("Cannot find the eContract")
            error = "Cannot find the eContract";
        }

        if (this.state.message == null) {
            console.log("Invalid message")
            error = "Invalid message";
        }
        if (error) {
            this.setState({
                error: error,
                loading: false,
            })
            return
        }
        try {
            let createReview = await API.graphql(graphqlOperation(mutations.createReview, { agreementId: this.props.agreementid, rating: this.state.stars, content: this.state.message }))
            console.log(createReview)
            if (Utils.checkNested(createReview, "data", "createReview", "id")) {
                this.props.onClose();
                this.setState({
                    error: null,
                    loading: false,
                })
            } else {
                this.setState({
                    error: "Fail to add a review, please try later.",
                    loading: false,
                })
            }
        } catch (e) {
            console.log(e)
            this.setState({
                error: "Fail to add a review, please try later.",
                loading: false,
            })
        }
    }


    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleStarsChange = event => {
        if (event.target.value < 1 || event.target.value > 5) return
        this.setState({
            stars: event.target.value,
        });
    };

    addStar = () => {
        let newStars = this.state.stars + 1
        if (newStars < 1 || newStars > 5) return
        this.setState({
            stars: newStars,
        });
    }

    removeStar = () => {
        let newStars = this.state.stars - 1
        if (newStars < 1 || newStars > 5) return
        this.setState({
            stars: newStars,
        });
    }

    handleEntering = () => {

    }

    render() {
        const {
            loading,
            message,
            error,
            stars,
        } = this.state;
        const { ...other } = this.props;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        let errorDisplay;
        if (error) {
            errorDisplay = <DialogContent><Typography style={{ color: 'red' }}>{error}</Typography></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                fullWidth
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
                className="ReviewModal"
            >
                <DialogContent className="main-content">
                    <Box className="headline">How would you rate it (5 stars is perfect, you will not be able to update this rate later):</Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                        <Box marginRight={1.5}><IconButton aria-label="remove" onClick={this.removeStar}><RemoveIcon /></IconButton></Box>
                        <DisplayStars rating={parseInt(stars)} />
                        <Box marginLeft={1.5}><IconButton aria-label="add" onClick={this.addStar}><AddIcon /></IconButton></Box>
                    </Box>

                    <TextField
                        disabled={loading}
                        id="message-post"
                        label="Message"
                        value={message}
                        onChange={this.handleChange("message")}
                        margin="normal"
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 2000 }}
                    />
                </DialogContent>
                {errorDisplay}
                {loadingDisplay}
                <DialogActions>
                    <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'left' }} onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'right', backgroundColor: '#CC0000' }} onClick={() => this.handleConfirmation()} >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ReviewModal.propTypes = {
    onClose: PropTypes.func,
    agreementid: PropTypes.string,
    postId: PropTypes.string,
};

export default ReviewModal;