import React, { Component } from 'react';
import './PostsSearch.scss';
import PropTypes from 'prop-types';
import SelectCountryCity from '../SelectCountryCity/SelectCountryCity';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

import SelectCategory from '../SelectCategory/SelectCategory';
import Utils from '../../utils/Utils';

const initCountry = "FRANCE";
const initCategory = "MISSION";

class PostsSearch extends Component {
    constructor() {
        super();
        this.categoryChange = this.categoryChange.bind(this)
        this.locationChange = this.locationChange.bind(this)
    }

    state = {
        country: initCountry,
        city: null,

        category: initCategory,
        tag: null,
        subTag: null,

        errorMessage: null,
    }

    onSearch() {
        // Check if there is at least a country and a category
        if (!this.state.country) { this.setState({ errorMessage: "Please select at least a country." }); return }
        if (this.props.onSearch) { this.props.onSearch(this.state) }
        this.setState({ errorMessage: null })
    }

    categoryChange(categoryObj) {
        this.setState({
            category: Utils.checkNested(categoryObj.category, "value"),
            tag: Utils.checkNested(categoryObj.tag, "id"),
            subTag: Utils.checkNested(categoryObj.subtag, "id"),
        })
    }

    locationChange(locationObj) {
        this.setState({
            country: Utils.checkNested(locationObj.country),
            city: Utils.checkNested(locationObj.city, "id"),
        })
    }

    render() {
        return (
            <Box className="PostsSearch">
                <Box marginBottom={2.5}>
                    <Box marginBottom={1}><Typography>What are you looking for?</Typography></Box>
                    <Box display='flex' alignItems='center'>
                        <Box marginRight={2}><FlightTakeoffIcon /></Box>
                        <Box color={'black'}><SelectCategory category={initCategory} alwaysOpen={true} onChange={this.categoryChange} /></Box>
                    </Box>
                </Box>
                <Box>
                    <Box display='flex' alignItems='center'>
                        <Box marginRight={2}><LocationOnIcon /></Box>
                        <Box color={'black'}><SelectCountryCity country={initCountry} alwaysOpen={true} onChange={this.locationChange} /></Box>
                    </Box>
                </Box>
                <Box flexGrow={1} textAlign="right">
                    <Button color="primary" style={{color:'white'}} variant="contained" size="small" onClick={() => this.onSearch()}>
                        <SearchIcon />
                        Search
                    </Button>
                </Box>
                {
                    this.state.errorMessage ?
                        <Typography style={{ color: "red" }}>{this.state.errorMessage}</Typography>
                        :
                        null
                }

            </Box >
        );
    }
}

PostsSearch.propTypes = {
    onSearch: PropTypes.func,
};

export default PostsSearch;