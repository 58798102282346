// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import Utils from "./Utils";

class locationHelper {
    static getCityNameById(cityId) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.getCity, { id: cityId }))
                .then(result => {
                    resolve( Utils.checkNested(result, "data", "getCity", "name"))

                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }
}

export default locationHelper;