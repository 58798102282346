import React, { Component } from 'react';
import './PostLocationWrapper.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';
import SelectCountryCity from '../SelectCountryCity/SelectCountryCity';

class PostLocationWrapper extends Component {
    state = {
        cityId: null,
        country: null,
        editable: false,
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "country") ||
            Utils.checkNested(this, "props", "cityId") ||
            Utils.checkNested(this, "props", "editable")) {
            this.resetFromProps()
        }
    }

    componentDidUpdate(prevProps) {
        let countryFromProps = Utils.checkNested(this, "props", "country");
        let countryFromPrevProps = Utils.checkNested(prevProps, 'country');

        let cityIdFromProps = Utils.checkNested(this, "props", "cityId");
        let cityIdFromPrevProps = Utils.checkNested(prevProps, 'cityId');

        let editableFromProps = Utils.checkNested(this, "props", "editable");
        let editableFromPrevProps = Utils.checkNested(prevProps, 'editable');

        if (countryFromProps !== countryFromPrevProps ||
            cityIdFromProps !== cityIdFromPrevProps ||
            editableFromProps !== editableFromPrevProps) {
            this.resetFromProps();
        }
    }

    resetFromProps() {
        this.setState({
            cityId: this.props.cityId,
            country: this.props.country,
            editable: this.props.editable,
        })
    }

    async onUpdate(location) {
        if (!Utils.checkNested(this.props, 'postId')) return
        if (!Utils.checkNested(this.props, 'category')) return
        if (!Utils.checkNested(location, 'country')) return
        let cityIdToPush = Utils.checkNested(location, 'city', 'id');
        let date = new Date().toISOString();
        try {
            let input = {
                id: this.props.postId,
                country: location.country,
                city: cityIdToPush,
                category: this.props.category,
                tag: this.props.tagId,
                subTag: this.props.subtagId,
                updatedAt: date,
            }

            let updateLocation = await API.graphql(graphqlOperation(mutations.updateServiceLocation, { input: input }));
            if (Utils.checkNested(updateLocation, "data", "updateService", "country")) {
                this.setState({
                    country: updateLocation.data.updateService.country,
                    cityId: updateLocation.data.updateService.city,
                })

                if (this.props.onUpdate) {
                    this.props.onUpdate(updateLocation.data.updateService)
                }
            }
        } catch (e) {
            console.log("Fail on update title")
            console.log(e)
        }
    }

    render() {
        const { country, cityId } = this.state;
        const { editable } = this.props;

        return (
            <Box className="PostLocationWrapper">
                <SelectCountryCity
                    onChange={this.onUpdate}
                    country={country}
                    cityId={cityId}
                    editable={editable}
                    loading={false}
                />
            </Box>
        );
    }
}

PostLocationWrapper.propTypes = {
    postId: PropTypes.string,
    country: PropTypes.string,
    cityId: PropTypes.string,
    editable: PropTypes.bool,
    category: PropTypes.string, // Needed for the update
    tagId: PropTypes.string, // Needed for the update
    subtagId: PropTypes.string, // Needed for the update

    onUpdate: PropTypes.func,
};

export default PostLocationWrapper;