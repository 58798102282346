import React, { Component } from 'react';
import './Blog.scss';
import { Typography, Container } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Footer from '../../componants/Footer/Footer';

import ArticleSearch from '../../componants/ArticleSearch/ArticleSearch';
import ListArticles from '../../componants/ListArticles/ListArticles';
import { BlogRoute } from '../../Routing';
import Utils from '../../utils/Utils';

class Blog extends Component {
    constructor() {
        super();
        this.onResult = this.onResult.bind(this);
    }

    state = {
        resultItems: [],
    }

    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
    }

    onResult(resultItems, category="ALL", language="ALL") {
        let currentCategory = Utils.checkNested(this.props, "match", "params", "category") ? this.props.match.params.category : 'ALL';
        let currentLanguage = Utils.checkNested(this.props, "match", "params", "language") ? this.props.match.params.language : 'ALL';

        if (currentCategory !== category || currentLanguage !== language) {
            this.props.history.push(BlogRoute + category + '/' + language)
        }
        this.setState({
            resultItems: resultItems,
        })
    }

    render() {
        return (
            <Box id="Blog">
                <Container maxWidth="lg">
                    <Typography style={{ marginBottom: "1em", marginTop: "2em", color: 'white' }} variant="h2">
                        All you want to know about drones
                    </Typography>
                </Container>

                <Container style={{ marginBottom: "1em", marginTop: "2em", color: 'white' }} maxWidth="md">
                    <ArticleSearch onResult={this.onResult}/>
                </Container>

                <Box className="container-white" >
                    <Container maxWidth="md">
                        <ListArticles resultItems={this.state.resultItems} />
                    </Container>
                </Box>

                <Footer />
            </Box>
        );
    }
}

export default Blog;