import React, { Component } from 'react';
import './CardReview.scss';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import UserThumb from '../UserThumb/UserThumb';
import DisplayStars from '../DisplayStars/DisplayStars';

class CardReview extends Component {
    render() {
        const { userFromSub, content, rating, date } = this.props;
        return (
            <Box className="CardReview">
                <Box className="container-review" width="100%" style={{ whiteSpace: 'pre-line' }}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                        <DisplayStars rating={rating} />
                        <Box flexGrow={1} />
                        <Typography variant='subtitle2'>{new Date(date).toDateString()}</Typography>
                        </Box>
                    <Box marginBottom={1} marginTop={1}><Typography>{content}</Typography></Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" >
                        <Box flexGrow={1} />
                        <Box marginRight={1}><Typography>By: </Typography></Box>
                        <UserThumb userId={userFromSub} clickable={true} />
                    </Box>
                </Box>
            </Box>
        )
    }
}

CardReview.propTypes = {
    userFromSub: PropTypes.string,
    rating: PropTypes.number,
    content: PropTypes.string,
    date: PropTypes.string,
};

export default CardReview;