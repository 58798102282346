import React, { Component } from 'react';
import './Conversation.scss';
import { Box, TextField, Button, Container } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DescriptionIcon from '@material-ui/icons/Description';

import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from '../../graphql/subscriptions';

import Messaging from '../../utils/messagingHelper';
import Utils from '../../utils/Utils';
import uuid from 'react-uuid';
import UserThumb from '../../componants/UserThumb/UserThumb';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { NewAgreementRoute } from '../../Routing';

class Conversation extends Component {
    state = {
        listMessages: [],
        cryptKey: null,
        message: "",
        conversObjList: null,
        currentSub: null,
        recipentSub: null,
        loading: false,
    }
    componentDidMount() {
        if (Utils.checkNested(this.props, 'match', 'params', 'id')) {
            this.setupConvers(this.props.match.params.id);
            this.subscriptionNew = this.subscribeNewMessage(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.subscriptionNew.unsubscribe();
    }

    subscribeNewMessage(conversId) {
        return API.graphql(
            graphqlOperation(subscriptions.onCreateMessageByConvers, { conversId: conversId })
        ).subscribe({
            next: (messageData) => {
                console.log("New message received")
                if (Utils.checkNested(messageData, 'value', 'data', 'onCreateMessageByConvers')) {
                    let newMessage = messageData.value.data.onCreateMessageByConvers;
                    this.addNewMessage(newMessage);
                }
            }
        });
    }

    async addNewMessage(messageCrypt) {
        let newMessageId = messageCrypt.id;
        let currentMessagesList = this.state.listMessages;
        for (let i = 0; i < currentMessagesList.length; i++) {
            if (currentMessagesList[i].id === newMessageId) {
                return
            }
        }
        let decryptMessage = await Messaging.decryptMessage(messageCrypt, this.state.cryptKey)
        currentMessagesList.unshift(decryptMessage);
        this.setState({
            listMessages: currentMessagesList,
        })
        this.scrollConversBottom();
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    async setupConvers(conversId) {
        let conversObjList = await Messaging.getConversObjList(conversId);
        let cryptKey = await Messaging.getCrypKey(conversObjList);
        let listMessagesCrypt = await Messaging.getConversation(conversId);
        let listMessages = await Messaging.decryptListMessages(listMessagesCrypt, cryptKey);
        let currentSub = await Utils.getCurrentUserSub();
        let recipentSub = await Messaging.getRecipentSub(conversObjList);
        Messaging.setConversationRead(conversId, currentSub);
        this.setState({
            listMessages: listMessages,
        })
        this.setState({
            cryptKey: cryptKey,
            conversObjList: conversObjList,
            currentSub: currentSub,
            recipentSub: recipentSub,
        })
        this.scrollConversBottom();
    }

    async sendMessage() {
        if (this.state.message == null || this.state.message === "") return
        this.setState({
            loading: true
        })
        let sendMessage = await Messaging.sendMessage(this.state.currentSub, this.state.recipentSub, this.state.message, this.state.conversObjList)
        if (sendMessage[1]) {
            this.addNewMessage(sendMessage[1])
        }
        this.scrollConversBottom();
        this.setState({
            loading: false,
            message: "",
        })
    }

    scrollConversBottom() {
        let node = document.getElementsByClassName("messages-container")[0];
        if (node) {
            node.scrollTop = node.scrollHeight;
        }
    }

    render() {
        const { listMessages, currentSub, message, loading, recipentSub } = this.state;
        this.scrollConversBottom();
        return (
            <Container maxWidth="md" className="Conversation">
                <Box className="header-container" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                    <Box className="user-thumb" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                        <Box marginLeft={1}><UserThumb userId={recipentSub} clickable={true} noStars={true} /></Box>
                    </Box>

                    <Box flexGrow={1}></Box>
                    <Box >
                        <Link to={NewAgreementRoute + recipentSub} style={{ textDecoration: 'none' }}>
                            <Button disabled={!recipentSub} variant="contained" size="small" color="primary">
                                <DescriptionIcon />Create a e-Contract
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Box className="messages-container">
                    {
                        listMessages.slice().reverse().map((messageObj) => {
                            return <Box key={uuid()} className={messageObj.userSub === currentSub ? "user-message" : "not-user-message"}>{messageObj.message}</Box>
                        })
                    }
                </Box>
                <Box className="input-container" display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end">
                    <Box className="text-field-container" flexGrow={1}>
                        <TextField
                            className="text-field"
                            disabled={loading || !recipentSub}
                            id="message-post"
                            label="Message"
                            value={message}
                            onChange={this.handleChange("message")}
                            margin="normal"
                            fullWidth
                            multiline
                            inputProps={{ maxLength: 2000 }}
                        />
                    </Box>
                    <Box marginLeft={2} paddingBottom={1.5} paddingRight={1}>
                        <Button disabled={loading || !recipentSub} variant="contained" size="small" color="secondary" onClick={() => this.sendMessage()} >
                            <SendIcon />
                        </Button>
                    </Box>
                </Box>

            </Container>
        );
    }
}

export default Conversation;