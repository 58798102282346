import React, { Component } from 'react';
import './AddPostButton.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';

// DB
import { Box, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddPostModal from './AddPostModal/AddPostModal';

class AddPostButton extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    state = {
        dialogOpen: false,
    };

    async onAdd() {
        if (Utils.checkNested(this, "props", "onAdd")) {
            this.props.onAdd();
        }
    }

    handleClose() {
        this.setState({ dialogOpen: false });
        if (this.props.onAdd) { this.props.onAdd(); }

    }

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    render() {
        let { dialogOpen } = this.state;
        const { disabled, isEarlyAdopter } = this.props;
        return (
            <Box className="AddPostButton">
                <Button onClick={this.handleOpen} disabled={disabled} variant="contained" color="secondary"><AddIcon />Publish a new service</Button>
                <AddPostModal
                    open={dialogOpen}
                    onClose={this.handleClose}
                    isearlyadopter={isEarlyAdopter}
                />
            </Box>
        );
    }
}

AddPostButton.propTypes = {
    onAdd: PropTypes.func,
    disabled: PropTypes.bool,
    isEarlyAdopter: PropTypes.bool,
};

export default AddPostButton;
