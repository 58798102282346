import React, { Component } from 'react';
import './UserListPosts.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import CardPost from '../CardPost/CardPost';
import uuid from "react-uuid";

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from '../../customGraphql/queries';

import { Box, Typography } from '@material-ui/core';
import AddPostButton from '../AddPostButton/AddPostButton';

class UserListPosts extends Component {
    state = {
        listPosts: [],
        disabledAddPost: true,
    };

    constructor() {
        super();
        this.loadPosts = this.loadPosts.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "user")) {
            this.loadPosts()
        }
    }

    componentDidUpdate(prevProps) {
        let userFromProps = Utils.checkNested(this, "props", "user");
        let userFromPrevProps = Utils.checkNested(prevProps, 'user');
        if (userFromProps !== userFromPrevProps) {
            this.loadPosts();
        }
    }

    async loadPosts() {
        let getPosts = await API.graphql(graphqlOperation(customQueries.servicesByUserShort, { user: this.props.user, sortDirection: 'DESC' }));
        if (!Utils.checkNested(getPosts, 'data', 'servicesByUser', 'items')) return
        let addPostShouldDisabled = true;
        if (getPosts.data.servicesByUser.items.length < 10) { addPostShouldDisabled = false }
        this.setState({
            listPosts: getPosts.data.servicesByUser.items,
            disabledAddPost: addPostShouldDisabled
        })
    }

    render() {
        const { listPosts, disabledAddPost } = this.state;
        const { ownProfile, isEarlyAdopter } = this.props;

        return (
            <Box className="UserListPosts">
                {
                    ownProfile ?
                        <Box className="add-post"><AddPostButton disabled={disabledAddPost} onAdd={this.loadPosts} isEarlyAdopter={isEarlyAdopter} /></Box>
                        :
                        null
                }
                { listPosts.length > 0 ?
                    listPosts.map((post, index) => {
                        return <CardPost key={uuid()} postId={post.id}></CardPost>
                    })
                    :
                    <Box>
                    {
                        ownProfile ?
                        <Box className="no-post"><Typography>You don't currently have any post. Click "Publish a new post" to show to the world what you can offer.</Typography></Box>
                        :
                        <Box className="no-post"><Typography>No services available.</Typography></Box>
                    }
                    </Box>
                }
            </Box>
        );
    }
}

UserListPosts.propTypes = {
    user: PropTypes.string,
    ownProfile: PropTypes.bool,
    isEarlyAdopter: PropTypes.bool,
};

export default UserListPosts;
