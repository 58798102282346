import React, { Component } from 'react';
import './PostDescriptionTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';

class PostDescriptionTextField extends Component {
    state = {
        title: "",
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "description")) {
            this.setTitle(this.props.description)
        }
    }

    componentDidUpdate(prevProps) {
        let descriptionFromProps = Utils.checkNested(this, "props", "description");
        let descriptionFromPrevProps = Utils.checkNested(prevProps, 'description');
        if (descriptionFromProps !== descriptionFromPrevProps) {
            this.setDescription(this.props.description);
        }
    }

    setDescription(text) {
        this.setState({
            "description": text,
        });
    }

    async onUpdate(newdescription) {
        // Update title on the cloud
        if (!Utils.checkNested(this.props, "postId")) return
        let date = new Date().toISOString();
        try {
            if (newdescription === "" || newdescription === null) return;
            let input = {
                id: this.props.postId,
                description: newdescription,
                
                // The sort key has to be provide, and I want to update the updatedAt field so I have to provide all sort fields
                country: this.props.country,
                city: this.props.cityId,
                category: this.props.category,
                tag: this.props.tagId,
                subTag: this.props.subtagId,
                updatedAt: date,
            }
            let updateDescription = await API.graphql(graphqlOperation(mutations.updateServiceDescription, { input: input }));

            // Update the child componant to display the new description
            if (Utils.checkNested(updateDescription, "data", "updateService", "description")) {
                this.setDescription(updateDescription.data.updateService.description)
                if (this.props.onUpdate) {
                    this.props.onUpdate(updateDescription.data.updateService)
                }
            }
        } catch (e) {
            console.log("Fail on update description")
            console.log(e)
        }


    }

    render() {
        const { description } = this.state;
        const { editable } = this.props;

        return (
            <Box className="PostDescriptionTextField">
                <UpdatableTextField
                    text={description}
                    editable={editable}
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={3000}
                />
            </Box>
        );
    }
}

PostDescriptionTextField.propTypes = {
    description: PropTypes.string,
    editable: PropTypes.bool,
    postId: PropTypes.string,

    country: PropTypes.string, // Needed for the update
    cityId: PropTypes.string, // Needed for the update
    category: PropTypes.string, // Needed for the update
    tagId: PropTypes.string, // Needed for the update
    subtagId: PropTypes.string, // Needed for the update

    onUpdate: PropTypes.func,
};

export default PostDescriptionTextField;