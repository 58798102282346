// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import Utils from "./Utils";

class reviewHelper {
    static getAgreementReviews(agreementId) {
        return new Promise(async (resolve, reject) => {
            try {
                let reviewsByAgreement = await API.graphql(graphqlOperation(queries.reviewsByAgreementId, { agreementId: agreementId }))
                if (Utils.checkNested(reviewsByAgreement, 'data', 'reviewsByAgreementId', 'items', 'length')) {
                    resolve(reviewsByAgreement.data.reviewsByAgreementId.items)
                }
            } catch (e) {
                console.log(e)
                resolve([])
            }           
        })
    }
}

export default reviewHelper;