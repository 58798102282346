import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import './CardPost.scss';
import { PostRoute } from '../../Routing';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ImgDisplay from '../imgDisplay/ImgDisplay';
import SelectCategory from '../SelectCategory/SelectCategory';
import SetPrice from '../SetPrice/SetPrice';
import SelectCountryCity from '../SelectCountryCity/SelectCountryCity';
import UserThumb from '../UserThumb/UserThumb';
import Utils from '../../utils/Utils';
import imgNotFound from '../../utils/image-not-found.jpg'

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import locationHelper from '../../utils/locationHelper';

const styles = theme => ({
    avatar: {
        height: "250px",
        width: "100%",
        borderRadius: "4px",
        //boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        overflow: 'hidden',
        //marginRight: theme.spacing(1.5),
        //marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            width: "250px",
        },
    },
    container: {
        //padding: theme.spacing(1),
        marginBottom: theme.spacing(2.5),
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: '#fefefe',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);'
    },
    containerText: {
        maxWidth:'100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: "calc(100% - 250px)",
        },
    }

    
});

class CardPost extends Component {
    state = {
        mainImgKey: null,
        title: null,
        price: null,
        currency: null,
        label: null,
        country: null,
        cityId: null,
        userSub: null,
        identityId: null,
        cityName: null,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "postId")) {
            this.loadInfo(this.props.postId)
        }
    };

    componentDidUpdate(prevProps) {
        let postIdFromProps = Utils.checkNested(this, "props", "postId");
        let postIdFromPrevProps = Utils.checkNested(prevProps, 'postId');
        if (postIdFromProps !== postIdFromPrevProps) {
            this.loadInfo(postIdFromProps);
        }
    };

    async loadInfo(postId) {
        if (!postId) return;

        try {
            const getService = await API.graphql(graphqlOperation(queries.getService, { id: postId }));
            if (!Utils.checkNested(getService, "data", "getService")) return
            let serviceData = getService.data.getService;
            let imgKey = null;
            if (serviceData.imageKey && serviceData.imageKey.length > 0) {
                imgKey = serviceData.imageKey[0];
            }
            if (!imgKey) {
                imgKey = serviceData.user;
            }

            let identityId = null;
            let cityName = null;
            if (serviceData.user) { identityId = await Utils.getIdentityIdFromSub(serviceData.user); }
            if (serviceData.city) { cityName = await locationHelper.getCityNameById(serviceData.city); }
            this.setState({
                mainImgKey: imgKey,
                title: serviceData.title,
                price: serviceData.price,
                currency: serviceData.currency,
                label: serviceData.priceLabel,
                category: serviceData.category,
                tagId: serviceData.tag,
                subtagId: serviceData.subTag,
                date: serviceData.updatedAt,
                country: serviceData.country,
                cityId: serviceData.city,
                cityName: cityName,
                userSub: serviceData.user,
                identityId: identityId
            })
        } catch (e) {
            console.log(e)
        }
    }

    numberToString(number) {
        if (!number) return null;
        return number.toString();
    }

    render() {
        let { classes, postId } = this.props;
        let {
            mainImgKey,
            title,
            price,
            currency,
            label,
            category,
            tagId,
            subtagId,
            date,
            country,
            cityId,
            cityName,
            userSub,
            identityId
        } = this.state;
        let dateToDisplayed = Utils.isoDateToReadable(date);
        return (
            <CardActionArea className={classes.container} component={Link} to={PostRoute + country + '-' + cityName + '/' +  postId}>
                <Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" >
                        <Box className={classes.avatar}>
                            <ImgDisplay
                                defaultImgPath={imgNotFound}
                                imgKey={mainImgKey}
                                identityId={identityId}
                            />
                        </Box>
                        <Box flexGrow={1} padding={2} className={classes.containerText}>
                            <Box marginBottom="1em"><Typography variant="h6">{title}</Typography></Box>
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                                <Box flexGrow={1} marginBottom="1em"><SelectCategory category={category} tagId={tagId} subtagId={subtagId} /></Box>
                                <Box marginBottom="1em"><SetPrice price={this.numberToString(price)} currency={currency} label={label} /></Box>
                            </Box>
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                                <Box flexGrow={1}><SelectCountryCity country={country} cityId={cityId} /></Box>
                                <Box><Typography>{dateToDisplayed}</Typography></Box>
                            </Box>
                            <Box display="flex" flexDirection="row-reverse" flexWrap="wrap"><UserThumb userId={userSub} /></Box>
                        </Box>

                    </Box>
                </Box>

            </CardActionArea>
        );
    }
}


CardPost.propTypes = {
    postId: PropTypes.string,
};

export default withStyles(styles)(CardPost);
