import React, { Component } from 'react';
import './UserAvatar.scss';
import Box from '@material-ui/core/Box';
import ImgDisplay from '../imgDisplay/ImgDisplay';
import PropTypes from 'prop-types';
import defaultAvatar from '../../utils/defaultAvatar.png'

class UserAvatar extends Component {
    async onEdit(key) {
        console.log("Edit avatar: " + key)
        // Needed to update the profile image in the drawer, a subscription would be cleaner. Good enough for now
        window.location.reload();
    }

    async onDelete(key) {
        console.log("Delete avatar: " + key)
        // Needed to update the profile image in the drawer, a subscription would be cleaner. Good enough for now
        window.location.reload();
    }

    render() {
        const { userSub, ownProfile, identityId } = this.props;

        return (
            <Box className="UserAvatar">
                <ImgDisplay
                    defaultImgPath={defaultAvatar}
                    imgKey={userSub}
                    identityId={identityId}
                    allowEdit={ownProfile}
                    allowDelete={ownProfile}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    maxHeight={400}
                    maxWidth={400}
            />
            </Box>
        );
    }
}

UserAvatar.propTypes = {
    userSub: PropTypes.string,
    ownProfile: PropTypes.bool,
    identityId: PropTypes.string,
};

export default UserAvatar;