import React, { Component } from 'react';
import './NewAgreement.scss';
import Box from '@material-ui/core/Box';
import { Typography, Divider, TextField, Button, Container } from '@material-ui/core';
import Utils from '../../utils/Utils';
import UserThumb from '../../componants/UserThumb/UserThumb';
import userHelper from '../../utils/userHelper';
import agreementHelper from '../../utils/agreementHelper';
import { AgreementRoute } from '../../Routing';

class NewAgreement extends Component {
    state = {
        user1Sub: null,
        user2Sub: null,
        user1Name: null,
        user2Name: null,
        user1Mail: null,
        user2Mail: null,
        identityIdUser1: null,
        user1Obj: null,
        user2obj: null,
        content: "",
        title: "",
        loading: false,
    }
    componentDidMount() {
        if (Utils.checkNested(this.props, 'match', 'params', 'id')) {
            this.setupUsers(this.props.match.params.id);
        }
    }

    async setupUsers(user2Sub) {
        let user1Sub = await Utils.getCurrentUserSub();
        let user1Obj = await userHelper.getUserObj(user1Sub);
        let user2Obj = await userHelper.getUserObj(user2Sub);
        console.log(user1Obj);
        console.log(user2Obj)
        let user1Name = "Error";
        let user2Name = "Error";
        let user1Mail = "Error";
        let user2Mail = "Error";
        let identityIdUser1 = null;

        if (user1Obj) {
            user1Name = user1Obj.userName;
            user1Mail = user1Obj.email;
            identityIdUser1 = user1Obj.identityId;
        }

        if (user2Obj) {
            user2Name = user2Obj.userName;
            user2Mail = user2Obj.email;
        }

        this.setState({
            user1Sub: user1Sub,
            user2Sub: user2Sub,

            user1Name: user1Name,
            identityIdUser1: identityIdUser1,
            user1Mail: user1Mail,
            user1Obj: user1Obj,

            user2Name: user2Name,
            user2Mail: user2Mail,
            user2Obj: user2Obj,
        })
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    async handleSave() {
        this.setState({
            loading: true,
        });
        const { user1Sub, user2Sub, user1Name, user2Name, user1Mail, user2Mail, content, title, identityIdUser1 } = this.state;
        let createAgreement = await agreementHelper.createAgreement(user1Sub, user2Sub, user1Name, user2Name, user1Mail, user2Mail, identityIdUser1, content, title)
        console.log(createAgreement)
        if (createAgreement && createAgreement.id) {
            this.setState({
                loading: false,
            });
            window.location.replace(AgreementRoute + createAgreement.id);
        }
        this.setState({
            loading: false,
        });
    }

    render() {
        const { user1Sub, user2Sub, user1Name, user2Name, user1Mail, user2Mail, content, loading, title } = this.state;
        let date = new Date().toDateString()
        return (

            <Box className="NewAgreement">
                <Container maxWidth="lg">
                    <Box marginBottom={3}>
                        <Typography style={{color: 'white'}} component="h2" variant="h2">Create a new eContract</Typography>
                    </Box>
                </Container>
                <Container maxWidth="md">
                    <Box className="container">
                        <Typography variant="h4">Between</Typography>
                        <Box marginBottom={2}><Divider /></Box>
                        <Box textAlign="center"><Typography variant="h5">You</Typography></Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={2}>
                            <Box flexGrow={1} />
                            <UserThumb userId={user1Sub} />
                            <Box flexGrow={1} />
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>Username ({date}):</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User unique code:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Sub}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User verified email:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Mail}</Typography>
                        </Box>

                        <Box marginBottom={2} marginTop={2}><Divider /></Box>

                        <Box textAlign="center"><Typography variant="h5">And</Typography></Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={2}>
                            <Box flexGrow={1} />
                            <UserThumb userId={user2Sub} />
                            <Box flexGrow={1} />
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>Username ({date}):</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User unique code:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Sub}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User verified email:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Mail}</Typography>
                        </Box>
                    </Box>

                    <Box className="container">
                        <Typography variant="h4">Title</Typography>
                        <Box marginBottom={1}><Divider /></Box>
                        <Typography>Give a short name so you can identify it easily</Typography>
                        <Box marginTop={1} marginBottom={3}>
                            <TextField
                                disabled={loading}
                                id="title-new-agreement"
                                label="Title (100 characters max)"
                                value={title}
                                onChange={this.handleChange("title")}
                                margin="normal"
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 100 }}
                            />
                        </Box>
                        <Typography variant="h4">Details</Typography>
                        <Box marginBottom={1}><Divider /></Box>
                        <Typography>Give all necessary information to prevent misunderstanding: what, where, when, price, payment method...</Typography>
                        <Box marginTop={1}>
                            <TextField
                                disabled={loading}
                                id="content-new-agreement"
                                label="Content (5000 characters max)"
                                value={content}
                                onChange={this.handleChange("content")}
                                margin="normal"
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 5000 }}
                            />
                        </Box>
                        <Box marginTop={4}>
                            <Button disabled={loading} variant="contained" size="small" color="primary" style={{ float: 'right' }} onClick={() => this.handleSave()} >
                                Save this new agreement
                        </Button>
                        </Box>
                    </Box>
                </Container>
            </Box >
        );
    }
}

export default NewAgreement;