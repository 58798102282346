import React, { Component } from 'react';
import './UserTitleTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';

class UserTitleTextField extends Component {
    state = {
        title: 'No Title',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "title")) {
            this.setTitle(this.props.title)
        }
    }

    componentDidUpdate(prevProps) {
        let titleFromProps = Utils.checkNested(this, "props", "title");
        let titleFromPrevProps = Utils.checkNested(prevProps, 'title');
        if (titleFromProps !== titleFromPrevProps) {
            this.setTitle(this.props.title);
        }
    }

    setTitle(text) {
        this.setState({
            "title": text,
        });
    }

    async onUpdate(newtitle) {
        // Update title on the cloud
        try {
            let userSub = await Utils.getCurrentUserSub();
            if (!userSub) return;

            if (newtitle === "" || newtitle === null) newtitle = " ";

            let input = {
                cognitoSub: userSub,
                title: newtitle
            }

            let updateTitle = await API.graphql(graphqlOperation(mutations.updateUserTitle, { input: input }));

            // Update the child componant to display the new title
            if (Utils.checkNested(updateTitle, "data", "updateUser", "title")) {
                this.setTitle(updateTitle.data.updateUser.title)
            }
        } catch (e) {
            console.log("Fail on update title")
            console.log(e)
        }


    }

    render() {
        const { title } = this.state;
        const { editable } = this.props;

        return (
            <Box className="UserTitleTextField">
                <UpdatableTextField
                    text={title}
                    editable={editable}
                    typography="h6"
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={150}
                />
            </Box>
        );
    }
}

UserTitleTextField.propTypes = {
    title: PropTypes.string,
    editable: PropTypes.bool,
};

export default UserTitleTextField;