import React, { Component } from 'react';
import './DisplayStars.scss';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';

class DisplayStars extends Component {
    roundHalf(num) {
        return Math.round(num*2)/2;
    }

    render() {
        const { rating } = this.props;

        const stars = []

        let ratingToHalf = this.roundHalf(rating);
        for (let i = 1; i <= 5; i++) {
            if (ratingToHalf < i && ratingToHalf > i - 1) {
                stars.push(<StarHalfIcon key={i} />)
            } else if (ratingToHalf >= i) {
                stars.push(<StarIcon key={i} />)
            } else {
                stars.push(<StarBorderIcon key={i} />)
            }
        }

        return (
            <Box className="DisplayStars" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                <Box className={!rating ? "disable": 'normal'}>
                    {stars}
                </Box>
            </Box>
        );
    }
}


DisplayStars.propTypes = {
    rating: PropTypes.number,
};

export default DisplayStars;
