import React, { Component } from 'react';
import './Agreements.scss';
import { Box, Typography, Hidden, Container } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';

import uuid from 'react-uuid';
import UserThumb from '../../componants/UserThumb/UserThumb';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AgreementRoute } from '../../Routing';
import Utils from '../../utils/Utils';

import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from '../../graphql/subscriptions';
import agreementHelper, { STATUS } from '../../utils/agreementHelper';
import ConfirmationDeleteAgreementModal from '../../componants/ConfirmationDeleteAgreementModal/ConfirmationDeleteAgreementModal';

class Agreements extends Component {
    state = {
        listAgreements: null,
        dialogDeleteOpen: false,
        agreementobjToDelete: null,
    }

    // Get the conversation list
    componentDidMount() {
        if (this.state && !this.state.listAgreements) {
            this.getListAgreements()
            this.subscribeUpdateAgreement();
            this.subscribeNewAgreement();
            this.subscribeDeleteAgreement();
        }
    }

    componentWillUnmount() {
        this.subscriptionUpdate.unsubscribe();
        this.subscriptionCreate.unsubscribe();
        this.subscriptionDeleteUser1.unsubscribe();
        this.subscriptionDeleteUser2.unsubscribe();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state !== nextState) {
            return true
        } else {
            return false
        }
    }

    async subscribeUpdateAgreement() {
        let currentSub = await Utils.getCurrentUserSub();
        this.subscriptionUpdate = API.graphql(
            graphqlOperation(subscriptions.onUpdateAgreementByUser2, { user2Sub: currentSub })
        ).subscribe({
            next: (agreementData) => {
                console.log("Update agreement received")
                if (Utils.checkNested(agreementData, 'value', 'data', 'onUpdateAgreementByUser2')) {
                    let newAgreement = agreementData.value.data.onUpdateAgreementByUser2;
                    this.updateAgreement(newAgreement);
                }
            }
        });
    }

    async subscribeNewAgreement() {
        let currentSub = await Utils.getCurrentUserSub();
        this.subscriptionCreate = API.graphql(
            graphqlOperation(subscriptions.onCreateAgreementByUser2, { user2Sub: currentSub })
        ).subscribe({
            next: (agreementData) => {
                console.log("New agreement received")
                if (Utils.checkNested(agreementData, 'value', 'data', 'onCreateAgreementByUser2')) {
                    let newAgreement = agreementData.value.data.onCreateAgreementByUser2;
                    this.updateAgreement(newAgreement);
                }
            }
        });
    }

    async subscribeDeleteAgreement() {
        let currentSub = await Utils.getCurrentUserSub();
        this.subscriptionDeleteUser1 = API.graphql(
            graphqlOperation(subscriptions.onDeleteAgreementByUser1, { user1Sub: currentSub })
        ).subscribe({
            next: (agreementData) => {
                console.log("Delete agreement received")
                if (Utils.checkNested(agreementData, 'value', 'data', 'onDeleteAgreementByUser1')) {
                    let deleteAgreement = agreementData.value.data.onDeleteAgreementByUser1;
                    this.deleteAgreement(deleteAgreement.id);
                }
            }
        });

        this.subscriptionDeleteUser2 = API.graphql(
            graphqlOperation(subscriptions.onDeleteAgreementByUser2, { user2Sub: currentSub })
        ).subscribe({
            next: (agreementData) => {
                console.log("Delete agreement received")
                if (Utils.checkNested(agreementData, 'value', 'data', 'onDeleteAgreementByUser2')) {
                    let deleteAgreement = agreementData.value.data.onDeleteAgreementByUser2;
                    this.deleteAgreement(deleteAgreement.id);
                }
            }
        });
    }

    deleteAgreement(deleteAgreementId) {
        let idFound = null;
        for (let i = 0; i < this.state.listAgreements.length; i++) {
            if (this.state.listAgreements[i].id === deleteAgreementId) {
                idFound = i;
            }
        }
        if (idFound === null) return
        let newListAgreements = this.state.listAgreements;
        newListAgreements.splice(idFound, 1);
        this.setState({
            listAgreements: newListAgreements,
        })
    }

    updateAgreement(agreementObj) {
        let idFound = null;
        for (let i = 0; i < this.state.listAgreements.length; i++) {
            if (this.state.listAgreements[i].id === agreementObj.id) {
                idFound = i;
            }
        }

        let newListAgreements = this.state.listAgreements;
        if (idFound !== null) {
            newListAgreements.splice(idFound, 1)
        }
        newListAgreements.unshift(agreementObj);

        newListAgreements = this.addStatus(newListAgreements);

        this.setState({
            listAgreements: newListAgreements,
        })
    }

    async getListAgreements() {
        let listAgreements = await agreementHelper.getMyAgreements();
        listAgreements = this.addStatus(listAgreements);
        this.setState({
            listAgreements: listAgreements,
        })
    }

    addStatus(listAgreements) {
        if (!listAgreements) return listAgreements
        for (let i = 0; i < listAgreements.length; i++) {
            let status = agreementHelper.getAgreementStatus(listAgreements[i])
            listAgreements[i].status = status;
            if (status === STATUS.USER1_EDITING) listAgreements[i].statusText = "Waiting for " + listAgreements[i].nameUser1 + " to finalize the eContract.";
            if (status === STATUS.WAIT_CONFIRMATION) listAgreements[i].statusText = "Waiting for " + listAgreements[i].nameUser2 + " to confirm.";
            if (status === STATUS.CONFIRMED) listAgreements[i].statusText = "eContract confirmed and locked.";
            if (status === STATUS.UNDEFINED) listAgreements[i].statusText = "Error.";
        }
        return listAgreements
    }

    handleDeleteModalClose() {
        this.setState({ dialogDeleteOpen: false });

    }

    handleDeleteModalOpen(agreementObj) {
        this.setState({ agreementobjToDelete: agreementObj, dialogDeleteOpen: true });
    }

    render() {
        const { listAgreements, dialogDeleteOpen, agreementobjToDelete } = this.state;
        return (
            <Box className="Agreements">
                <Container maxWidth="lg">
                    <Box marginBottom={3} color='white'>
                        <Hidden smDown implementation="css"><Typography variant="h2">My eContracts</Typography></Hidden>
                    </Box>
                </Container>
                <Container maxWidth="md">
                    {
                        listAgreements ?
                            <Box>
                                {
                                    listAgreements.length > 0 ?
                                        listAgreements.map((agreementObj, index) => {
                                            return <Box key={uuid()} className="agreement-container" display="flex" flexDirection="row" flexWrap="no-wrap">
                                                {
                                                    agreementObj.status === STATUS.USER1_EDITING ?
                                                        <Box className="agreement-editing" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <EditIcon className="icon" />
                                                        </Box>
                                                        :
                                                        null
                                                }
                                                {
                                                    agreementObj.status === STATUS.WAIT_CONFIRMATION ?
                                                        <Box className="agreement-editing" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <RefreshIcon className="icon" />
                                                        </Box>
                                                        :
                                                        null
                                                }
                                                {
                                                    agreementObj.status === STATUS.CONFIRMED ?
                                                        <Box className="agreement-locked" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <LockIcon className="icon" />
                                                        </Box>
                                                        :
                                                        null
                                                }

                                                <Box className="link-agreement-container" flexGrow={1} paddingTop={1} paddingBottom={1}>
                                                    <Link className="link-agreement" to={AgreementRoute + agreementObj.id} >
                                                        <Box marginBottom={1} marginTop={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <Typography variant="h5">{agreementObj.title}</Typography>
                                                        </Box>
                                                        <Box marginBottom={1} marginTop={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <Box>
                                                                <UserThumb userId={agreementObj.user1Sub} clickable={false} noStars={true} />
                                                            </Box>
                                                            <Box flexGrow={1}></Box>
                                                            <Box><UserThumb userId={agreementObj.user2Sub} clickable={false} noStars={true} /></Box>
                                                        </Box>
                                                        <Box marginBottom={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <Box><Typography><b>Status:</b> {agreementObj.statusText}</Typography></Box>
                                                        </Box>
                                                        {
                                                            agreementObj.updatedAt ?
                                                                <Box marginBottom={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                                    <Box flexGrow={1}></Box>
                                                                    <Box><Typography><b>Last update:</b> {new Date(agreementObj.updatedAt).toDateString()}</Typography></Box>
                                                                </Box>
                                                                :
                                                                null
                                                        }

                                                    </Link>
                                                </Box>
                                                {
                                                    agreementObj.status !== STATUS.CONFIRMED ?
                                                        <Box onClick={() => this.handleDeleteModalOpen(agreementObj)} className="agreement-delete" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <DeleteIcon className="icon" />
                                                        </Box>
                                                        :
                                                        null
                                                }

                                                {
                                                    agreementObj.status === STATUS.CONFIRMED ?
                                                        <Box className="agreement-locked" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                            <LockIcon className="icon" />
                                                        </Box>
                                                        :
                                                        null
                                                }

                                            </Box>
                                        })
                                        :
                                        <Box className="container">You don't have any eContract yet. To create one, go on the conversation with the person you want to work with and click "create a e-contract".</Box>
                                }
                            </Box>
                            :
                            <Box className="container">Loading</Box>
                    }
                    <ConfirmationDeleteAgreementModal
                        open={dialogDeleteOpen}
                        onClose={() => this.handleDeleteModalClose()}
                        agreementobj={agreementobjToDelete}
                    />
                </Container>
            </Box >
        );
    }
}

export default Agreements;