import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI
import "./ConfirmationDeleteAgreementModal.scss";
import { Box, Typography } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import agreementHelper from '../../utils/agreementHelper';

class ConfirmationDeleteAgreementModal extends Component {
    state = {
        errors: null,
        loading: false,
    }

    handleCancel = () => {
        this.setState({ loading: false });
        this.props.onClose();
    };

    async handleConfirmation() {
        if (!this.props.agreementObj && !this.props.agreementobj.id) return 
        this.setState({ loading: true });
        let errors = await agreementHelper.deleteAgreement(this.props.agreementobj.id)
        if (!errors) {
            this.props.onClose();
        }
        this.setState({
            loading: false,
            errors: errors,
        })
    }

    handleEntering = () => {

    }

    render() {
        const { loading, errors } = this.state;
        const { agreementobj, ...other } = this.props;
        if (!agreementobj) return null
        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                fullWidth
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
                className="ConfirmationDeleteAgreementModal"
            >
                <DialogTitle className="modal-title">Delete agreement confirmation</DialogTitle>
                <DialogContent className="main-content">
                    <Typography className="headline" >You are about to permanantly delete an unfinished agreement between {agreementobj.nameUser1} and {agreementobj.nameUser2}.</Typography>
                    <Typography>Please confirm</Typography>
                </DialogContent>
                <DialogContent>
                    {loadingDisplay}
                    {
                        errors ?
                            <Box color="red">
                                <ul>
                                    {errors.map((err, index) => {
                                        return <li key={index}>{err}</li>
                                    })}
                                </ul>
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'left' }} onClick={this.handleCancel} >
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'right', backgroundColor: '#CC0000' }} onClick={() => this.handleConfirmation()} >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDeleteAgreementModal.propTypes = {
    onClose: PropTypes.func,
    agreementobj: PropTypes.object,
};

export default ConfirmationDeleteAgreementModal;