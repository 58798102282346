export const countries =  [
    {
        value: "AFGHANISTAN",
        label: "Afghanistan"
    },
    {
        value: "ALAND ISLANDS",
        label: "Aland Islands"
    },
    {
        value: "ALBANIA",
        label: "Albania"
    },
    {
        value: "ALGERIA",
        label: "Algeria"
    },
    {
        value: "AMERICAN SAMOA",
        label: "American Samoa"
    },
    {
        value: "ANDORRA",
        label: "Andorra"
    },
    {
        value: "ANGOLA",
        label: "Angola"
    },
    {
        value: "ANGUILLA",
        label: "Anguilla"
    },
    {
        value: "ANTIGUA AND BARBUDA",
        label: "Antigua and Barbuda"
    },
    {
        value: "ARGENTINA",
        label: "Argentina"
    },
    {
        value: "ARMENIA",
        label: "Armenia"
    },
    {
        value: "ARUBA",
        label: "Aruba"
    },
    {
        value: "AUSTRALIA",
        label: "Australia"
    },
    {
        value: "AUSTRIA",
        label: "Austria"
    },
    {
        value: "AZERBAIJAN",
        label: "Azerbaijan"
    },
    {
        value: "BAHAMAS",
        label: "Bahamas"
    },
    {
        value: "BAHRAIN",
        label: "Bahrain"
    },
    {
        value: "BANGLADESH",
        label: "Bangladesh"
    },
    {
        value: "BARBADOS",
        label: "Barbados"
    },
    {
        value: "BELARUS",
        label: "Belarus"
    },
    {
        value: "BELGIUM",
        label: "Belgium"
    },
    {
        value: "BELIZE",
        label: "Belize"
    },
    {
        value: "BENIN",
        label: "Benin"
    },
    {
        value: "BERMUDA",
        label: "Bermuda"
    },
    {
        value: "BHUTAN",
        label: "Bhutan"
    },
    {
        value: "BOLIVIA",
        label: "Bolivia"
    },
    {
        value: "BONAIRE, SAINT EUSTATIUS AND SABA",
        label: "Bonaire, Saint Eustatius and Saba"
    },
    {
        value: "BOSNIA AND HERZEGOVINA",
        label: "Bosnia and Herzegovina"
    },
    {
        value: "BOTSWANA",
        label: "Botswana"
    },
    {
        value: "BRAZIL",
        label: "Brazil"
    },
    {
        value: "BRITISH VIRGIN ISLANDS",
        label: "British Virgin Islands"
    },
    {
        value: "BRUNEI",
        label: "Brunei"
    },
    {
        value: "BULGARIA",
        label: "Bulgaria"
    },
    {
        value: "BURKINA FASO",
        label: "Burkina Faso"
    },
    {
        value: "BURUNDI",
        label: "Burundi"
    },
    {
        value: "CAMBODIA",
        label: "Cambodia"
    },
    {
        value: "CAMEROON",
        label: "Cameroon"
    },
    {
        value: "CANADA",
        label: "Canada"
    },
    {
        value: "CAPE VERDE",
        label: "Cape Verde"
    },
    {
        value: "CAYMAN ISLANDS",
        label: "Cayman Islands"
    },
    {
        value: "CENTRAL AFRICAN REPUBLIC",
        label: "Central African Republic"
    },
    {
        value: "CHAD",
        label: "Chad"
    },
    {
        value: "CHILE",
        label: "Chile"
    },
    {
        value: "CHINA",
        label: "China"
    },
    {
        value: "CHRISTMAS ISLAND",
        label: "Christmas Island"
    },
    {
        value: "COCOS ISLANDS",
        label: "Cocos Islands"
    },
    {
        value: "COLOMBIA",
        label: "Colombia"
    },
    {
        value: "COMOROS",
        label: "Comoros"
    },
    {
        value: "COOK ISLANDS",
        label: "Cook Islands"
    },
    {
        value: "COSTA RICA",
        label: "Costa Rica"
    },
    {
        value: "CROATIA",
        label: "Croatia"
    },
    {
        value: "CUBA",
        label: "Cuba"
    },
    {
        value: "CURACAO",
        label: "Curacao"
    },
    {
        value: "CYPRUS",
        label: "Cyprus"
    },
    {
        value: "CZECH REPUBLIC",
        label: "Czech Republic"
    },
    {
        value: "DEMOCRATIC REPUBLIC OF THE CONGO",
        label: "Democratic Republic of the Congo"
    },
    {
        value: "DENMARK",
        label: "Denmark"
    },
    {
        value: "DJIBOUTI",
        label: "Djibouti"
    },
    {
        value: "DOMINICA",
        label: "Dominica"
    },
    {
        value: "DOMINICAN REPUBLIC",
        label: "Dominican Republic"
    },
    {
        value: "EAST TIMOR",
        label: "East Timor"
    },
    {
        value: "ECUADOR",
        label: "Ecuador"
    },
    {
        value: "EGYPT",
        label: "Egypt"
    },
    {
        value: "EL SALVADOR",
        label: "El Salvador"
    },
    {
        value: "EQUATORIAL GUINEA",
        label: "Equatorial Guinea"
    },
    {
        value: "ERITREA",
        label: "Eritrea"
    },
    {
        value: "ESTONIA",
        label: "Estonia"
    },
    {
        value: "ETHIOPIA",
        label: "Ethiopia"
    },
    {
        value: "FALKLAND ISLANDS",
        label: "Falkland Islands"
    },
    {
        value: "FAROE ISLANDS",
        label: "Faroe Islands"
    },
    {
        value: "FIJI",
        label: "Fiji"
    },
    {
        value: "FINLAND",
        label: "Finland"
    },
    {
        value: "FRANCE",
        label: "France"
    },
    {
        value: "FRENCH GUIANA",
        label: "French Guiana"
    },
    {
        value: "FRENCH POLYNESIA",
        label: "French Polynesia"
    },
    {
        value: "FRENCH SOUTHERN TERRITORIES",
        label: "French Southern Territories"
    },
    {
        value: "GABON",
        label: "Gabon"
    },
    {
        value: "GAMBIA",
        label: "Gambia"
    },
    {
        value: "GEORGIA",
        label: "Georgia"
    },
    {
        value: "GERMANY",
        label: "Germany"
    },
    {
        value: "GHANA",
        label: "Ghana"
    },
    {
        value: "GIBRALTAR",
        label: "Gibraltar"
    },
    {
        value: "GREECE",
        label: "Greece"
    },
    {
        value: "GREENLAND",
        label: "Greenland"
    },
    {
        value: "GRENADA",
        label: "Grenada"
    },
    {
        value: "GUADELOUPE",
        label: "Guadeloupe"
    },
    {
        value: "GUAM",
        label: "Guam"
    },
    {
        value: "GUATEMALA",
        label: "Guatemala"
    },
    {
        value: "GUERNSEY",
        label: "Guernsey"
    },
    {
        value: "GUINEA",
        label: "Guinea"
    },
    {
        value: "GUINEA-BISSAU",
        label: "Guinea-Bissau"
    },
    {
        value: "GUYANA",
        label: "Guyana"
    },
    {
        value: "HAITI",
        label: "Haiti"
    },
    {
        value: "HONDURAS",
        label: "Honduras"
    },
    {
        value: "HONG KONG",
        label: "Hong Kong"
    },
    {
        value: "HUNGARY",
        label: "Hungary"
    },
    {
        value: "ICELAND",
        label: "Iceland"
    },
    {
        value: "INDIA",
        label: "India"
    },
    {
        value: "INDONESIA",
        label: "Indonesia"
    },
    {
        value: "IRAN",
        label: "Iran"
    },
    {
        value: "IRAQ",
        label: "Iraq"
    },
    {
        value: "IRELAND",
        label: "Ireland"
    },
    {
        value: "ISLE OF MAN",
        label: "Isle of Man"
    },
    {
        value: "ISRAEL",
        label: "Israel"
    },
    {
        value: "ITALY",
        label: "Italy"
    },
    {
        value: "IVORY COAST",
        label: "Ivory Coast"
    },
    {
        value: "JAMAICA",
        label: "Jamaica"
    },
    {
        value: "JAPAN",
        label: "Japan"
    },
    {
        value: "JERSEY",
        label: "Jersey"
    },
    {
        value: "JORDAN",
        label: "Jordan"
    },
    {
        value: "KAZAKHSTAN",
        label: "Kazakhstan"
    },
    {
        value: "KENYA",
        label: "Kenya"
    },
    {
        value: "KIRIBATI",
        label: "Kiribati"
    },
    {
        value: "KOSOVO",
        label: "Kosovo"
    },
    {
        value: "KUWAIT",
        label: "Kuwait"
    },
    {
        value: "KYRGYZSTAN",
        label: "Kyrgyzstan"
    },
    {
        value: "LAOS",
        label: "Laos"
    },
    {
        value: "LATVIA",
        label: "Latvia"
    },
    {
        value: "LEBANON",
        label: "Lebanon"
    },
    {
        value: "LESOTHO",
        label: "Lesotho"
    },
    {
        value: "LIBERIA",
        label: "Liberia"
    },
    {
        value: "LIBYA",
        label: "Libya"
    },
    {
        value: "LIECHTENSTEIN",
        label: "Liechtenstein"
    },
    {
        value: "LITHUANIA",
        label: "Lithuania"
    },
    {
        value: "LUXEMBOURG",
        label: "Luxembourg"
    },
    {
        value: "MACAO",
        label: "Macao"
    },
    {
        value: "MACEDONIA",
        label: "Macedonia"
    },
    {
        value: "MADAGASCAR",
        label: "Madagascar"
    },
    {
        value: "MALAWI",
        label: "Malawi"
    },
    {
        value: "MALAYSIA",
        label: "Malaysia"
    },
    {
        value: "MALDIVES",
        label: "Maldives"
    },
    {
        value: "MALI",
        label: "Mali"
    },
    {
        value: "MALTA",
        label: "Malta"
    },
    {
        value: "MARSHALL ISLANDS",
        label: "Marshall Islands"
    },
    {
        value: "MARTINIQUE",
        label: "Martinique"
    },
    {
        value: "MAURITANIA",
        label: "Mauritania"
    },
    {
        value: "MAURITIUS",
        label: "Mauritius"
    },
    {
        value: "MAYOTTE",
        label: "Mayotte"
    },
    {
        value: "MEXICO",
        label: "Mexico"
    },
    {
        value: "MICRONESIA",
        label: "Micronesia"
    },
    {
        value: "MOLDOVA",
        label: "Moldova"
    },
    {
        value: "MONACO",
        label: "Monaco"
    },
    {
        value: "MONGOLIA",
        label: "Mongolia"
    },
    {
        value: "MONTENEGRO",
        label: "Montenegro"
    },
    {
        value: "MONTSERRAT",
        label: "Montserrat"
    },
    {
        value: "MOROCCO",
        label: "Morocco"
    },
    {
        value: "MOZAMBIQUE",
        label: "Mozambique"
    },
    {
        value: "MYANMAR",
        label: "Myanmar"
    },
    {
        value: "NAMIBIA",
        label: "Namibia"
    },
    {
        value: "NAURU",
        label: "Nauru"
    },
    {
        value: "NEPAL",
        label: "Nepal"
    },
    {
        value: "NETHERLANDS",
        label: "Netherlands"
    },
    {
        value: "NEW CALEDONIA",
        label: "New Caledonia"
    },
    {
        value: "NEW ZEALAND",
        label: "New Zealand"
    },
    {
        value: "NICARAGUA",
        label: "Nicaragua"
    },
    {
        value: "NIGER",
        label: "Niger"
    },
    {
        value: "NIGERIA",
        label: "Nigeria"
    },
    {
        value: "NIUE",
        label: "Niue"
    },
    {
        value: "NORFOLK ISLAND",
        label: "Norfolk Island"
    },
    {
        value: "NORTH KOREA",
        label: "North Korea"
    },
    {
        value: "NORTHERN MARIANA ISLANDS",
        label: "Northern Mariana Islands"
    },
    {
        value: "NORWAY",
        label: "Norway"
    },
    {
        value: "OMAN",
        label: "Oman"
    },
    {
        value: "PAKISTAN",
        label: "Pakistan"
    },
    {
        value: "PALAU",
        label: "Palau"
    },
    {
        value: "PALESTINIAN TERRITORY",
        label: "Palestinian Territory"
    },
    {
        value: "PANAMA",
        label: "Panama"
    },
    {
        value: "PAPUA NEW GUINEA",
        label: "Papua New Guinea"
    },
    {
        value: "PARAGUAY",
        label: "Paraguay"
    },
    {
        value: "PERU",
        label: "Peru"
    },
    {
        value: "PHILIPPINES",
        label: "Philippines"
    },
    {
        value: "PITCAIRN",
        label: "Pitcairn"
    },
    {
        value: "POLAND",
        label: "Poland"
    },
    {
        value: "PORTUGAL",
        label: "Portugal"
    },
    {
        value: "PUERTO RICO",
        label: "Puerto Rico"
    },
    {
        value: "QATAR",
        label: "Qatar"
    },
    {
        value: "REPUBLIC OF THE CONGO",
        label: "Republic of the Congo"
    },
    {
        value: "REUNION",
        label: "Reunion"
    },
    {
        value: "ROMANIA",
        label: "Romania"
    },
    {
        value: "RUSSIA",
        label: "Russia"
    },
    {
        value: "RWANDA",
        label: "Rwanda"
    },
    {
        value: "SAINT BARTHELEMY",
        label: "Saint Barthelemy"
    },
    {
        value: "SAINT HELENA",
        label: "Saint Helena"
    },
    {
        value: "SAINT KITTS AND NEVIS",
        label: "Saint Kitts and Nevis"
    },
    {
        value: "SAINT LUCIA",
        label: "Saint Lucia"
    },
    {
        value: "SAINT MARTIN",
        label: "Saint Martin"
    },
    {
        value: "SAINT PIERRE AND MIQUELON",
        label: "Saint Pierre and Miquelon"
    },
    {
        value: "SAINT VINCENT AND THE GRENADINES",
        label: "Saint Vincent and the Grenadines"
    },
    {
        value: "SAMOA",
        label: "Samoa"
    },
    {
        value: "SAN MARINO",
        label: "San Marino"
    },
    {
        value: "SAO TOME AND PRINCIPE",
        label: "Sao Tome and Principe"
    },
    {
        value: "SAUDI ARABIA",
        label: "Saudi Arabia"
    },
    {
        value: "SENEGAL",
        label: "Senegal"
    },
    {
        value: "SERBIA",
        label: "Serbia"
    },
    {
        value: "SEYCHELLES",
        label: "Seychelles"
    },
    {
        value: "SIERRA LEONE",
        label: "Sierra Leone"
    },
    {
        value: "SINGAPORE",
        label: "Singapore"
    },
    {
        value: "SINT MAARTEN",
        label: "Sint Maarten"
    },
    {
        value: "SLOVAKIA",
        label: "Slovakia"
    },
    {
        value: "SLOVENIA",
        label: "Slovenia"
    },
    {
        value: "SOLOMON ISLANDS",
        label: "Solomon Islands"
    },
    {
        value: "SOMALIA",
        label: "Somalia"
    },
    {
        value: "SOUTH AFRICA",
        label: "South Africa"
    },
    {
        value: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
        label: "South Georgia and the South Sandwich Islands"
    },
    {
        value: "SOUTH KOREA",
        label: "South Korea"
    },
    {
        value: "SOUTH SUDAN",
        label: "South Sudan"
    },
    {
        value: "SPAIN",
        label: "Spain"
    },
    {
        value: "SRI LANKA",
        label: "Sri Lanka"
    },
    {
        value: "SUDAN",
        label: "Sudan"
    },
    {
        value: "SURINAME",
        label: "Suriname"
    },
    {
        value: "SVALBARD AND JAN MAYEN",
        label: "Svalbard and Jan Mayen"
    },
    {
        value: "SWAZILAND",
        label: "Swaziland"
    },
    {
        value: "SWEDEN",
        label: "Sweden"
    },
    {
        value: "SWITZERLAND",
        label: "Switzerland"
    },
    {
        value: "SYRIA",
        label: "Syria"
    },
    {
        value: "TAIWAN",
        label: "Taiwan"
    },
    {
        value: "TAJIKISTAN",
        label: "Tajikistan"
    },
    {
        value: "TANZANIA",
        label: "Tanzania"
    },
    {
        value: "THAILAND",
        label: "Thailand"
    },
    {
        value: "TOGO",
        label: "Togo"
    },
    {
        value: "TONGA",
        label: "Tonga"
    },
    {
        value: "TRINIDAD AND TOBAGO",
        label: "Trinidad and Tobago"
    },
    {
        value: "TUNISIA",
        label: "Tunisia"
    },
    {
        value: "TURKEY",
        label: "Turkey"
    },
    {
        value: "TURKMENISTAN",
        label: "Turkmenistan"
    },
    {
        value: "TURKS AND CAICOS ISLANDS",
        label: "Turks and Caicos Islands"
    },
    {
        value: "TUVALU",
        label: "Tuvalu"
    },
    {
        value: "U.S. VIRGIN ISLANDS",
        label: "U.S. Virgin Islands"
    },
    {
        value: "UGANDA",
        label: "Uganda"
    },
    {
        value: "UKRAINE",
        label: "Ukraine"
    },
    {
        value: "UNITED ARAB EMIRATES",
        label: "United Arab Emirates"
    },
    {
        value: "UNITED KINGDOM",
        label: "United Kingdom"
    },
    {
        value: "UNITED STATES",
        label: "United States"
    },
    {
        value: "URUGUAY",
        label: "Uruguay"
    },
    {
        value: "UZBEKISTAN",
        label: "Uzbekistan"
    },
    {
        value: "VANUATU",
        label: "Vanuatu"
    },
    {
        value: "VATICAN",
        label: "Vatican"
    },
    {
        value: "VENEZUELA",
        label: "Venezuela"
    },
    {
        value: "VIETNAM",
        label: "Vietnam"
    },
    {
        value: "WALLIS AND FUTUNA",
        label: "Wallis and Futuna"
    },
    {
        value: "WESTERN SAHARA",
        label: "Western Sahara"
    },
    {
        value: "YEMEN",
        label: "Yemen"
    },
    {
        value: "ZAMBIA",
        label: "Zambia"
    },
    {
        value: "ZIMBABWE",
        label: "Zimbabwe"
    }
]