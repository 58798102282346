import React, { Component } from 'react';
import './Posts.scss';
import { Typography, Box, Container } from '@material-ui/core';
import PostsSearch from '../../componants/PostsSearch/PostsSearch';

import SearchListPosts from '../../componants/SearchListPosts/SearchListPosts';
import SelectCategory from '../../componants/SelectCategory/SelectCategory';
import SelectCountryCity from '../../componants/SelectCountryCity/SelectCountryCity';

class Posts extends Component {
    constructor() {
        super();
        this.onSearch = this.onSearch.bind(this)
    }

    state = {
        searchInput: null,
    };

    onSearch(searchData) {
        this.setState({
            searchInput: searchData,
        })
    }

    render() {
        const { searchInput } = this.state;
        console.log(searchInput)
        return (
            <Box className="Posts">
                <Container maxWidth="lg">
                    <Box marginBottom={3}>
                        <Typography component="h2" variant="h2">Find a Pilot</Typography>
                    </Box>
                </Container>
                <Container maxWidth="md">
                    <Box marginBottom={7}>
                        <PostsSearch onSearch={this.onSearch} />
                    </Box>
                </Container>

                <Box className="postResults">
                    <Container maxWidth="md">
                        {
                            searchInput ?
                                <Box marginBottom={3}>
                                    <Typography>Searching pilots for :</Typography>
                                    <SelectCategory category={searchInput.category} tagId={searchInput.tag} subtagId={searchInput.subTag} />
                                    <SelectCountryCity country={searchInput.country} cityId={searchInput.city} />
                                </Box>
                                :
                                <Box marginBottom={3}>
                                    <Typography>Here are the lastest posts on ClicknDrone. To find the perfect drone pilot near you, use the search tool above.</Typography>
                                </Box>
                        }
                    </Container>

                    <SearchListPosts displayLastest={searchInput ? false : true} searchInput={searchInput} />
                </Box>

            </Box>
        );
    }
}

export default Posts;