import React, { Component } from 'react';
import './Post.scss';
import Box from '@material-ui/core/Box';
import Utils from '../../utils/Utils';
import { Typography, Container } from '@material-ui/core';
import PostTitleTextField from '../../componants/PostTitleTextField/PostTitleTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import UserThumb from '../../componants/UserThumb/UserThumb';
import ButtonContact from '../../componants/ButtonContact/ButtonContact';
import PostLocationWrapper from '../../componants/PostLocationWrapper/PostLocationWrapper';
import PostCategoryWrapper from '../../componants/PostCategoryWrapper/PostCategoryWrapper';
import PostPriceWrapper from '../../componants/PostPriceWrapper/PostPriceWrapper';
import PostDescriptionTextField from '../../componants/PostDescriptionTextField/PostDescriptionTextField';
import PostGalleryWrapper from '../../componants/PostGalleryWrapper/PostGalleryWrapper';
import ButtonDeletePost from '../../componants/ButtonDeletePost/ButtonDeletePost';

class Mission extends Component {
    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }


    state = {
        postId: null,
        error: false,
        errorMessage: "",

        listImgKey: null,
        title: null,
        description: null,
        price: null,
        currency: null,
        label: null,
        country: null,
        cityId: null,
        userSub: null,
        identityId: null,
    };

    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
        this.setPostId();
    }

    setPostId() {
        if (Utils.checkNested(this.props, "match", "params", "id")) {
            this.loadInfo(this.props.match.params.id);
        } else {
            this.setState({
                error: true,
                errorMessage: "Sorry, we can't find the post you are looking for."
            })
        }
    }

    async loadInfo(postId) {
        if (!postId) return;

        try {
            const getService = await API.graphql(graphqlOperation(queries.getService, { id: postId }));
            if (!Utils.checkNested(getService, "data", "getService")) {
                this.setState({
                    error: true,
                    errorMessage: "Sorry, we can't find the post you are looking for."
                })
            }
            let serviceData = getService.data.getService;
            let ownPost = false;
            let currentUser = await Utils.getCurrentUserSub();
            if (currentUser === serviceData.user) {
                ownPost = true;
            }

            let identityId = await Utils.getIdentityIdFromSub(serviceData.user);

            this.setState({
                postId: postId,
                listImgKey: serviceData.imageKey,
                title: serviceData.title,
                description: serviceData.description,
                price: serviceData.price,
                currency: serviceData.currency,
                label: serviceData.priceLabel,
                category: serviceData.category,
                tagId: serviceData.tag,
                subtagId: serviceData.subTag,
                date: serviceData.updatedAt,
                country: serviceData.country,
                cityId: serviceData.city,
                userSub: serviceData.user,
                identityId: identityId,
                ownPost: ownPost,
            })
        } catch (e) {
            this.setState({
                error: true,
                errorMessage: "Sorry, we can't find the post you are looking for."
            })
        }
    }

    numberToString(number) {
        if (!number) return null;
        return number.toString();
    }

    async onUpdate(newInfo) {
        this.setState({
            title: newInfo.title !== undefined ? newInfo.title : this.state.title,
            description: newInfo.description !== undefined ? newInfo.description : this.state.description,

            price: newInfo.price !== undefined ? newInfo.price : this.state.price,
            currency: newInfo.currency !== undefined ? newInfo.currency : this.state.currency,
            label: newInfo.priceLabel !== undefined ? newInfo.priceLabel : this.state.label,

            category: newInfo.category !== undefined ? newInfo.category : this.state.category,
            tagId: newInfo.tag !== undefined ? newInfo.tag : this.state.tagId,
            subtagId: newInfo.subTag !== undefined ? newInfo.subTag : this.state.subtagId,

            //date: serviceData.updatedAt,

            country: newInfo.country !== undefined ? newInfo.country : this.state.country,
            cityId: newInfo.city !== undefined ? newInfo.city : this.state.cityId,
        })
    }

    render() {
        let {
            postId,
            listImgKey,
            title,
            description,
            price,
            currency,
            label,
            category,
            tagId,
            subtagId,
            date,
            country,
            cityId,
            userSub,
            identityId,
            error,
            errorMessage,
            ownPost
        } = this.state;
        let dateToDisplayed = Utils.isoDateToReadable(date);
        let priceString = null;
        if (price) { priceString = price.toString(); }
        return (
            <Container maxWidth="md">
                <Box className="Post">
                    {
                        error ?
                            <Box className="error-message container-border"><Typography>{errorMessage}</Typography></Box>
                            :
                            <Box>
                                {
                                    ownPost ?
                                        <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" alignItems="center">
                                            <Box flexGrow={1}></Box>
                                            <Box><ButtonDeletePost postId={postId} /></Box>
                                        </Box>
                                        :
                                        null
                                }

                                <Box className="container-border">
                                    <Box marginBottom="1em">
                                        <PostTitleTextField
                                            editable={ownPost}
                                            title={title}
                                            postId={postId}

                                            country={country}
                                            cityId={cityId}
                                            category={category}
                                            tagId={tagId}
                                            subtagId={subtagId}

                                            onUpdate={this.onUpdate}

                                            width="100%"
                                        />
                                    </Box>

                                    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%" alignItems="center">
                                        <Box marginRight="1em" marginTop="1em"><UserThumb userId={userSub} clickable={true} /></Box>
                                        <Box marginTop="1em"><ButtonContact disabled={ownPost} userIdToContact={userSub} /></Box>

                                        <Box flexGrow={1}></Box>
                                        <Box marginTop="1em"><Typography className="date-typo">Last update: <b>{dateToDisplayed}</b></Typography></Box>

                                    </Box>
                                </Box>
                                <Box className="container-border">
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                                        <Box flexGrow={1} marginBottom="0.5em">
                                            <PostCategoryWrapper
                                                editable={ownPost}
                                                country={country}
                                                cityId={cityId}
                                                postId={postId}
                                                category={category}
                                                tagId={tagId}
                                                subtagId={subtagId}

                                                onUpdate={this.onUpdate}
                                            />
                                        </Box>
                                        <Box>
                                            <PostPriceWrapper
                                                editable={ownPost}
                                                price={priceString}
                                                currency={currency}
                                                label={label}

                                                country={country}
                                                cityId={cityId}
                                                postId={postId}
                                                category={category}
                                                tagId={tagId}
                                                subtagId={subtagId}

                                                onUpdate={this.onUpdate}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                                        <Box>
                                            <PostLocationWrapper
                                                editable={ownPost}
                                                country={country}
                                                cityId={cityId}
                                                postId={postId}
                                                category={category}
                                                tagId={tagId}
                                                subtagId={subtagId}

                                                onUpdate={this.onUpdate}
                                            />
                                        </Box>


                                    </Box>
                                </Box>
                                <Box className="container-border">
                                    <PostDescriptionTextField
                                        editable={ownPost}
                                        description={description}
                                        postId={postId}

                                        country={country}
                                        cityId={cityId}
                                        category={category}
                                        tagId={tagId}
                                        subtagId={subtagId}

                                        onUpdate={this.onUpdate}

                                        width="100%"
                                    />
                                </Box>
                                <Box className="container-border" width="100%">
                                    <PostGalleryWrapper
                                        postId={postId}
                                        listImgKey={listImgKey}
                                        identityId={identityId}
                                        isOwner={ownPost}

                                        country={country}
                                        cityId={cityId}
                                        category={category}
                                        tagId={tagId}
                                        subtagId={subtagId}
                                    />
                                </Box>

                            </Box>
                    }
                </Box>
            </Container>
        );
    }
}

export default Mission;