import React, { Component } from 'react';
import './ListReviews.scss';
import Box from '@material-ui/core/Box';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Utils from '../../utils/Utils';
import CardReview from '../CardReview/CardReview';
import uuid from 'react-uuid';

class ListReviews extends Component {
    state = {
        listReviews: null,
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "userSub")) {
            this.getUserReviews(this.props.userSub)
        }
    }

    componentDidUpdate(prevProps) {
        let subFromProps = Utils.checkNested(this, "props", "userSub");
        let subFromPrevProps = Utils.checkNested(prevProps, 'userSub');
        if (subFromProps !== subFromPrevProps) {
            this.getUserReviews(subFromProps)
        }
    }

    async getUserReviews(sub) {
        try {
            const getReviews = await API.graphql(graphqlOperation(queries.reviewsByUserTo, { userToSub: sub, sortDirection: "DESC" }));
            let listReviews = Utils.checkNested(getReviews, "data", "reviewsByUserTo", "items");
            this.setState({
                listReviews: listReviews,
            });
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { listReviews } = this.state;
        return (
            <Box className="ListReviews">
                {
                    listReviews === null ?
                        <Box className="container"><Typography>Loading...</Typography></Box>
                        :
                        <Box>
                            {listReviews.length > 0 ?
                                listReviews.map((review) => {
                                    return <CardReview
                                        key={uuid()}
                                        userFromSub={review.userFromSub}
                                        rating={review.rating}
                                        content={review.content}
                                        date={review.postedAt}
                                    />
                                })
                                :
                                <Box className="container"><Typography>No review yet.</Typography></Box>
                            }
                        </Box>
                }
            </Box>
        );
    }
}

ListReviews.propTypes = {
    userSub: PropTypes.string,
};

export default ListReviews;