import React, { Component } from 'react';
import './SearchListPosts.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import CardPost from '../CardPost/CardPost';
import uuid from "react-uuid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from '../../customGraphql/queries';
import * as normalQueries from '../../graphql/queries';

import { Box, Typography, Container } from '@material-ui/core';
import { categoryList } from '../../utils/Constants';

const limit = 10;
class SearchListPosts extends Component {
    state = {
        listPosts: [],
        disabledAddPost: true,
        loading: false,
        tokenList: [null],
        tokenIndex: 0,
    };

    constructor() {
        super();
        this.loadPosts = this.loadPosts.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "searchInput")) {
            this.loadPosts()
        } else if (Utils.checkNested(this, "props", "displayLastest")) {
            this.loadLastest();
        }


    }

    componentDidUpdate(prevProps) {
        let fromProps = Utils.checkNested(this, "props", "searchInput");
        let fromPrevProps = Utils.checkNested(prevProps, 'searchInput');
        if (fromProps !== fromPrevProps) {
            this.resetTokens();
            this.loadPosts();
        }
    }

    resetTokens() {
        this.setState({
            tokenList: [null],
            tokenIndex: 0,
        })
    }

    async loadLastest() {
        try {
            this.startLoading();
            let input = {
                category: categoryList[0].value,
                limit: limit,
                sortDirection: 'DESC',                
            }
            let getPosts = await API.graphql(graphqlOperation(normalQueries.servicesByCategory, input));
            let resultItems = Utils.checkNested(getPosts, 'data', 'servicesByCategory', 'items');
            this.setState({
                listPosts: resultItems,
            })
        } catch (e) {
            console.log(e)
        }
        this.stopLoading();
    }

    async loadPosts(startToken = null) {
        let searchInput = this.props.searchInput;
        if (!searchInput) return
        if (!searchInput.country) return
        // Set the search to run 
        // Default
        let query = customQueries.servicesByCountryShort;
        let queryName = 'servicesByCountry';
        let input = {
            limit: limit,
            sortDirection: 'DESC',
            nextToken: startToken,
            country: searchInput.country,
            categoryUpdatedAt: { beginsWith: { category: searchInput.category } },
        }
        // Subtag and city
        if (searchInput.subTag && searchInput.city) {
            query = customQueries.servicesByCitySubtagShort;
            queryName = 'servicesByCitySubtag';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                country: searchInput.country,
                citySubTagUpdatedAt: { beginsWith: { city: searchInput.city, subTag: searchInput.subTag } },
            }
        } else if (searchInput.tag && searchInput.city) {
            // Tag and city
            query = customQueries.servicesByCityTagShort;
            queryName = 'servicesByCityTag';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                country: searchInput.country,
                cityTagUpdatedAt: { beginsWith: { city: searchInput.city, tag: searchInput.tag } },
            }
        } else if (searchInput.subTag) {
            // Subtag
            query = customQueries.servicesByCountrySubtagShort;
            queryName = 'servicesByCountrySubtag';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                country: searchInput.country,
                subTagUpdatedAt: { beginsWith: { subTag: searchInput.subTag } },
            }
        } else if (searchInput.tag) {
            // Tag
            query = customQueries.servicesByCountryTagShort;
            queryName = 'servicesByCountryTag';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                country: searchInput.country,
                tagUpdatedAt: { beginsWith: { tag: searchInput.tag } },
            }
        } else if (searchInput.city) {
            // City
            query = customQueries.servicesByCityShort;
            queryName = 'servicesByCity';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                country: searchInput.country,
                cityCategoryUpdatedAt: { beginsWith: { city: searchInput.city, category: searchInput.category } },
            }
        }
        try {
            this.startLoading();
            let getPosts = await API.graphql(graphqlOperation(query, input));
            let resultItems = Utils.checkNested(getPosts, 'data', queryName, 'items');
            if (!resultItems) {
                this.stopLoading()
                return
            }
            // Add the token if we are at the last element of the list and if there is a next page
            let updatedNextToken = Utils.checkNested(getPosts, 'data', queryName, 'nextToken');
            let updatedListToken = this.state.tokenList;
            if (updatedNextToken && (this.state.tokenIndex + 1 >= updatedListToken.length)) {
                updatedListToken.push(updatedNextToken)
            }
            this.setState({
                listPosts: resultItems,
                tokenList: updatedListToken,
            })
        } catch (e) {
            console.log(e)
        }
        this.stopLoading()
    }

    startLoading() {
        this.setState({ loading: true, listPosts: [] })
    }

    stopLoading() {
        this.setState({ loading: false })
    }

    onPrevous() {
        // Check if there is a previous
        if (this.state.tokenIndex <= 0) return
        // If yes update the index and load info
        this.loadPosts(this.state.tokenList[this.state.tokenIndex - 1]);
        this.setState({
            tokenIndex: this.state.tokenIndex - 1,
        })
    }

    onNext() {
        // Check if their is a next index on the list
        if (this.state.tokenList.length <= this.state.tokenIndex) return
        // If yes then update the index and load info for it
        this.loadPosts(this.state.tokenList[this.state.tokenIndex + 1]);
        this.setState({
            tokenIndex: this.state.tokenIndex + 1,
        })
    }

    jumpTo(index) {
        // Check if their is a next index on the list
        if (this.state.tokenList.length <= index) return
        // If yes then update the index and load info for it
        this.loadPosts(this.state.tokenList[index]);
        this.setState({
            tokenIndex: index,
        })
    }

    render() {
        const { listPosts, loading, tokenList, tokenIndex } = this.state;
        let nextToken = undefined;
        let prevousToken = undefined;
        if (tokenList.length > tokenIndex) { nextToken = tokenList[tokenIndex + 1]; }
        if (tokenIndex > 0) { prevousToken = tokenList[tokenIndex - 1]; }
        return (
            <Box className="SearchListPosts">
                <Container maxWidth="md">
                    {
                        prevousToken !== undefined || nextToken !== undefined ?
                            <Box width="100" display="flex" marginBottom={2}>
                                <Box>
                                    <Button disabled={prevousToken === undefined} color="secondary" variant="contained" size="small" onClick={() => this.onPrevous()}>
                                        <NavigateBeforeIcon />
                                        Previous
                                </Button>
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box display="flex" alignItems="center" className="pagination">
                                    {
                                        tokenList.map((token, index) => {
                                            return <Button color={index === tokenIndex ? "secondary" : "white"} variant="contained" size="small" key={uuid()} onClick={() => this.jumpTo(index)}>{index + 1}</Button>
                                        })
                                    }
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box textAlign="right">
                                    <Button disabled={nextToken === undefined} color="secondary" variant="contained" size="small" onClick={() => this.onNext()}>
                                        Next
                                    <NavigateNextIcon />
                                    </Button>
                                </Box>
                            </Box>
                            :
                            null
                    }


                    {listPosts.length > 0 ?
                        listPosts.map((post, index) => {
                            return <CardPost key={uuid()} postId={post.id}></CardPost>
                        })
                        :
                        <Box>
                            {
                                loading ?
                                    <Box className="loading"><CircularProgress /></Box>
                                    :
                                    <Box className="no-post"><Typography>Sorry, we didn't found any pilot for this search</Typography></Box>
                            }
                        </Box>
                    }

                    {
                        prevousToken !== undefined || nextToken !== undefined ?
                            <Box width="100" display="flex" marginTop={2}>
                                <Box>
                                    <Button disabled={prevousToken === undefined} color="secondary" variant="contained" size="small" onClick={() => this.onPrevous()}>
                                        <NavigateBeforeIcon />
                                        Previous
                                </Button>
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box display="flex" alignItems="center" className="pagination">
                                    {
                                        tokenList.map((token, index) => {
                                            return <Button color={index === tokenIndex ? "secondary" : "white"} variant="contained" size="small" key={uuid()} onClick={() => this.jumpTo(index)}>{index + 1}</Button>
                                        })
                                    }
                                </Box>
                                <Box flexGrow={1}></Box>
                                <Box textAlign="right">
                                    <Button disabled={nextToken === undefined} color="secondary" variant="contained" size="small" onClick={() => this.onNext()}>
                                        Next
                                    <NavigateNextIcon />
                                    </Button>
                                </Box>
                            </Box>
                            :
                            null
                    }
                </Container>
            </Box>
        );
    }
}

SearchListPosts.propTypes = {
    searchInput: PropTypes.object,
};

export default SearchListPosts;
