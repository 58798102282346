import React, { Component } from 'react';
import './UserDetail.scss';
import Box from '@material-ui/core/Box';
import Utils from '../../utils/Utils';
import UserDescriptionTextField from '../UserDescriptionTextField/UserDescriptionTextField';
import UserTitleTextField from '../UserTitleTextField/UserTitleTextField';

class UserDetail extends Component {
    render() {
        const { ownProfile, userInfo } = this.props;

        let description = null;
        if (Utils.checkNested(userInfo, "description") !== null) {
            description = userInfo.description;
        }

        let title = null;
        if (Utils.checkNested(userInfo, "title") !== null) {
            title = userInfo.title;
        }

        return (
            <Box className="UserDetail">
                <UserTitleTextField editable={ownProfile} title={title}/>
                <UserDescriptionTextField editable={ownProfile} description={description}/>
            </Box>
        );
    }
}

export default UserDetail;