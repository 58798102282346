import React, { Component } from 'react';
import './Agreement.scss';
import Box from '@material-ui/core/Box';
import { Hidden, Typography, Divider, Button, Container } from '@material-ui/core';
import UserThumb from '../../componants/UserThumb/UserThumb';
import agreementHelper, { STATUS } from '../../utils/agreementHelper';
import Utils from '../../utils/Utils';
import AgreementContentTextField from '../../componants/AgreementContentTextField/AgreementContentTextField';
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from '../../graphql/subscriptions';
import ButtonContact from '../../componants/ButtonContact/ButtonContact';
import AgreementTitleTextField from '../../componants/AgreementTitleTextField/AgreementTitleTextField';
import ReviewButton from '../../componants/ReviewButton/ReviewButton';
import reviewHelper from '../../utils/reviewHelper';
import CardReview from '../../componants/CardReview/CardReview';

class Agreement extends Component {
    state = {
        agreementId: null,
        currentUserSub: null,
        user1Sub: null,
        user2Sub: null,
        user1Name: null,
        user2Name: null,
        user1Mail: null,
        user2Mail: null,
        identityIdUser1: null,
        creationDate: null,
        content: "",
        title: "",
        statusText: null,
        status: null,
        loading: false,
        validationDateUser1: null,
        validationDateUser2: null,
        deletableBy: null,
        updatableBy: null,
        listReviews: [],
    }

    componentDidMount() {
        if (Utils.checkNested(this.props, 'match', 'params', 'id')) {
            this.getAgreement(this.props.match.params.id);
            this.subscribeUpdateAgreement(this.props.match.params.id);
        }
    }

    componentWillUnmount() {
        this.subscriptionUpdate.unsubscribe();
    }

    async subscribeUpdateAgreement(agreementId) {
        this.subscriptionUpdate = API.graphql(
            graphqlOperation(subscriptions.onUpdateAgreementById, { id: agreementId })
        ).subscribe({
            next: (agreementData) => {
                console.log("Update agreement received")
                if (Utils.checkNested(agreementData, 'value', 'data', 'onUpdateAgreementById')) {
                    let newAgreement = agreementData.value.data.onUpdateAgreementById;
                    if (!newAgreement) return
                    this.setState({
                        validationDateUser1: newAgreement.validationDateUser1,
                        validationDateUser2: newAgreement.validationDateUser2,
                        updatedAt: newAgreement.updatedAt,
                        content: newAgreement.content,
                        title: newAgreement.title,
                        deletableBy: newAgreement.deletableBy,
                        updatableBy: newAgreement.updatableBy,
                        user1Sub: newAgreement.user1Sub,
                    })
                    this.defineStatus(newAgreement);
                }
            }
        });
    }

    async getAgreement(id) {
        let getAgreement = await agreementHelper.getAgreement(id);
        let currentUserSub = await Utils.getCurrentUserSub();
        if (!getAgreement) return
        this.setState({
            agreementId: getAgreement.id,
            validationDateUser1: getAgreement.validationDateUser1,
            validationDateUser2: getAgreement.validationDateUser2,
            deletableBy: getAgreement.deletableBy,
            updatableBy: getAgreement.updatableBy,
            currentUserSub: currentUserSub,
            user1Sub: getAgreement.user1Sub,
            user2Sub: getAgreement.user2Sub,
            user1Name: getAgreement.nameUser1,
            user2Name: getAgreement.nameUser2,
            user1Mail: getAgreement.user1Mail,
            user2Mail: getAgreement.user2Mail,
            identityIdUser1: getAgreement.identityIdUser1,
            creationDate: getAgreement.creationDate,
            updatedAt: getAgreement.updatedAt,
            content: getAgreement.content,
            title: getAgreement.title,
        })
        this.defineStatus(getAgreement);
        this.getReviews(getAgreement.id);
    }

    async getReviews(agreementId) {
        let listReviews = await reviewHelper.getAgreementReviews(agreementId)
        this.setState({ listReviews: listReviews });
    }

    defineStatus(agreements) {
        if (!agreements) return
        let statusText = null;
        let status = agreementHelper.getAgreementStatus(agreements)
        if (status === STATUS.USER1_EDITING) {
            statusText = "Waiting for " + agreements.nameUser1 + " to finalize the eContract. For now, the details can still be updated by " + agreements.nameUser1 +
                ". \n You can discuss together using the chat before finalizing, after that no modification will be permitted.";
        } else if (status === STATUS.WAIT_CONFIRMATION) {
            statusText = "Waiting for " + agreements.nameUser2 + " to confirm. No modification can be done anymore.";
        } else if (status === STATUS.CONFIRMED) {
            statusText = "The following eContract has been validated by both of you. It is no longer possible to edit it. Please take some time to leave a review.";
        } else {
            statusText = "Error.";
        }

        this.setState({
            status: status,
            statusText: statusText,
        })
    }

    async handleFinalize() {
        this.setState({
            loading: true,
        })
        await agreementHelper.finalize(this.state.agreementId)
        this.setState({
            loading: false,
        })
    }

    async handleAgreed() {
        this.setState({
            loading: true,
        })
        await agreementHelper.agreed(this.state.agreementId)
        this.setState({
            loading: false,
        })
    }

    render() {
        const { agreementId,
            currentUserSub,
            user1Sub,
            user2Sub,
            user1Name,
            user2Name,
            user1Mail,
            user2Mail,
            content,
            title,
            loading,
            creationDate,
            updatedAt,
            status,
            statusText,
            validationDateUser1,
            validationDateUser2,
            listReviews }
            = this.state;
        let formatCreationDate = new Date(creationDate).toDateString();
        let editable = status === STATUS.USER1_EDITING && currentUserSub === user1Sub ? true : false;
        return (
            <Box className="Agreement" whiteSpace="pre-line">
                <Container maxWidth="lg">
                    <Box marginBottom={3} color='white'>
                        <Hidden smDown implementation="css"><Typography variant="h2">eContract</Typography></Hidden>
                    </Box>
                </Container>
                <Container maxWidth="md">
                    {/* ------------ Status section --------------- */}
                    <Box className="container">
                        <Typography variant="h4">Status</Typography>
                        <Box marginBottom={2}><Divider /></Box>
                        <Box marginBottom={2} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                            <Box><Typography variant="subtitle2"><b>Create:</b> {formatCreationDate}</Typography></Box>
                            <Box flexGrow={1}></Box>
                            <Box><Typography variant="subtitle2"><b>Update:</b> {new Date(updatedAt).toDateString()}</Typography></Box>
                        </Box>
                        {validationDateUser1 ?
                            <Box marginBottom={1}><Typography variant="subtitle2"><b>Validate by {user1Name}:</b> {new Date(validationDateUser1).toDateString()}</Typography></Box>
                            : null
                        }
                        {validationDateUser2 ?
                            <Box marginBottom={1}><Typography variant="subtitle2"><b>Validate by {user2Name}:</b> {new Date(validationDateUser2).toDateString()}</Typography></Box>
                            : null
                        }
                        <Typography>{statusText}</Typography>
                        {
                            user1Sub === currentUserSub && status === STATUS.USER1_EDITING ?
                                <Box marginTop={4} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                    <Box flexGrow={1} />
                                    <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleFinalize()} >
                                        Finalize
                                </Button>
                                </Box>
                                :
                                null
                        }

                        {
                            user2Sub === currentUserSub && status === STATUS.WAIT_CONFIRMATION ?
                                <Box marginTop={4} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                    <Box flexGrow={1} />
                                    <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleAgreed()} >
                                        I agree
                                </Button>
                                </Box>
                                :
                                null
                        }

                        {
                            status === STATUS.CONFIRMED ?
                                <Box marginTop={4} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                    <Box flexGrow={1} />
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                        <ReviewButton
                                            disabled={loading}
                                            agreementId={agreementId}
                                        />
                                    </Box>
                                </Box>
                                :
                                null
                        }

                    </Box>

                    {/* ------------ Users section --------------- */}
                    <Box className="container">
                        <Typography variant="h4">Between</Typography>
                        <Box marginBottom={2}><Divider /></Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={2}>
                            <Box flexGrow={1} />
                            <UserThumb userId={user1Sub} clickable={true} />
                            {
                                currentUserSub !== user1Sub ?
                                    <Box marginLeft={1}><ButtonContact disabled={loading} userIdToContact={user1Sub} /></Box> :
                                    null
                            }
                            <Box flexGrow={1} />
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>Username ({formatCreationDate}):</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User unique code:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Sub}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User verified email:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user1Mail}</Typography>
                        </Box>

                        <Box marginBottom={2} marginTop={2}><Divider /></Box>

                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={2}>
                            <Box flexGrow={1} />
                            <UserThumb userId={user2Sub} clickable={true} />
                            {
                                currentUserSub !== user2Sub ?
                                    <Box marginLeft={1}><ButtonContact disabled={loading} userIdToContact={user2Sub} /></Box> :
                                    null
                            }

                            <Box flexGrow={1} />
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>Username ({formatCreationDate}):</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Name}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User unique code:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Sub}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom={1}>
                            <Typography>User verified email:</Typography>
                            <Box flexGrow={1} />
                            <Typography>{user2Mail}</Typography>
                        </Box>
                    </Box>

                    <Box className="container" textAlign="justify" whiteSpace="pre-line">
                        <Box>
                            <AgreementTitleTextField
                                editable={editable && !loading}
                                title={title}
                                agreementId={agreementId}
                            />
                        </Box>
                        <Box marginBottom={3} marginTop={3} />
                        <Box marginBottom={1}><Divider /></Box>
                        <Box>
                            <AgreementContentTextField
                                editable={editable && !loading}
                                content={content}
                                agreementId={agreementId}
                            />
                        </Box>
                    </Box>

                    <Box>
                        {listReviews.length > 0 ?
                            listReviews.map((review, index) => {
                                return <CardReview
                                    key={index}
                                    userFromSub={review.userFromSub}
                                    rating={review.rating}
                                    content={review.content}
                                    date={review.postedAt}
                                />
                            })
                            :
                            null
                        }
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default Agreement;