import React, { Component } from 'react';
import './ListArticles.scss';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import uuid from 'react-uuid';
import { ArticleRoute } from '../../Routing';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ListArticles extends Component {
    render() {
        const { resultItems } = this.props;
        return (
            <Box className="ListArticles" display="flex" flexDirection="row" flexWrap="wrap">
                {resultItems.map(function (article) {
                    return <Card style={{ maxWidth: 400, minWidth: 300, marginRight: '1em', marginBottom: '1em' }} key={uuid()}>
                        <CardActionArea component={Link} to={ArticleRoute + article.slang}>
                            <CardMedia
                                title="Drone article"
                                style={{ height: 140 }}
                                image={article.imgUrl}
                            />
                            <CardContent style={{ color: "black" }}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {article.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {article.description}
                                </Typography>
                                <Box textAlign="right">
                                    <Typography variant="caption" color="textSecondary">
                                        {new Date(article.publishDate).toDateString()}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" component={Link} to={ArticleRoute + article.slang}>
                                Read
                                </Button>
                        </CardActions>
                    </Card>;
                })}
                {
                    resultItems.length === 0 ?
                        <Typography>
                            No result
                        </Typography> :
                        null
                }
            </Box>
        );
    }
}

ListArticles.propTypes = {
    resultItems: PropTypes.array,
};

export default ListArticles;