import React, { Component } from 'react';

import { Link } from "react-router-dom";
import { PostsRoute, CreditsRoute, HelpRoute } from "../../Routing.js";

import bridgeImg from "./img/portfolio/bridge.jpg"
import beachImg from "./img/portfolio/beach.jpg"
import constructionImg from "./img/portfolio/construction.jpg"
import cropImg from "./img/portfolio/crop.jpg"
import hideImg from "./img/portfolio/hide.jpg"
import houseImg from "./img/portfolio/house.jpg"
import insuranceImg from "./img/portfolio/insurance.jpg"
import logisticImg from "./img/portfolio/logistic.jpg"
import mavicImg from "./img/portfolio/mavic.jpg"
import teachImg from "./img/portfolio/teach.jpg"
import thermalImg from "./img/portfolio/thermal.jpg"
import weddingImg from "./img/portfolio/wedding.jpg"
import Box from '@material-ui/core/Box';

import { Typography } from '@material-ui/core';

import "./lib/bootstrap/css/bootstrap.min.scss";
import "./lib/font-awesome/css/font-awesome.min.css";
import "./lib/animate/animate.min.css";
import "./Landing.scss";

import Footer from '../../componants/Footer/Footer.js';

class Landing extends Component {
    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
    }

    render() {
        return (
            <Box id="Landing">

                <div id="landing-page">
                    <section id="hero">
                        <div className="hero-container">
                            <Typography variant="h1" style={{ color: "white" }}>Welcome</Typography>
                            <Typography variant="h2" style={{ color: "white" }}>to the world first network for drone services</Typography>
                            <Typography variant="h3" style={{ color: "white" }}>From learning how to use your new toy <br />to obtain professional thermal 3d mapping, <br />you'll find <b>the right pilot</b> here</Typography>
                            <Link to={PostsRoute} className="btn-get-started">Find a pilot!</Link>
                            <Typography variant="h3" style={{ color: "white" }}>Want to offer your services as a drone pilot<br />and find new clients?</Typography>
                            <Link to={HelpRoute} className="btn-get-started">Become a pilot</Link>
                        </div>
                    </section>

                    <main id="main">

                        {/* 
                        <section id="facts">
                            <div className="container wow fadeIn">

                                <div className="row counters">

                                    <div className="col-lg-4 col-9 text-center">
                                        <span data-toggle="counter-up">3,550,000</span>
                                        <p>Drone owners expected by 2021
                                            <br />It is that many potential pilots for your job!</p>
                                    </div>

                                    <div className="col-lg-4 col-9 text-center">
                                        <span>$</span>
                                        <span data-toggle="counter-up">1,500</span>
                                        <p>Price for a good entry-level drone<br />A lot for a one day use, but not so much to start a business</p>
                                    </div>

                                    <div className="col-lg-4 col-9 text-center">
                                        <span>$</span>
                                        <span data-toggle="counter-up">50</span>
                                        <p>Average price to get wonderful aerial pictures of your property</p>
                                    </div>
                                </div>
                            </div>
                        </section >
                        */}

                        <section id="services">
                            <div className="container wow fadeIn">
                                <div className="section-header">
                                    <h3 className="section-title">How it works</h3>
                                    <h2 className="section-description">You are looking for a drone pilot, a formation or some material?</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="box">
                                            <div className="icon"><i className="fa fa-search"></i></div>
                                            <h4 className="title">1 <i className="fa fa-caret-right"></i> Search</h4>
                                            <p className="description text-center">
                                                Use our search engine to find the perfect pilot for your need.<br />
                                                See previous works and rating so you are certain to contact the right person.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="box">
                                            <div className="icon"><i className="fa fa-comments-o"></i></div>
                                            <h4 className="title">2 <i className="fa fa-caret-right"></i> Chat and agreed</h4>
                                            <p className="description text-center">
                                                Click&Drone provides you a embedded chat so it is easy to get in touch with the selected pilot.<br /><br />
                                                When you ready to work together, create an eContract so all the details of the mission are clear for everyone.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                        <div className="box">
                                            <div className="icon"><i className="fa fa-paper-plane"></i></div>
                                            <h4 className="title">3 <i className="fa fa-caret-right"></i> Fly</h4>
                                            <p className="description text-center">You're done!<br />Time to fly and enjoy the perfect result that only drones can provide.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* 
                        <section id="call-to-action">
                            <div className="container wow fadeIn">
                                <div className="row">
                                    <div className="col-lg-9 text-center text-lg-left">
                                        <h3 className="cta-title">What do we do</h3>
                                        <p className="cta-text">Click&Drone aims to help people to get the best of the drone technology
                                          by providing a platform to help you find the right professional.
                                          The service is not constrained you to a particular application,
                                          as long as it is related to drones you will be able to find someone with the skills you need. You could want to find
                                          someone to let you try the lastest drone on the market before buying it, look for a formation as a hobbyist or as a professional,
                                          want to obtain some pictures of your propery, have a photographer that also do aerial imagery,
                                          do a thermal analysis of your house... The options are endless and only limited by your imagination.</p>
                                    </div>
                                    <div className="col-lg-3 cta-btn-container text-center">
                                        <a className="cta-btn align-middle" href="#its-free">Best of all: it's free!</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        */}

                        <section id="its-free">
                            <div className="container wow fadeIn">
                                <div className="row">
                                    <div className="col-lg-9 text-center text-lg-left">
                                        <h3 className="cta-title">It is free!</h3>
                                        <p className="cta-text">At Click&Drone, we think that connecting people should always be free.<br />
                                            That is why we are not interfering in any ways on your transaction. Offering your services or materials and searching for them is absolutly fees free.<br />
                                            To be able to give you this, we want to offer premium services like insurance, acting as a trusted third party on critical missions
                                             or sell optimized dashboards and tools for company or freelancer.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section id="portfolio">
                            <div className="container wow fadeInUp">
                                <div className="section-header">
                                    <h3 className="section-title">Use cases</h3>
                                </div>
                                <br />

                                <div className="row" id="portfolio-wrapper">
                                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                        <img src={bridgeImg} alt="Bridge" />
                                        <div className="details">
                                            <h4>Surveying</h4>
                                            <span>
                                                Drone can often be a good option to get close to inaccessible
                                                structures you want to survey.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-web">
                                        <img src={constructionImg} alt="constructionImg" />
                                        <div className="details">
                                            <h4>Construction</h4>
                                            <span>
                                                On large construction, a few aerial image can help you get the big picture.
                                                Professionals can also create precise 3d model to get more feedback.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                        <img src={weddingImg} alt="weddingImg" />
                                        <div className="details">
                                            <h4>Event</h4>
                                            <span>
                                                Find a photographer for your event that is also able to produce high quality aerial images.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-card">
                                        <img src={cropImg} alt="farming" />
                                        <div className="details">
                                            <h4>Farming</h4>
                                            <span>
                                                Find a professional to help you analyse your field and gain productivity.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-card">
                                        <img src={insuranceImg} alt="insuranceImg" />
                                        <div className="details">
                                            <h4>Insurance</h4>
                                            <span>Drone can be use to observed dammage on your property and claim for your money</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-web">
                                        <img src={thermalImg} alt="thermalImg" />
                                        <div className="details">
                                            <h4>Thermal analysis</h4>
                                            <span>With particular sensor, drones can help you optimize your house isolation.</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-card">
                                        <img src={beachImg} alt="holidays" />
                                        <div className="details">
                                            <h4>Holidays</h4>
                                            <span>
                                                You want a drone to make awesome videos of your trip but don't want to invest for a one time use?
                                                Find someone near you to rent it!
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                                        <img src={hideImg} alt="hide" />
                                        <div className="details">
                                            <h4>Take some hight</h4>
                                            <span>Some things are hard to see from the ground. Drones can help you find unexpected treasures.</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-logo">
                                        <img src={houseImg} alt="house" />
                                        <div className="details">
                                            <h4>Real estate</h4>
                                            <span>Having some aerial pictures of your house can significantly increase your chances to sell it.</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-logo">
                                        <img src={teachImg} alt="teachImg" />
                                        <div className="details">
                                            <h4>Formation</h4>
                                            <span>Want to develop your piloting skills? From begginer to professional, there is someone near you that can help you.</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-web">
                                        <img src={mavicImg} alt="mavic" />
                                        <div className="details">
                                            <h4>Test</h4>
                                            <span>Want to test the new model before buying? Why not finding someone to show it to you.</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 portfolio-item filter-logo">
                                        <img src={logisticImg} alt="logistic" />
                                        <div className="details">
                                            <h4>Your turn</h4>
                                            <span>The limit is your imagination, now it is your turn to invent where a drone can help you.</span>
                                        </div>
                                    </div>
                                </div>
                                <Link to={CreditsRoute}>Images credits</Link>
                            </div>
                        </section>


                    </main>
                    <Footer />

                    <a href="#hero" className="back-to-top"><i className="fa fa-chevron-up"></i></a>

                </div>
            </Box>
        );
    }
}

export default Landing;