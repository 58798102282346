import React, { Component } from 'react';
import './ButtonContact.scss';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
/*
import { UserRoute } from '../../Routing';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
*/
import MessageIcon from '@material-ui/icons/Message';
import ContactModal from './ContactModal/ContactModal';

class ButtonContact extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    state = {
        dialogOpen: false,
    };

    handleClose() {
        this.setState({ dialogOpen: false });
        if (this.props.onAdd) { this.props.onAdd(); }

    }

    handleOpen() {
        this.setState({ dialogOpen: true });
    }

    contactUser() {
        console.log("contact" + this.props.userIdToContact)
    }
    render() {
        const { disabled, userIdToContact } = this.props;
        let { dialogOpen } = this.state;
        return (
            <Box>
                <Button disabled={disabled || !userIdToContact} variant="contained" size="small" color="secondary" onClick={this.handleOpen} >
                    <MessageIcon />
                </Button>
                <ContactModal
                    open={dialogOpen}
                    recipentsub={userIdToContact}
                    onClose={this.handleClose}
                />
            </Box>
        );
    }
}


ButtonContact.propTypes = {
    userIdToContact: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ButtonContact;
