import React, { Component } from 'react';
import './SelectCountryCity.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import Select from "react-select";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import PublicIcon from '@material-ui/icons/Public';
import LocationOnIcon from '@material-ui/icons/LocationOn';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from '../../customGraphql/queries';
import * as queries from '../../graphql/queries';
import { Box, Typography, Button, Chip, Avatar } from '@material-ui/core';
import { countries } from '../../utils/CountriesList';

const suggestionsCountries = countries;

class SelectCountryCity extends Component {
    state = {
        selectedCountryOption: null,
        selectedCity: null,
        textCountry: null,
        textCity: null,
        editing: false,
        setCity: true,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "cityId")) {
            this.searchCityId(this.props.cityId)
        } else if (Utils.checkNested(this, "props", "country")) {
            this.searchCountry(this.props.country)
        }
    }

    componentDidUpdate(prevProps) {
        let cityIdFromProps = Utils.checkNested(this, "props", "cityId");
        let cityIdFromPrevProps = Utils.checkNested(prevProps, 'cityId');

        let countryFromProps = Utils.checkNested(this, "props", "country");
        let countryFromPrevProps = Utils.checkNested(prevProps, 'country');

        if (cityIdFromProps !== cityIdFromPrevProps) {
            if (!cityIdFromProps && countryFromProps) {
                this.searchCountry(this.props.country);
            }
            this.searchCityId(this.props.cityId);
        } else if (countryFromProps !== countryFromPrevProps) {
            this.searchCountry(this.props.country);
        }

        let forceValidateFromProps = Utils.checkNested(this, "props", "forceValidate");
        let forceValidateFromPrevProps = Utils.checkNested(prevProps, 'forceValidate');
        if (forceValidateFromProps !== forceValidateFromPrevProps) {
            this.handleValidate();
        }
    }

    searchCountry(country) {
        let countryObj = countries.find(o => o.value === country);
        // Setting the country reset city
        this.setState({
            "selectedCountryOption": countryObj,
            "textCountry": country.charAt(0).toUpperCase() + country.slice(1).toLowerCase(),
            "selectedCity": null,
            "textCity": null
        });
    }

    async searchCityId(id) {
        if (id) {
            try {
                let getCity = await API.graphql(graphqlOperation(queries.getCity, { id: id }));
                let cityResult = null
                if (Utils.checkNested(getCity, "data", "getCity")) {
                    cityResult = getCity.data.getCity;
                    if (cityResult.country && cityResult.name && cityResult.id) {
                        this.searchCountry(cityResult.country)
                        let cityItem = { label: cityResult.name, value: cityResult.name, id: cityResult.id };

                        this.setState({
                            "selectedCity": cityItem,
                            "textCity": cityResult.name.charAt(0).toUpperCase() + cityResult.name.slice(1).toLowerCase()
                        });
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    handleCountryChange = selectedCountryOption => {
        // Set the country and reset the city search
        this.setState({
            "selectedCountryOption": selectedCountryOption,
            "selectedCityOption": null,
            "suggestionsCities": [],
            //"setCity": false,
        });
        // If always open, treat select as validate
        if (this.props.alwaysOpen) { this.handleSelect({ country: Utils.checkNested(selectedCountryOption, "value"), city: null }); }
    };

    handleCityChange = selectedCityOption => {
        this.setState({ "selectedCityOption": selectedCityOption });
        // If always open, treat select as validate
        if (this.props.alwaysOpen) { this.handleSelect({ country: Utils.checkNested(this.state.selectedCountryOption, "value"), city: selectedCityOption });}
    };

    startEditing() {
        this.setState({
            "editing": true,
        });
    }

    finishEditing() {
        this.setState({
            "editing": false,
        });
    }

    handleSelect(locationToPush) {
        if (this.props.onChange) {
            this.props.onChange(locationToPush);
        }
    }

    async handleValidate() {
        if (this.props.onChange) {
            this.props.onChange({ country: Utils.checkNested(this.state, "selectedCountryOption", "value"), city: this.state.selectedCityOption });
        }
        this.finishEditing();
    }

    handleCancel() {
        this.finishEditing();
    }

    setCity() {
        this.setState({
            "setCity": true,
        });
    }

    handleCityInputChange = (newValue) => {
        if (newValue.length > 2) {
            this.loadCitiesFromDb(newValue);
        }
        return newValue;
    };

    async loadCitiesFromDb(citySearchValue) {
        if (!this.state.selectedCountryOption.value) return
        let countryName = this.state.selectedCountryOption.value;
        let citySearch = citySearchValue.toLocaleUpperCase();
        let citiesResults = [];

        let getCities = await API.graphql(graphqlOperation(customQueries.cityShortByCountryAndName, { country: countryName, name: { beginsWith: citySearch } }));


        if (Utils.checkNested(getCities, "data", "cityByCountry", "items")) {
            citiesResults = Utils.checkNested(getCities, "data", "cityByCountry", "items");
        }
        let citiesResultsForm = [];
        citiesResults.forEach(city => {
            if (Utils.checkNested(city, "id") &&
                Utils.checkNested(city, "name") &&
                Utils.checkNested(city, "country")) {
                let item = {
                    id: city.id,
                    value: city.name,
                    label: city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase(),
                    country: city.country
                };
                citiesResultsForm.push(item);
            }
        });
        this.setState({
            "suggestionsCities": citiesResultsForm
        });
    }

    render() {
        let { selectedCountryOption, editing, setCity, selectedCityOption, suggestionsCities, textCountry, textCity } = this.state;
        let { editable, alwaysOpen, loading } = this.props;
        return (
            <Box className="SelectCountryCity" display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                {
                    editing || alwaysOpen ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em">
                                <Select isDisabled={loading} options={suggestionsCountries} value={selectedCountryOption} onChange={this.handleCountryChange} />
                            </Box>
                            <Box marginRight="1.5em" marginBottom="0.5em">
                                {
                                    setCity ?
                                        <Box minWidth="200px">
                                            <Select isDisabled={loading} options={suggestionsCities} value={selectedCityOption} onInputChange={this.handleCityInputChange} onChange={this.handleCityChange} />
                                        </Box>
                                        :
                                        <Button disabled={loading || selectedCountryOption == null} variant="contained" size="small" color="secondary" onClick={() => this.setCity()} >
                                            <AddIcon /> Set city
                                        </Button>
                                }
                            </Box>
                        </Box>
                        :
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" marginRight="1.5em" marginBottom="0.5em">
                            {
                                textCity ?
                                    <Box marginRight="0.5em" >
                                        <Chip avatar={<Avatar><LocationOnIcon /></Avatar>} label={textCity} />
                                    </Box>
                                    :
                                    null
                            }
                            {
                                textCountry ?
                                    <Box>
                                        <Chip avatar={<Avatar><PublicIcon /></Avatar>} label={textCountry} />
                                    </Box>
                                    :
                                    <Box>
                                        <Typography>No location</Typography>
                                    </Box>
                            }
                        </Box>
                }
                {
                    editable && !alwaysOpen ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                            {
                                editing ?
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom="0.5em">
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ backgroundColor: "#CC0000", marginRight: "10px" }} onClick={() => this.handleCancel()} >
                                            <ClearIcon />
                                        </Button>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleValidate()} >
                                            <DoneIcon />
                                        </Button>
                                    </Box>
                                    :
                                    <Box marginBottom="0.5em">
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.startEditing()} >
                                            <EditIcon />
                                        </Button>
                                    </Box>
                            }
                        </Box> : null
                }

            </Box>
        );
    }
}

SelectCountryCity.propTypes = {
    onChange: PropTypes.func,
    country: PropTypes.string,
    cityId: PropTypes.string,
    editable: PropTypes.bool,
    loading: PropTypes.bool,
    forceValidate: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
};

export default SelectCountryCity;