import React, { Component } from 'react';
import './Help.scss';
import { Typography, Container } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Footer from '../../componants/Footer/Footer';
import profilScreen from "./../../img/help/profil.png";

class Help extends Component {
    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
    }

    render() {
        return (
            <Box id="Help">
                <Container maxWidth="lg">
                    <Typography style={{ marginBottom: "1em", color: 'white' }} variant="h2">How to start with ClicknDrone</Typography>
                </Container>
                <Box className="container-transparent" >
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em', color: 'white' }} variant="h4">
                            Welcome to ClicknDrone
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            This page aims to give you an overview of this platform so you can take the best of it.
                            Whether you already have a drone business and want to gain visibility or
                            you want to begin you journey into the drone industry, you will find here precious
                            information on how to use ClicknDrone.
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            Before to start, it is important to understand what we do. Nowadays, the drone technology
                            reaches a point where it can provide fast, cheap and high quality result for both
                            professional or personal applications. But even knowing that, it could be very
                            frustrating to look for the right pilot due to the current lack of centralization.
                            How to compare different service providers? How to have access to previous real reviews?
                            How to contact them? ClicknDrone is a platform intending to fill this gap by regrouping
                            a large number of professionals in the same place with a user-friendly/drone oriented
                            search tool. It also provides you with previous reviews, easy to use encrypted messaging tool 
                            and a e-contract feature so both client and service provider
                            agreed on the mission.
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            However, ClicknDrone does not interfere in the mission it self. That means we don't take
                            any commission on your work, but also that we are not responsible for it. On this page,
                            we will only talk about the platform but not about the legal aspect of drone piloting as
                            it is a vast and country dependent topic.
                         </Typography>
                    </Container>
                </Box>
                <Box className="container-white" >
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em' }} variant="h4">
                            Create an account
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            The first thing to do to start using ClicknDrone is to create an account. Without one,
                            you will still be able to search for pilots, but not contacting them.
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            To create an account click on <b>Log in</b> > <b>Create account</b> and go through
                            the registration process. You'll only need a valid email address and the registration
                            process should take around 1 min to complete.
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            After your registration is completed, you should land on your profile page. It is a good
                            idea to spend few minutes to provide <b>general information</b> about you or your organization,
                            a <b>name</b>, a <b>city</b>, a <b>profile picture</b> and optionally some pictures of your previous
                                work (max 5). Once you are done, you should see something looking like that:
                        </Typography>
                        <Box>
                            <img alt='' display='block' width='90%' src={profilScreen} />
                        </Box>
                    </Container>
                </Box>
                <Box className="container-transparent" >
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em', color: 'white' }} variant="h4">
                            Add a service
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            In ClicknDrone, we are not listing pilots but services. It means that in order
                            to let people find you, you now have to enumerate what kind of services you can
                            offer in the list provided.
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            To access you services, <b>My profile</b> > tab <b>Services</b>. This will
                            display the list of the services that can be found by potential clients though the
                            search tool.
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            To add a new service, simply click on <b>Publish a new service</b> on the <b>Services</b> tab.
                            Then complete the information needed and validate. Congrats, your new service should now be
                            displayed on your services list and be publicly searchable!
                         </Typography>
                    </Container>
                </Box>
                <Box className="container-white" >
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em' }} variant="h4">
                            Using the messaging tool
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            ClicknDrone provides an easy and embedded way for service provider and client to
                            communicate. To send a message to another user, go on one of his/her service or directly
                            on his/her profile. Here find the small message icon next to the username and click on it.
                            this will open a popup where you can enter you message and send it. Once sent
                            the conversation page will automatically be loaded.
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            To access your conversation, simply click on <b>Messages</b> on the right menu. This
                            will displayed the list of all your conversations. It will appear red if you have unread
                            messages.
                        </Typography>
                    </Container>
                </Box>
                <Box className="container-transparent" >
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em', color: 'white' }} variant="h4">
                            Using eContract
                        </Typography>
                        <Typography style={{ marginBottom: '1em', color: 'white' }} variant="body1">
                            Once you are ready to work with your new client you can create a <b>eContract</b>. As
                            everything in ClicknDrone, it is not mandatory to use this feature, but it give
                            certain advantages. First, it is not possible to update once it is validated by both
                            the client and the pilot which can be useful if you disagreed on the result or the price
                            after the work is done. And second, it is the way ClicknDrone uses to know that two users
                            worked together and allow them to leave a review, which will help you to find new clients.
                        </Typography>
                    </Container>
                </Box>
                <Box className="container-white">
                    <Container maxWidth="md">
                        <Typography style={{ marginTop: '1em', marginBottom: '1em' }} variant="h4">
                            Some drones
                        </Typography>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            Finally, if you are new in the drone business, you may wonder what kind of drone you
                            should use. Well that depends a lot on the type of mission and quality you are looking for
                            but here is an overview of the most common drones on the market.
                        </Typography>
                        <Box display='flex' alignItems='center'>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.fr/gp/product/B075FRZ3DW/ref=as_li_tl?ie=UTF8&camp=1642&creative=6746&creativeASIN=B075FRZ3DW&linkCode=as2&tag=dzolo-21&linkId=8d96819d7f22ac99d7d71a9a38f38b9f"><img alt='' border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=FR&ASIN=B075FRZ3DW&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=dzolo-21" /></a><img src="//ir-fr.amazon-adsystem.com/e/ir?t=dzolo-21&l=am2&o=8&a=B075FRZ3DW" width="1" height="1" border="0" alt="" style={{ border: 'none !important', margin: '0px !important' }} />
                            <Typography style={{ marginBottom: '1em' }} variant="body1">
                                If your purpose is to do color <b>pictures</b> for some <b>events</b> or <b>real estate</b>,
                                then a <b>Mavic Pro</b> (<a target="_blank" rel="noopener noreferrer" href="https://www.amazon.fr/gp/product/B07994RXLY/ref=as_li_tl?ie=UTF8&camp=1642&creative=6746&creativeASIN=B07994RXLY&linkCode=as2&tag=dzolo-21&linkId=24ca89222463eeb29179ad8cde5c126f">here on amazon</a>) would probably be a good starting choice. It is a
                                lightweight and relatively cheap 4k quadcopter made by the number 1 drone constructor DJI.
                                In most countries, this drone will fall into the lightest drone category which can
                                significantly facilitate the administrative load of an operation.
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Typography style={{ marginBottom: '1em' }} variant="body1">
                                If you want to target more professional clients that want you to shoot video with the
                                highest quality standard, then a <b>DJI inspire</b> (<a target="_blank" rel="noopener noreferrer" href="https://www.amazon.fr/gp/product/B01MSRDI3B/ref=as_li_tl?ie=UTF8&camp=1642&creative=6746&creativeASIN=B01MSRDI3B&linkCode=as2&tag=dzolo-21&linkId=ea16f728b207c9545fc8ad507257a03d">here</a>) could be a nice bet. This drone
                                allow you to use various camera, fly longer, faster and have a better stabilization.
                            </Typography>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.amazon.fr/gp/product/B01MSRDI3B/ref=as_li_tl?ie=UTF8&camp=1642&creative=6746&creativeASIN=B01MSRDI3B&linkCode=as2&tag=dzolo-21&linkId=b09fb4ec00e8c5467659006c13104cdb"><img alt='' border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=FR&ASIN=B01MSRDI3B&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=dzolo-21" /></a><img src="//ir-fr.amazon-adsystem.com/e/ir?t=dzolo-21&l=am2&o=8&a=B01MSRDI3B" width="1" height="1" border="0" alt="" style={{ border: 'none !important', margin: '0px !important' }} />
                        </Box>
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            Finally, even if drone market is mainly filled of quadcopters, fixed wings UAVs also have a lot
                            of advantages and should not be underestimated. For long missions where you need to cover
                            an important areas, they even can be the only option. Drones like the <b>eBee</b> (senseFly)
                            can stay in the air for almost 1 hour! But this kind of drone are only made for professional
                            and you should directly contact a reseller to get a quote.
                        </Typography>
                        <br />
                        <Typography style={{ marginBottom: '1em' }} variant="body1">
                            Hope you found some useful information on this page, <b>fly safely!</b>
                        </Typography>
                    </Container>
                </Box>
                <Footer />
            </Box>
        );
    }
}

export default Help;