// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import Utils from "./Utils";
import uuid from "react-uuid";

export const STATUS = {
    USER1_EDITING: "USER1_EDITING",
    WAIT_CONFIRMATION: "WAIT_CONFIRMATION",
    CONFIRMED: "CONFIRMED",
    UNDEFINED: "UNDEFINED",
}

class agreementHelper {
    static createAgreement(user1Sub, user2Sub, user1Name, user2Name, user1Mail, user2Mail, identityIdUser1, content, title) {
        return new Promise((resolve, reject) => {
            let date = new Date().toISOString();
            let date0 = new Date(0).toISOString();
            console.log(date0)
            let input = {
                id: uuid(),
                updatedAt: date,
                user1Sub: user1Sub,
                user2Sub: user2Sub,
                nameUser1: user1Name,
                nameUser2: user2Name,
                user1Mail: user1Mail,
                user2Mail: user2Mail,
                creationDate: date,
                content: content,
                title: title,
                deletableBy: [user1Sub, user2Sub],
                updatableByUser1: user1Sub,
                updatableByUser2: user2Sub,
            }
            API.graphql(graphqlOperation(mutations.createAgreement, { input: input }))
                .then(result => {
                    let agreement = Utils.checkNested(result, "data", "createAgreement");
                    resolve(agreement)

                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static getMyAgreements(nextToken1 = null, nextToken2 = null) {
        return new Promise(async (resolve, reject) => {
            let userSub = await Utils.getCurrentUserSub();
            let listUser1 = [];
            let listUser2 = [];
            try {
                let agreementsByUser1 = await API.graphql(graphqlOperation(queries.agreementsByUser1, { user1Sub: userSub, limit: "100", sortDirection: "DESC", nextToken: nextToken1 }))
                if (Utils.checkNested(agreementsByUser1, 'data', 'agreementsByUser1', 'items', 'length')) {
                    listUser1 = agreementsByUser1.data.agreementsByUser1.items;
                }
            } catch (e) {
                console.log(e)
            }
            try {
                let agreementsByUser2 = await API.graphql(graphqlOperation(queries.agreementsByUser2, { user2Sub: userSub, limit: "100", sortDirection: "DESC", nextToken: nextToken2 }))
                console.log(agreementsByUser2)
                if (Utils.checkNested(agreementsByUser2, 'data', 'agreementsByUser2', 'items', 'length')) {
                    listUser2 = agreementsByUser2.data.agreementsByUser2.items;
                }
            } catch (e) {
                console.log(e)
            }
            let completeList = listUser1.concat(listUser2)
            completeList.sort(this.compareByDate);
            resolve(completeList)
        })
    }

    static compareByDate(a, b) {
        // Use toUpperCase() to ignore character casing
        const dateA = a.updatedAt;
        const dateB = b.updatedAt;

        let comparison = 0;
        if (dateA < dateB) {
            comparison = 1;
        } else if (dateA > dateB) {
            comparison = -1;
        }
        return comparison;
    }

    static deleteAgreement(agreementId) {
        return new Promise(async (resolve, reject) => {
            try {
                let deleteAgreement = await API.graphql(graphqlOperation(mutations.deleteAgreement, { input: { id: agreementId } }))
                if (Utils.checkNested(deleteAgreement, 'data', 'deleteAgreement', 'id')) {
                    resolve(null)
                } else {
                    resolve(["Fail to delete"])
                }
            } catch (e) {
                console.log(e)
                resolve(["Fail to delete"])
            }
        })
    }

    static getAgreement(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let getAgreement = await API.graphql(graphqlOperation(queries.getAgreement, { id: id }))
                if (Utils.checkNested(getAgreement, 'data', 'getAgreement', 'id')) {
                    resolve(getAgreement.data.getAgreement)
                } else {
                    resolve(null)
                }
            } catch (e) {
                console.log(e)
                resolve(null)
            }
        })
    }

    static finalize(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let date = new Date().toISOString();
                let input = {
                    id: id,
                    updatableByUser1: null,
                    updatedAt: date,
                    validationDateUser1: date,
                }
                let updateAgreement = await API.graphql(graphqlOperation(mutations.updateAgreement, { input: input }))
                if (Utils.checkNested(updateAgreement, 'data', 'updateAgreement', 'id')) {
                    resolve(updateAgreement.data.updateAgreement)
                } else {
                    resolve(null)
                }
            } catch (e) {
                console.log(e)
                resolve(null)
            }
        })
    }

    static agreed(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let date = new Date().toISOString();
                let input = {
                    id: id,
                    updatableByUser2: null,
                    validationDateUser2: date,
                    deletableBy: null,
                }
                let updateAgreement = await API.graphql(graphqlOperation(mutations.updateAgreement, { input: input }))
                if (Utils.checkNested(updateAgreement, 'data', 'updateAgreement', 'id')) {
                    resolve(updateAgreement.data.updateAgreement)
                } else {
                    resolve(null)
                }
            } catch (e) {
                console.log(e)
                resolve(null)
            }
        })
    }

    static getAgreementStatus(agreement) {
        if (!agreement) return STATUS.UNDEFINED
        if (!agreement.validationDateUser1 && !agreement.validationDateUser2 && agreement.updatableByUser1) return STATUS.USER1_EDITING
        if (agreement.validationDateUser1 && !agreement.validationDateUser2 && agreement.updatableByUser2) return STATUS.WAIT_CONFIRMATION
        if (agreement.validationDateUser1 && agreement.validationDateUser2 && !agreement.deletableBy) return STATUS.CONFIRMED
        return STATUS.UNDEFINED
    }
}

export default agreementHelper;