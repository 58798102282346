/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByRecipent = `subscription OnCreateMessageByRecipent($recipentSub: ID) {
  onCreateMessageByRecipent(recipentSub: $recipentSub) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const onCreateMessageByConvers = `subscription OnCreateMessageByConvers($conversId: ID) {
  onCreateMessageByConvers(conversId: $conversId) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const onUpdateMessage = `subscription OnUpdateMessage($recipentSub: ID) {
  onUpdateMessage(recipentSub: $recipentSub) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const onDeleteMessage = `subscription OnDeleteMessage($recipentSub: ID) {
  onDeleteMessage(recipentSub: $recipentSub) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const onCreateConversation = `subscription OnCreateConversation($userSub: ID) {
  onCreateConversation(userSub: $userSub) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const onUpdateConversation = `subscription OnUpdateConversation($userSub: ID) {
  onUpdateConversation(userSub: $userSub) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const onDeleteConversation = `subscription OnDeleteConversation($userSub: ID) {
  onDeleteConversation(userSub: $userSub) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const onCreateAgreementById = `subscription OnCreateAgreementById($id: ID) {
  onCreateAgreementById(id: $id) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onCreateAgreementByUser1 = `subscription OnCreateAgreementByUser1($user1Sub: ID) {
  onCreateAgreementByUser1(user1Sub: $user1Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onCreateAgreementByUser2 = `subscription OnCreateAgreementByUser2($user2Sub: ID) {
  onCreateAgreementByUser2(user2Sub: $user2Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onUpdateAgreementById = `subscription OnUpdateAgreementById($id: ID) {
  onUpdateAgreementById(id: $id) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onUpdateAgreementByUser1 = `subscription OnUpdateAgreementByUser1($user1Sub: ID) {
  onUpdateAgreementByUser1(user1Sub: $user1Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onUpdateAgreementByUser2 = `subscription OnUpdateAgreementByUser2($user2Sub: ID) {
  onUpdateAgreementByUser2(user2Sub: $user2Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onDeleteAgreementByUser2 = `subscription OnDeleteAgreementByUser2($user2Sub: ID) {
  onDeleteAgreementByUser2(user2Sub: $user2Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onDeleteAgreementByUser1 = `subscription OnDeleteAgreementByUser1($user1Sub: ID) {
  onDeleteAgreementByUser1(user1Sub: $user1Sub) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const onCreateService = `subscription OnCreateService {
  onCreateService {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const onUpdateService = `subscription OnUpdateService {
  onUpdateService {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const onDeleteService = `subscription OnDeleteService {
  onDeleteService {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const onCreateCity = `subscription OnCreateCity {
  onCreateCity {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const onUpdateCity = `subscription OnUpdateCity {
  onUpdateCity {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const onDeleteCity = `subscription OnDeleteCity {
  onDeleteCity {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const onCreateArticle = `subscription OnCreateArticle {
  onCreateArticle {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const onUpdateArticle = `subscription OnUpdateArticle($owner: String) {
  onUpdateArticle(owner: $owner) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const onDeleteArticle = `subscription OnDeleteArticle($owner: String) {
  onDeleteArticle(owner: $owner) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const onCreateArticleComment = `subscription OnCreateArticleComment($owner: String!) {
  onCreateArticleComment(owner: $owner) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
export const onUpdateArticleComment = `subscription OnUpdateArticleComment($owner: String!) {
  onUpdateArticleComment(owner: $owner) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
export const onDeleteArticleComment = `subscription OnDeleteArticleComment($owner: String) {
  onDeleteArticleComment(owner: $owner) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
