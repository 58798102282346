import React, { Component } from 'react';
import './NewArticle.scss';
import Box from '@material-ui/core/Box';
import { Typography, Divider, TextField, Button, Container, Chip } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import LabelIcon from '@material-ui/icons/Label';
import Select from "react-select";
import { blogCategoryList, languageList } from '../../utils/Constants';
import ReactMarkdown from 'react-markdown';
import UserThumb from '../../componants/UserThumb/UserThumb';
import Utils from '../../utils/Utils';
import Footer from '../../componants/Footer/Footer';
import uuid from 'react-uuid';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import { ArticleRoute } from '../../Routing';

//
import pinyin from 'tiny-pinyin';


class NewArticle extends Component {
    state = {
        categoryOptions: null,
        languageOptions: null,

        languageOptionsSelected: "",
        categoryOptionsSelected: "",

        content: "",
        description: "",
        title: "",
        loading: false,
        editMode: true,
        userSub: null,
        errors: [],
    }

    componentDidMount() {
        this.getUserSub()
    }

    async getUserSub() {
        let userSub = await Utils.getCurrentUserSub();
        let catOption = [...blogCategoryList];
        catOption.shift();
        let langOption = [...languageList];
        langOption.shift();
        this.setState({
            userSub: userSub,
            categoryOptions: catOption,
            languageOptions: langOption,
        })
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleCategoryChange = selectedCategoryOption => {
        if (selectedCategoryOption !== this.state.categoryOptionsSelected) {
            this.setState({
                categoryOptionsSelected: selectedCategoryOption,
            });
        }
    };

    handleLanguageChange = selectedLanguageOption => {
        if (selectedLanguageOption !== this.state.languageOptionsSelected) {
            this.setState({
                languageOptionsSelected: selectedLanguageOption,
            });
        }
    };

    async publish() {
        this.setState({
            loading: true,
        });
        // Check data
        let errors = [];
        const { title, description, content, categoryOptionsSelected, languageOptionsSelected, userSub, url } = this.state;
        const status = "onReview";
        const currentDate = new Date().toISOString();
        if (!title || title === " ") errors.push('Incorrect title');
        if (!description || description === " ") errors.push('Incorrect description');
        if (!content || content === " ") errors.push('Incorrect content');
        if (!categoryOptionsSelected.value || categoryOptionsSelected.value === "ALL") errors.push('Select category');
        if (!languageOptionsSelected.value || languageOptionsSelected.value === "ALL") errors.push('Select language');
        if (!url || url === " ") errors.push('Incorrect url');
        // Generate slang
        let slang = pinyin.convertToPinyin(this.state.title, null, true).toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        let rand = Math.floor(Math.random() * 1000);
        slang = rand + '-' + slang;
        const article_id = uuid();

        if (errors.length === 0) {
            // Push the new article
            try {
                let input = {
                    slang: slang,
                    articleId: article_id,
                    title: title,
                    description: description,
                    content: content,
                    imgUrl: url,

                    publishDate: currentDate,
                    updateDate: currentDate,

                    status: status,
                    category: categoryOptionsSelected.value,
                    language: languageOptionsSelected.value,

                    owner: userSub,
                }

                let createArticle = await API.graphql(graphqlOperation(mutations.createArticle, { input: input }));
                console.log(createArticle)
                window.location.replace(ArticleRoute + slang);
            } catch (e) {
                console.log("Fail to create article")
                console.log(e)
            }
        } else {
            this.setState({ errors: errors, });
        }

        this.setState({
            loading: false,
        });
    }

    toogleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    render() {
        const {
            description,
            url,
            content,
            loading,
            title,
            categoryOptions,
            categoryOptionsSelected,
            languageOptions,
            languageOptionsSelected,
            editMode,
            userSub,
            errors
        } = this.state;
        let date = new Date().toDateString()
        return (

            <Box className="NewArticle">
                {
                    editMode ?
                        <Box>
                            <Container maxWidth="lg">
                                <Box marginBottom={3}>
                                    <Typography style={{ marginBottom: "1em", marginTop: "2em", color: 'white' }} component="h2" variant="h2">
                                        Write a new article
                                    </Typography>
                                </Box>
                            </Container>
                            <Box className="container">
                                <Container maxWidth="md">
                                    <Box marginBottom={5}>
                                        <Typography>{date}</Typography>
                                    </Box>

                                    <Box marginBottom={5} display='flex' flexDirection="row" flexWrap="wrap" alignItems='center'>
                                        <Box display='flex' alignItems='center' marginRight={2}>
                                            <Box marginRight={2}><LanguageIcon /></Box>
                                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                                <Select isDisabled={loading} options={languageOptions} value={languageOptionsSelected} onChange={this.handleLanguageChange} />
                                            </Box>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <Box marginRight={2}><LabelIcon /></Box>
                                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                                <Select isDisabled={loading} options={categoryOptions} value={categoryOptionsSelected} onChange={this.handleCategoryChange} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Main image url</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="img_url-new-article"
                                                label="Main image url"
                                                value={url}
                                                onChange={this.handleChange("url")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Title</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="title-new-article"
                                                label="Title (100 characters max)"
                                                value={title}
                                                onChange={this.handleChange("title")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Short description</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="description-new-agreement"
                                                label="Description (500 characters max)"
                                                value={description}
                                                onChange={this.handleChange("description")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Content</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="content-new-agreement"
                                                label="Content (10000 characters max)"
                                                value={content}
                                                onChange={this.handleChange("content")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 10000 }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box marginTop={4}>
                                        <Button disabled={loading} variant="contained" color="primary" onClick={() => this.toogleEditMode()} >
                                            Show result
                                        </Button>
                                    </Box>
                                </Container>
                            </Box>
                        </Box>
                        :
                        <Box>
                            <Container className="background-image" style={{ backgroundImage: 'url("' + url + '")', color: 'white' }} maxWidth="lg">
                                <Typography style={{ paddingBottom: "1em", paddingTop: "2em" }} component="h2" variant="h2">
                                    {title}
                                </Typography>
                                <Box display='flex'
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    alignItems='center'>
                                    <UserThumb
                                        userId={userSub}
                                        clickable={false}
                                        noStars={false}
                                    />
                                    <Box flexGrow={1} />
                                    <Typography>{date}</Typography>
                                </Box>
                            </Container>
                            <Box className="container">
                                <Container maxWidth="md">
                                    <Chip label={categoryOptionsSelected.label} />
                                    <Chip label={languageOptionsSelected.label} />

                                    <Typography style={{ paddingBottom: "1em", paddingTop: "2em" }} component="h6" variant="h6">
                                        {description}
                                    </Typography>
                                    <Box style={{ paddingBottom: "1em", paddingTop: "2em", fontSize: "1.2em" }}>
                                        <ReactMarkdown source={content} />
                                    </Box>
                                    <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems='center' marginTop={4}>
                                        <Button disabled={loading} variant="contained" color="primary" onClick={() => this.toogleEditMode()} >
                                            Edit
                                        </Button>
                                        <Box flexGrow={1} />
                                        <Button disabled={loading} variant="contained" color="secondary" onClick={() => this.publish()} >
                                            Publish
                                        </Button>
                                    </Box>
                                    <Box style={{ color: 'red' }}>
                                        <ul>
                                            {errors.map(function (item) {
                                                return <li key={uuid()}>{item}</li>;
                                            })}
                                        </ul>
                                    </Box>
                                </Container>
                            </Box>
                        </Box>
                }
                <Footer />
            </Box >
        );
    }
}

export default NewArticle;