// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import Utils from "./Utils";
import uuid from "react-uuid";
import SimpleCrypto from "simple-crypto-js";

class Messaging {
    // A conversObjList is the list of the entries in the db corresponding the conversation (one for each user)
    static async sendMessage(userSub, recipentSub, message, conversObjList = null) {
        let localConversId = null;
        let localCryptKey = null;

        let localConversObjList = conversObjList;
        let messageCreated = null;
        // Check the message
        if (!message || message === "") {
            console.log("Message cannot be empty")
            return null
        }

        // First if the conversId or the cryptKey is not known, find it or create the conversation to have them
        if (localConversObjList === null) {
            let getConversObj = await this.getConversObj(userSub, recipentSub);
            if (!Utils.checkNested(getConversObj, "conversId")) {
                // The conversation does not exist yet, we should create it
                localConversObjList = await this.createConvers(userSub, recipentSub);
                if (!localConversObjList) {
                    console.log("Fail to create conversation")
                    return null
                }
            } else {
                // The conversation exists, so get the conversObjList 
                localConversObjList = await this.getConversObjList(getConversObj.conversId)
            }
        }
        // At this point we should have a localConversObjList, if not we should exit
        if (localConversObjList && localConversObjList.length > 0 &&
            Utils.checkNested(localConversObjList[0], "cryptKey") &&
            Utils.checkNested(localConversObjList[0], "conversId")) {
            localConversId = localConversObjList[0].conversId;
            localCryptKey = localConversObjList[0].cryptKey;
        } else {
            return null
        }

        // At this point a conversId exists so it is possible to send the message
        try {
            let date = new Date().toISOString();
            let simpleCrypto = new SimpleCrypto(localCryptKey);
            let encryptMessage = simpleCrypto.encrypt(message);
            let input = {
                id: uuid(),
                conversId: localConversId,
                updatedAt: date,
                userSub: userSub,
                recipentSub: recipentSub,
                message: encryptMessage,
            }
            let createMessage = await API.graphql(graphqlOperation(mutations.createMessage, { input: input }))

            if (Utils.checkNested(createMessage, "data", "createMessage", "id")) {
                messageCreated = createMessage.data.createMessage;
                // Message have been created, update conversation to unread
                this.setConversationUnread(localConversId, recipentSub);
            }
        } catch (e) {
            console.log("Fail to create the massage")
            console.log(e)
        }
        return [localConversObjList, messageCreated]

    }

    static setConversationUnread(conversId, user2Sub) {
        API.graphql(graphqlOperation(queries.conversationsByConversId, { conversId: conversId, filter: { userSub: { eq: user2Sub } } }))
            .then(result => {
                let items = Utils.checkNested(result, "data", "conversationsByConversId", "items");
                let conversUniqId = items[0].id;
                //let date = new Date().toISOString();
                // Update the convers
                let input = {
                    id: conversUniqId,
                    lastMessageRead: false,
                    //updatedAt: date,
                }
                API.graphql(graphqlOperation(mutations.updateConversation, { input: input }))
                    .then(result => {
                    })
                    .catch(err => {
                        console.log(err)
                        return
                    });
            })
            .catch(err => {
                console.log(err)
                return
            });
    }

    static setConversationRead(conversId, userSub) {
        API.graphql(graphqlOperation(queries.conversationsByConversId, { conversId: conversId, filter: { userSub: { eq: userSub } } }))
            .then(result => {
                let items = Utils.checkNested(result, "data", "conversationsByConversId", "items");
                let conversUniqId = items[0].id;
                // Update the convers
                let input = {
                    id: conversUniqId,
                    lastMessageRead: true,
                }
                API.graphql(graphqlOperation(mutations.updateConversation, { input: input }))
                    .then(result => {
                    })
                    .catch(err => {
                        console.log(err)
                        return
                    });
            })
            .catch(err => {
                console.log(err)
                return
            });
    }

    static createConvers(userSub, user2Sub) {
        return new Promise((resolve, reject) => {
            let cryptKey = uuid();
            let conversId = uuid();
            let date = new Date().toISOString();
            let conversObjList = [];
            let inputUser1 = {
                id: uuid(),
                userSub: userSub,
                updatedAt: date,
                lastMessageRead: false,
                user2Sub: user2Sub,
                conversId: conversId,
                cryptKey: cryptKey,
            };
            let inputUser2 = {
                id: uuid(),
                userSub: user2Sub,
                updatedAt: date,
                lastMessageRead: false,
                user2Sub: userSub,
                conversId: conversId,
                cryptKey: cryptKey,
            }
            // Put the two elements in the db
            // First
            try {
                API.graphql(graphqlOperation(mutations.createConversation, { input: inputUser1 }))
                    .then(result => {
                        console.log("The first item of the convers was created")
                        console.log(result)
                        if (Utils.checkNested(result, "data", "createConversation", "id")) {
                            // First item push sucessfully, do the second one
                            conversObjList.push(result.data.createConversation);
                            API.graphql(graphqlOperation(mutations.createConversation, { input: inputUser2 }))
                                .then(result => {
                                    console.log("The second item of the convers was created")
                                    console.log(result)
                                    if (Utils.checkNested(result, "data", "createConversation", "id")) {
                                        // The second item was pushed 
                                        conversObjList.push(result.data.createConversation);
                                        resolve(conversObjList)
                                    }
                                    else {
                                        console.log("Error creating conversation: Only one convers item created")
                                        resolve(null)
                                    }
                                })
                                .catch(err => {
                                    console.log(err)
                                    resolve(null)
                                });
                        } else {
                            console.log("Fail to create conversation")
                            resolve(null)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        resolve(null)
                    });
            } catch (e) {
                console.log(e)
            }

        })
    }

    static getConversObj(userSub, user2Sub) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.conversationsByUser, { userSub: userSub, filter: { user2Sub: { eq: user2Sub } } }))
                .then(result => {
                    let items = Utils.checkNested(result, "data", "conversationsByUser", "items");
                    if (items.length > 0) {
                        resolve(items[0])
                    } else {
                        console.log("The conversation does not exist yet")
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static getConversObjList(conversId) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.conversationsByConversId, { conversId: conversId }))
                .then(result => {
                    let items = Utils.checkNested(result, "data", "conversationsByConversId", "items");
                    if (items.length > 0) {
                        resolve(items)
                    } else {
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static getMyConversations() {
        return new Promise(async (resolve, reject) => {
            let userSub = await Utils.getCurrentUserSub();
            API.graphql(graphqlOperation(queries.conversationsByUser, { userSub: userSub, limit:"500", sortDirection: "DESC" }))
                .then(result => {
                    let items = Utils.checkNested(result, "data", "conversationsByUser", "items");
                    if (items.length > 0) {
                        resolve(items)
                    } else {
                        resolve([])
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve([])
                });
        })
    }

    static getConversation(conversId) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.messagesByConversation, { conversId: conversId, sortDirection: "DESC", limit: "500" }))
                .then(result => {
                    let items = Utils.checkNested(result, "data", "messagesByConversation", "items");
                    if (items.length > 0) {
                        resolve(items)
                    } else {
                        resolve([])
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve([])
                });
        })
    }

    static getCrypKey(conversObjList) {
        return new Promise((resolve, reject) => {
            if (conversObjList && Utils.checkNested(conversObjList[0], "cryptKey")) {
                resolve(conversObjList[0].cryptKey)
            } else {
                resolve(null)
            }
        })
    }

    static getRecipentSub(conversObjList, userSub) {
        return new Promise(async (resolve, reject) => {
            let userSubToDiscard = userSub;
            if (!userSub) {
                userSubToDiscard = await Utils.getCurrentUserSub();
            }
            if (!conversObjList) {
                resolve(null)
            }
            if (conversObjList && Utils.checkNested(conversObjList[0], "userSub") !== userSubToDiscard) {
                resolve(conversObjList[0].userSub)
            } else if(conversObjList) {
                resolve(conversObjList[0].user2Sub)
            }
        })
    }

    static decryptListMessages(listMessagesCrypt, cryptKey) {
        return new Promise((resolve, reject) => {
            let simpleCrypto = new SimpleCrypto(cryptKey);
            let decryptMessagesList = listMessagesCrypt;
            for (let i = 0; i < decryptMessagesList.length; i++) {
                let decryptMessage = simpleCrypto.decrypt(decryptMessagesList[i].message)
                decryptMessagesList[i].message = decryptMessage;
            }
            resolve(decryptMessagesList)
        })
    }

    static decryptMessage(messageCrypt, cryptKey) {
        return new Promise((resolve, reject) => {
            let simpleCrypto = new SimpleCrypto(cryptKey);
            let decryptMessageObj = messageCrypt;
            let decryptMessage = simpleCrypto.decrypt(messageCrypt.message)
            decryptMessageObj.message = decryptMessage;
            resolve(decryptMessageObj)
        })
    }
}

export default Messaging;