import React, { Component } from 'react';
import './SelectCategory.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import Select from "react-select";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

// DB
import { Box, Typography, Button, Chip } from '@material-ui/core';
import { categoryList, tagList, subtagList } from '../../utils/Constants';

class SelectCategory extends Component {
    state = {
        categoryOptions: categoryList,
        categoryOptionsSelected: null,
        categoryText: null,

        tagOptions: [],
        tagOptionsSelected: null,
        tagText: null,

        subtagOptions: [],
        subtagOptionsSelected: null,
        subtagText: null,

        editing: false,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "subtagId")) {
            this.setSubtag(this.props.subtagId)
        } else if (Utils.checkNested(this, "props", "tagId")) {
            this.setTag(this.props.tagId)
        } else if (Utils.checkNested(this, "props", "category")) {
            this.setCategory(this.props.category)
        }
    }

    componentDidUpdate(prevProps) {
        let subtagIdFromProps = Utils.checkNested(this, "props", "subtagId");
        let subtagIdFromPrevProps = Utils.checkNested(prevProps, 'subtagId');

        let tagIdFromProps = Utils.checkNested(this, "props", "tagId");
        let tagIdFromPrevProps = Utils.checkNested(prevProps, 'tagId');

        let categoryFromProps = Utils.checkNested(this, "props", "category");
        let categoryFromPrevProps = Utils.checkNested(prevProps, 'category');

        if (subtagIdFromProps !== subtagIdFromPrevProps) {
            if (subtagIdFromProps) { this.setSubtag(subtagIdFromProps); }
            else if (tagIdFromProps) { this.setTag(tagIdFromProps); }
            else if (categoryFromProps) { this.setCategory(categoryFromProps); }
        } else if (tagIdFromProps !== tagIdFromPrevProps) {
            if (tagIdFromProps) { this.setTag(tagIdFromProps); }
            else if (categoryFromProps) { this.setCategory(categoryFromProps); }
        } else if (categoryFromProps !== categoryFromPrevProps) {
            if (categoryFromProps) { this.setCategory(categoryFromProps); }
        }

        let forceValidateFromProps = Utils.checkNested(this, "props", "forceValidate");
        let forceValidateFromPrevProps = Utils.checkNested(prevProps, 'forceValidate');
        if (forceValidateFromProps !== forceValidateFromPrevProps) {
            this.handleValidate();
        }
    }

    async loadTagOptions(currentCategoryValue) {
        let listObject = [];

        for (let i = 0; i < tagList.length; i++) {
            if (tagList[i].category === currentCategoryValue) {
                listObject.push(tagList[i]);
            }
        }

        this.setState({
            tagOptions: listObject,
        });
    }

    async loadSubTagOptions(currentTagId) {
        let listObject = [];

        for (let i = 0; i < subtagList.length; i++) {
            if (subtagList[i].tagId === currentTagId) {
                listObject.push(subtagList[i]);
            }
        }

        this.setState({
            subtagOptions: listObject,
        });
    }

    async setSubtag(subtagId) {
        let subtagObject = null

        for (let i = 0; i < subtagList.length; i++) {
            if (subtagList[i].id === subtagId) {
                subtagObject = subtagList[i];
            }
        }

        if (!subtagObject) return
        // From the tag set the catagory
        await this.setTag(subtagObject.tagId);

        // Set the state for subtag (reset by setTag())
        this.setState({
            "subtagOptionsSelected": subtagObject,
            "subtagText": subtagObject.label,
        });
    }

    async setTag(tagId) {
        let tagObject = null

        for (let i = 0; i < tagList.length; i++) {
            if (tagList[i].id === tagId) {
                tagObject = tagList[i];
            }
        }

        if (!tagObject) return
        // From the tag set the catagory
        await this.setCategory(tagObject.category);

        // Set the state for tag (reset by setCategory())
        this.setState({
            "tagOptionsSelected": tagObject,
            "tagText": tagObject.label,

            "subtagOptionsSelected": null,
            "subtagText": null,
        });
    }

    async setCategory(category) {
        let categoryObject = null

        for (let i = 0; i < categoryList.length; i++) {
            if (categoryList[i].value === category) {
                categoryObject = categoryList[i];
            }
        }

        if (!categoryObject) {
            if (this.state.categoryOptions.length > 0) {
                categoryObject = this.state.categoryOptions[0];
            } else {
                return
            }
        }
        this.setState({
            "categoryOptionsSelected": categoryObject,
            "categoryText": categoryObject.label,

            "tagOptionsSelected": null,
            "tagText": null,

            "subtagOptionsSelected": null,
            "subtagText": null,
        });

        this.loadTagOptions(categoryObject.value)
    }

    handleCategoryChange = selectedCategoryOption => {
        // Load tag
        this.loadTagOptions(selectedCategoryOption.value)
        // Set the country and reset the city search
        this.setState({
            "categoryOptionsSelected": selectedCategoryOption,

            "tagOptionsSelected": null,

            "subtagOptionsSelected": null,
        });
        if (this.props.alwaysOpen) { this.pushOnSelect({ category: selectedCategoryOption, tag: null, subtag: null }) }
    };

    handleTagChange = selectedTagOption => {
        // Load tag
        this.loadSubTagOptions(selectedTagOption.id)
        // Set the country and reset the city search
        this.setState({
            "tagOptionsSelected": selectedTagOption,

            "subtagOptionsSelected": null,
        });
        if (this.props.alwaysOpen) { this.pushOnSelect({ category: this.state.categoryOptionsSelected, tag: selectedTagOption, subtag: null }) }
    };

    handleSubTagChange = selectedSubTagOption => {
        // Set the country and reset the city search
        this.setState({
            "subtagOptionsSelected": selectedSubTagOption,
        });
        if (this.props.alwaysOpen) { this.pushOnSelect({ category: this.state.categoryOptionsSelected, tag: this.state.tagOptionsSelected, subtag: selectedSubTagOption }) }
    };

    async pushOnSelect(dataToPush) {
        if (this.props.onChange) {
            this.props.onChange(dataToPush);
        }
    }

    async handleValidate() {
        if (this.props.onChange) {
            this.props.onChange({ category: this.state.categoryOptionsSelected, tag: this.state.tagOptionsSelected, subtag: this.state.subtagOptionsSelected });
        }
        this.finishEditing();
    }

    handleCancel() {
        this.finishEditing();
    }

    startEditing() {
        if (Utils.checkNested(this.state.categoryOptionsSelected, "value")) this.loadTagOptions(this.state.categoryOptionsSelected.value);
        if (Utils.checkNested(this.state.tagOptionsSelected, "id")) this.loadSubTagOptions(this.state.tagOptionsSelected.id);

        this.setState({
            "editing": true,
        });
    }

    finishEditing() {
        this.setState({
            "editing": false,
        });
    }

    render() {
        let {
            categoryOptions,
            categoryOptionsSelected,
            categoryText,

            tagOptions,
            tagOptionsSelected,
            tagText,

            subtagOptions,
            subtagOptionsSelected,
            subtagText,

            editing,
        } = this.state;

        let { editable, alwaysOpen, loading } = this.props;
        let displayTag = false;
        let displaySubTag = false;

        if (tagOptions.length > 0 || tagOptionsSelected) { displayTag = true; }
        if (displayTag && tagOptionsSelected && (subtagOptions.length > 0 || subtagOptionsSelected)) { displaySubTag = true; }

        return (
            <Box className="SelectCategory" display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                {
                    editing || alwaysOpen ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em">
                                <Select isDisabled={loading} options={categoryOptions} value={categoryOptionsSelected} onChange={this.handleCategoryChange} />
                            </Box>
                            <Box>
                                {
                                    displayTag ?
                                        <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em">
                                            <Select isDisabled={loading} options={tagOptions} value={tagOptionsSelected} onChange={this.handleTagChange} />
                                        </Box>
                                        :
                                        null
                                }
                            </Box>
                            <Box>
                                {
                                    displaySubTag ?
                                        <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em">
                                            <Select isDisabled={loading} options={subtagOptions} value={subtagOptionsSelected} onChange={this.handleSubTagChange} />
                                        </Box>
                                        :
                                        null
                                }
                            </Box>
                        </Box>
                        :
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" marginRight="1em" marginBottom="0.5em">
                            <Box marginRight="0.5em">
                                {
                                    categoryText ?
                                        <Chip label={categoryText} />
                                        :
                                        <Typography>No category</Typography>
                                }
                            </Box>
                            {
                                tagText ?
                                    <Box marginRight="0.5em">
                                        <Chip label={tagText} />
                                    </Box> :
                                    null
                            }
                            {
                                subtagText ?
                                    <Box marginRight="0.5em">
                                        <Chip label={subtagText} />
                                    </Box> :
                                    null
                            }
                        </Box>
                }
                {
                    editable && !alwaysOpen ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginBottom="0.5em">
                            {
                                editing ?
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ backgroundColor: "#CC0000", marginRight: "10px" }} onClick={() => this.handleCancel()} >
                                            <ClearIcon />
                                        </Button>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleValidate()} >
                                            <DoneIcon />
                                        </Button>
                                    </Box>
                                    :
                                    <Box>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.startEditing()} >
                                            <EditIcon />
                                        </Button>
                                    </Box>
                            }
                        </Box> : null
                }
            </Box>
        );
    }
}

SelectCategory.propTypes = {
    onChange: PropTypes.func,
    category: PropTypes.string,
    tagId: PropTypes.string,
    subtagId: PropTypes.string,
    editable: PropTypes.bool,
    loading: PropTypes.bool,
    forceValidate: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
};

export default SelectCategory;