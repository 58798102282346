export const updateUserDescription = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    cognitoSub
    description
  }
}
`;

export const updateUserTitle = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    cognitoSub
    title
  }
}
`;

export const updateUserUsername = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    userName
    cognitoSub
  }
}
`;

export const updateUserPortfolio = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    cognitoSub
    portfolioImgKey
  }
}
`;

export const updateUserLocation = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    cognitoSub
    country
    city
  }
}
`;

export const updateServiceImg = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    imageKey
  }
}
`;

export const updateServiceTitle = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    title
  }
}
`;

export const updateServiceDescription = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    description
  }
}
`;

export const updateServiceLocation = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    country
    city
  }
}
`;

export const updateServiceCategory = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    category
    tag
    subTag
  }
}
`;

export const updateServicePrice = `mutation UpdateService($input: UpdateServiceInput!) {
  updateService(input: $input) {
    id
    price
    currency
    priceLabel
  }
}
`;

export const updateAgreementContent = `mutation UpdateAgreement($input: UpdateAgreementInput!) {
  updateAgreement(input: $input) {
    id
    content
    updatedAt
  }
}
`;