import React from 'react';

// Import pages
import Landing from './pages/landing/Landing.js'
import Posts from './pages/posts/Posts'
import Post from './pages/post/Post.js'
import User from './pages/user/User.js'
import Conversations from './pages/conversations/Conversations'
import Conversation from './pages/conversation/Conversation'
import Admin from './pages/admin/Admin'

// Import react router
import { Route } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import MainLayout from './layouts/mainLayout/MainLayout.js';
import Agreements from './pages/agreements/Agreements.js';
import Agreement from './pages/agreement/Agreement.js';
import NewAgreement from './pages/newAgreement/NewAgreement.js';
import Login from './pages/login/Login.js';
import Credits from './pages/credits/Credits.js';
import Tou from './pages/tou/Tou.js';
import Help from './pages/help/Help.js';
import Blog from './pages/blog/Blog.js';
import NewArticle from './pages/newArticle/NewArticle.js';
import Article from './pages/article/Article.js';

export const LandingRoute = "/";
export const PostsRoute = "/drone-services/";
export const PostRoute = "/drone-service/";
export const UserRoute = "/user/";
export const AdminRoute = "/admin/";

export const ConversationsRoute = "/conversations/";
export const ConversationRoute = "/conversation/";

export const AgreementsRoute = "/agreements/";
export const AgreementRoute = "/agreement/";
export const NewAgreementRoute = "/new-agreement/";

export const LoginRoute = "/login/";

export const HelpRoute = "/help/";

export const CreditsRoute = "/credits/";
export const TouRoute = "/tou/";

export const BlogRoute = "/drone-blog/";
export const NewArticleRoute = "/new-article/";
export const ArticleRoute = "/drone-article/";

const listUnprotectedRoute = [LoginRoute];

class Routing extends React.Component {
    listRoute() {
        return (
            <div>
                <Route path={LandingRoute} exact component={Landing} />

                <Route path={PostsRoute + ":category?/:tag?/:subtag?/:country?/:city?"} component={Posts} />
                <Route path={PostRoute + ":slug?/:id"} component={Post} />

                <Route path={UserRoute + ":id"} component={User} />

                <Route path={ConversationsRoute} component={Conversations} />
                <Route path={ConversationRoute + ":id"} component={Conversation} />

                <Route path={AgreementsRoute} component={Agreements} />
                <Route path={AgreementRoute + ":id"} component={Agreement} />
                <Route path={NewAgreementRoute + ":id"} component={NewAgreement} />

                <Route path={LoginRoute} exact component={Login} />

                <Route path={CreditsRoute} component={Credits} />

                <Route path={HelpRoute} component={Help} />

                <Route path={TouRoute} component={Tou} />

                <Route path={BlogRoute+ ":category?/:language?"} component={Blog} />
                <Route path={NewArticleRoute} component={NewArticle} />
                <Route path={ArticleRoute + ":id"} component={Article} />

                <Route path={AdminRoute} component={Admin} />
            </div>
        );
    }
    unprotected() {
        return this.listRoute();
    }
    protected() {
        return (
            <MainLayout>
                {this.listRoute()}
            </MainLayout>
        );
    }
    render() {
        let unprotect = listUnprotectedRoute.includes(this.props.location.pathname);
        let finalRoutes = this.unprotected();
        if (unprotect) { finalRoutes = this.unprotected() }
        else { finalRoutes = this.protected() }
        return finalRoutes;
    }
}

export default withRouter(Routing);