import { Auth } from 'aws-amplify';
// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../customGraphql/queries';

class Utils {
    static checkNested(obj /*, level1, level2, ... levelN*/) {
        var args = Array.prototype.slice.call(arguments, 1);

        for (var i = 0; i < args.length; i++) {
            if (!obj || !obj.hasOwnProperty(args[i])) {
                return null;
            }
            obj = obj[args[i]];
        }
        return obj;
    }

    static getCurrentUserSub() {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.attributes.sub) {
                        resolve(user.attributes.sub)
                    } else {
                        console.log(user)
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static getCurrentIdentityId() {
        return new Promise((resolve, reject) => {
            Auth.currentCredentials()
                .then(user => {
                    if (user._identityId) {
                        resolve(user._identityId)
                    } else {
                        console.log(user)
                        resolve(null)
                    }
                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }

    static getIdentityIdFromSub(sub) {
        return new Promise((resolve, reject) => {
            API.graphql(graphqlOperation(queries.getUserThumb, { cognitoSub: sub }))
                .then(result => {
                    let identityId = this.checkNested(result, "data", "getUser", "identityId");
                    resolve(identityId)
                })
                .catch(err => {
                    console.log(err)
                    resolve(null)
                });
        })
    }


    static isoDateToReadable(isoDate) {
        let readable = new Date(isoDate);
        let m = readable.getMonth();
        let d = readable.getDate();
        let y = readable.getFullYear();

        // we define an array of the months in a year
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

        // we get the text name of the month by using the value of m to find the corresponding month name
        let mlong = months[m];

        return mlong + " " + d + ", " + y;
    }
}

export default Utils;