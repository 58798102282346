import React, { Component } from 'react';
import './ImgDisplay.scss';
import { Button, CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import Resizer from 'react-image-file-resizer';

// DB
import { Storage } from "aws-amplify";

const defaultMaxHeight = 500;
const defaultMaxWidth = 500;

class ImgDisplay extends Component {
    state = {
        loading: false,
        imgPath: null,
        currentKey: null,
    };

    constructor(props) {
        super(props)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this.props, "imgKey") || Utils.checkNested(this.props, "identityId")) {
            this.getImgFromKey(this.props.imgKey);
        }
    }

    componentDidUpdate(prevProps) {
        // If the img key from the prop is update, then we get thenew image from the s3
        let keyFromProps = Utils.checkNested(this.props, 'imgKey');
        let keyFromPrevProps = Utils.checkNested(prevProps, 'imgKey');

        let identityIdFromProps = Utils.checkNested(this.props, 'identityId');
        let identityIdFromPrevProps = Utils.checkNested(prevProps, 'identityId');
        if (keyFromProps !== keyFromPrevProps || identityIdFromProps !== identityIdFromPrevProps) {
            this.getImgFromKey(keyFromProps)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let imgKeyFromProps = Utils.checkNested(this.props, 'imgKey');
        let imgKeyFromNextProps = Utils.checkNested(nextProps, 'imgKey');
        let identityIdFromProps = Utils.checkNested(this.props, 'identityId');
        let identityIdFromNextProps = Utils.checkNested(nextProps, 'identityId');
        if (imgKeyFromProps !== imgKeyFromNextProps || identityIdFromProps !== identityIdFromNextProps || this.state !== nextState) {
            return true;
        } else {
          return false;
        }
      }

    startLoading() {
        this.setState({
            "loading": true,
        });
    }

    stopLoading() {
        this.setState({
            "loading": false,
        });
    }

    async pushOnS3(uri) {
        if (this.props.imgKey === null) return
        if (uri === null) return

        // Edit the image
        let returnedkey = await Storage.put(this.props.imgKey, uri, {
            level: 'protected',
            contentType: 'image/*'
        })
            .then(result => { return result.key })
            .catch(err => console.log(err));

        // Signal the update to the parent
        this.props.onEdit(returnedkey);

        this.stopLoading();
    }

    async handleEdit(event) {
        // Check new image
        const file = event.target.files[0];
        if (!file) return

        this.startLoading();

        // Resize
        let maxHeight = defaultMaxHeight;
        let maxWidth = defaultMaxWidth;
        if (this.props.maxHeight) { maxHeight = this.props.maxHeight }
        if (this.props.maxWidth) { maxHeight = this.props.maxWidth }
        Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            'JPG',
            90,
            0,
            uri => {
                this.pushOnS3(uri)
            },
            'blob'
        );

        // Update the image displayed (no need to get it from the s3)
        this.setState({
            imgPath: URL.createObjectURL(file)
        })
    }

    async getImgFromKey(key) {
        if (!key) return null
        if (key === this.state.currentKey) return null
        if (!this.props.identityId) return null
        this.startLoading();

        let imgPathFromProps = ""
        try {
            imgPathFromProps = await Storage.get(key, { level: 'protected', identityId: this.props.identityId })
                .then(result => {
                    return result
                })
                .catch(err => {
                    console.log(err);
                    return null
                });
        } catch {
            this.setState({
                "loading": false,
            });
        }

        if (imgPathFromProps !== this.state.imgPath) {
            this.setState({
                "imgPath": imgPathFromProps,
                "currentKey": key,
                "loading": false,
            });
        }
    }

    async handleDelete() {
        this.startLoading();

        // Delete on s3
        await Storage.remove(this.props.imgKey, { level: 'protected' })
            .then(result => { return result })
            .catch(err => {
                console.log(err);
                return null
            });

        // Signal the update to the parent
        this.props.onDelete(this.props.imgKey);

        // Remove the path as their is no image for this key anymore
        this.setState({
            imgPath: ""
        })
        this.stopLoading();
    }

    render() {
        const { allowEdit, allowDelete, allowEnlarge, defaultImgPath } = this.props;
        const { loading } = this.state;

        return (
            <Box className="ImgDisplay"
                style={{
                    backgroundImage: "url(" + defaultImgPath + ")",
                }}>
                <Box className="imgToDisplay"
                    style={{
                        backgroundImage: "url(" + this.state.imgPath + ")",
                    }} > <Box className="overlay" />
                    <Box className="loading" style={loading ? { opacity: "0.8" } : {}}>
                        <CircularProgress color="secondary" className="circular-load" />
                    </Box>
                    {
                        allowEdit ?
                            <Box className="edit-control">
                                <input
                                    accept="image/jpeg"
                                    style={{ display: 'none' }}
                                    id="button-file"
                                    multiple
                                    type="file"
                                    onChange={this.handleEdit}
                                    disabled={loading}
                                />
                                <label htmlFor="button-file">
                                    <Button disabled={loading} variant="contained" size="small" color="secondary" component="span">
                                        <EditIcon />
                                    </Button>
                                </label>
                            </Box> :
                            null
                    }
                    {
                        allowDelete ?
                            <Box className="delete-control">
                                <Button onClick={this.handleDelete} disabled={loading} variant="contained" size="small" style={{ color: "#fefefe", backgroundColor: '#CC0000' }} component="span">
                                    <DeleteIcon />
                                </Button>
                            </Box> :
                            null
                    }
                    {
                        allowEnlarge ?
                            <Box className="enlarge-control">
                                <Button onClick={this.handleEnlarge} disabled={loading} variant="contained" size="small" color="secondary" component="span">
                                    <FullscreenIcon />
                                </Button>
                            </Box> :
                            null
                    }
                </Box>
            </Box >
        );
    }
}

ImgDisplay.propTypes = {
    allowEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    allowDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    allowEnlarge: PropTypes.bool,
    imgKey: PropTypes.string,
    identityId: PropTypes.string,
    defaultImgPath: PropTypes.string,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
};

export default ImgDisplay;