import React, { Component } from 'react';
import './ArticleSearch.scss';
import PropTypes from 'prop-types';
import { Box, Typography, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import LabelIcon from '@material-ui/icons/Label';
import AddIcon from '@material-ui/icons/Add';

import Select from "react-select";

import { blogCategoryList, languageList } from '../../utils/Constants';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from '../../customGraphql/queries';
import Utils from '../../utils/Utils';
import userHelper from '../../utils/userHelper';
import { NewArticleRoute } from '../../Routing';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const initLanguage = languageList[0];
const initCategory = blogCategoryList[0];

const limit = 100;
class ArticleSearch extends Component {
    state = {
        categoryOptions: blogCategoryList,
        languageOptions: languageList,

        languageOptionsSelected: initLanguage,
        categoryOptionsSelected: initCategory,

        languageObjLastSearch: null,
        categoryObjLastSearch: null,
        onlineSearch: true,

        errorMessage: null,
        loading: false,

        isWriter: false,

        isModerator: false,
        toReviewCheckbox: false,
    }

    componentDidMount() {
        this.getFromSearchUrl(this.props, null)

        this.checkWriter();
        this.checkModo();
    }


    componentDidUpdate(prevProps, prevState) {
        this.getFromSearchUrl(this.props, prevProps)
    }

    getFromSearchUrl(props, prevProps) {
        let category = Utils.checkNested(props, "match", "params", "category") ? props.match.params.category : 'ALL';
        let language = Utils.checkNested(props, "match", "params", "language") ? props.match.params.language : 'ALL';

        let prevCategory = Utils.checkNested(prevProps, "match", "params", "category") ? prevProps.match.params.category : 'ALL';
        let prevLanguage = Utils.checkNested(prevProps, "match", "params", "language") ? prevProps.match.params.language : 'ALL';

        if (category !== prevCategory || language !== prevLanguage || !prevProps) {
            let categoryObj = this.getCategoryFromValue(category);
            let languageObj = this.getLanguageFromValue(language);
            this.setState({
                categoryOptionsSelected: categoryObj,
                languageOptionsSelected: languageObj,
            })
            this.triggerSearch(categoryObj, languageObj)
        }
    }

    getLanguageFromValue(value) {
        for (let i = 0; i < languageList.length; i++) {
            if (languageList[i].value === value) return languageList[i];
        }
    }

    getCategoryFromValue(value) {
        for (let i = 0; i < blogCategoryList.length; i++) {
            if (blogCategoryList[i].value === value) return blogCategoryList[i];
        }
    }

    async checkWriter() {
        let isWriter = await userHelper.isWriter();
        this.setState({
            isWriter: isWriter,
        })
    }
    async checkModo() {
        let isModo = await userHelper.isModo();
        this.setState({
            isModerator: isModo,
        })
    }

    async triggerSearch(categoryObj, languageObj, online = true, startToken = null) {
        if (!Utils.checkNested(categoryObj, 'value')) return;
        if (!Utils.checkNested(languageObj, 'value')) return;

        if (categoryObj === this.state.categoryObjLastSearch && 
            languageObj === this.state.languageObjLastSearch &&
            online === this.state.onlineSearch) {
            return
        }

        if (this.props.isLoading) this.props.isLoading(true);

        let onlineStatus = 'online';
        if (online === false) onlineStatus = 'onReview';

        let query = customQueries.articlesByStatusShort;
        let queryName = 'articlesByStatus';
        let input = {
            limit: limit,
            sortDirection: 'DESC',
            nextToken: startToken,
            status: onlineStatus,
        }
        // Category
        if (categoryObj.value !== "ALL" && languageObj.value === "ALL") {
            query = customQueries.articlesByStatusCategoryShort;
            queryName = 'articlesByStatusCategory';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                status: onlineStatus,
                categoryPublishDate: { beginsWith: { category: categoryObj.value } },
            }
        } else if (categoryObj.value === "ALL" && languageObj.value !== "ALL") {
            // Language
            query = customQueries.articlesByStatusLanguageShort;
            queryName = 'articlesByStatusLanguage';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                status: onlineStatus,
                languagePublishDate: { beginsWith: { language: languageObj.value } },
            }
        } else if (categoryObj.value !== "ALL" && languageObj.value !== "ALL") {
            // Language and category
            query = customQueries.articlesByStatusCategoryLanguageShort;
            queryName = 'articlesByStatusCategoryLanguage';
            input = {
                limit: limit,
                sortDirection: 'DESC',
                nextToken: startToken,
                status: onlineStatus,
                categoryLanguagePublishDate: { beginsWith: { category: categoryObj.value, language: languageObj.value } },
            }
        }

        try {
            let getArticles = await API.graphql(graphqlOperation(query, input));
            let resultItems = Utils.checkNested(getArticles, 'data', queryName, 'items');
            if (!resultItems) {
                if (this.props.isLoading) this.props.isLoading(false);
                return
            }
            console.log("Search")
            this.setState({
                languageObjLastSearch: languageObj,
                categoryObjLastSearch: categoryObj,
                onlineSearch: online,
            })

            if (this.props.onResult) this.props.onResult(resultItems, categoryObj.value, languageObj.value);
            /*
            // Add the token if we are at the last element of the list and if there is a next page
            let updatedNextToken = Utils.checkNested(getPosts, 'data', queryName, 'nextToken');
            let updatedListToken = this.state.tokenList;
            if (updatedNextToken && (this.state.tokenIndex + 1 >= updatedListToken.length)) {
                updatedListToken.push(updatedNextToken)
            }
            this.setState({
                listPosts: resultItems,
                tokenList: updatedListToken,
            })
            */
        } catch (e) {
            console.log(e)
        }

        if (this.props.isLoading) this.props.isLoading(false);
    }

    handleCategoryChange = selectedCategoryOption => {
        if (selectedCategoryOption !== this.state.categoryOptionsSelected) {
            this.setState({
                categoryOptionsSelected: selectedCategoryOption,
            });
            this.triggerSearch(selectedCategoryOption, this.state.languageOptionsSelected, !this.state.toReviewCheckbox)
        }
    };

    handleLanguageChange = selectedLanguageOption => {
        if (selectedLanguageOption !== this.state.languageOptionsSelected) {
            this.setState({
                languageOptionsSelected: selectedLanguageOption,
            });
            this.triggerSearch(this.state.categoryOptionsSelected, selectedLanguageOption, !this.state.toReviewCheckbox)
        }
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.triggerSearch(this.state.categoryOptionsSelected, this.state.languageOptionsSelected, !event.target.checked)
    };

    render() {
        let {
            categoryOptions,
            categoryOptionsSelected,
            languageOptions,
            languageOptionsSelected,
            loading,
            isWriter,
            isModerator,
            toReviewCheckbox,
        } = this.state;
        return (
            <Box className="ArticleSearch">
                <Box marginBottom={3}>
                    <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems='center'>
                        <Box display='flex' alignItems='center' marginRight={2}>
                            <Box marginRight={2}><LanguageIcon /></Box>
                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                <Select isDisabled={loading} options={languageOptions} value={languageOptionsSelected} onChange={this.handleLanguageChange} />
                            </Box>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Box marginRight={2}><LabelIcon /></Box>
                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                <Select isDisabled={loading} options={categoryOptions} value={categoryOptionsSelected} onChange={this.handleCategoryChange} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {
                    isModerator ?
                        <Box flexGrow={1} style={{ color: 'white' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={toReviewCheckbox}
                                        onChange={this.handleChange('toReviewCheckbox')}
                                        value="toReviewCheckbox"
                                    />
                                }
                                label="To review"
                            />
                        </Box>
                        :
                        null
                }
                {
                    isWriter ?
                        <Box flexGrow={1} textAlign="right">
                            <Link to={NewArticleRoute} style={{ textDecoration: 'none' }}>
                                <Button color="primary" style={{ color: 'white' }} variant="contained" size="small">
                                    <AddIcon /> New article
                                </Button>
                            </Link>
                        </Box>
                        :
                        null
                }

                {
                    this.state.errorMessage ?
                        <Typography style={{ color: "red" }}>{this.state.errorMessage}</Typography>
                        :
                        null
                }
            </Box >
        );
    }
}

ArticleSearch.propTypes = {
    onResult: PropTypes.func,
    isLoading: PropTypes.func,
};

export default withRouter(ArticleSearch);