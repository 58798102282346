import React, { Component } from 'react';
import './Article.scss';
import Box from '@material-ui/core/Box';
import { Typography, Divider, TextField, Button, Container, Chip } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import LabelIcon from '@material-ui/icons/Label';
import Select from "react-select";
import { blogCategoryList, languageList } from '../../utils/Constants';
import ReactMarkdown from 'react-markdown';
import UserThumb from '../../componants/UserThumb/UserThumb';
import Footer from '../../componants/Footer/Footer';
import uuid from 'react-uuid';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { BlogRoute } from '../../Routing';
import userHelper from '../../utils/userHelper';
import Utils from '../../utils/Utils';


class Article extends Component {
    state = {
        categoryOptions: blogCategoryList,
        languageOptions: languageList,

        languageOptionsSelected: "",
        categoryOptionsSelected: "",

        content: "",
        description: "",
        title: "",
        loading: false,
        editMode: false,
        userSub: null,
        errors: [],
        url: "",
        onlineStatus: null,
        publishDate: null,

        isOwner: false,
        isModo: false,
    }

    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
        this.setupArticle();
        this.checkUser();
    }

    async checkUser() {
        let isModo = await userHelper.isModo()
        this.setState({
            isModo: isModo,
        })
    }

    async setupArticle() {
        let article_slang = this.props.match.params.id;
        try {
            let getArticle = await API.graphql(graphqlOperation(queries.getArticle, { slang: article_slang }));
            let languageOptionsSelected = this.getLanguageFromValue(getArticle.data.getArticle.language);
            let categoryOptionsSelected = this.getCategoryFromValue(getArticle.data.getArticle.category);
            let currentUser = await Utils.getCurrentUserSub();
            let isOwner = currentUser === getArticle.data.getArticle.owner;
            this.setState({
                content: getArticle.data.getArticle.content,
                description: getArticle.data.getArticle.description,
                title: getArticle.data.getArticle.title,
                userSub: getArticle.data.getArticle.owner,
                url: getArticle.data.getArticle.imgUrl,
                onlineStatus: getArticle.data.getArticle.status,
                languageOptionsSelected: languageOptionsSelected,
                categoryOptionsSelected: categoryOptionsSelected,
                publishDate: getArticle.data.getArticle.publishDate,
                isOwner: isOwner,
                slang: article_slang,
            })
        } catch (e) {
            console.log("Fail to get article")
            console.log(e)
        }
    }

    getLanguageFromValue(value) {
        for (let i = 0; i < languageList.length; i++) {
            if (languageList[i].value === value) return languageList[i];
        }
    }

    getCategoryFromValue(value) {
        for (let i = 0; i < blogCategoryList.length; i++) {
            if (blogCategoryList[i].value === value) return blogCategoryList[i];
        }
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    handleCategoryChange = selectedCategoryOption => {
        if (selectedCategoryOption !== this.state.categoryOptionsSelected) {
            this.setState({
                categoryOptionsSelected: selectedCategoryOption,
            });
        }
    };

    handleLanguageChange = selectedLanguageOption => {
        if (selectedLanguageOption !== this.state.languageOptionsSelected) {
            this.setState({
                languageOptionsSelected: selectedLanguageOption,
            });
        }
    };

    async publish(status = "onReview") {
        this.setState({
            loading: true,
        });
        // Check data
        let errors = [];
        const { title, description, content, categoryOptionsSelected, languageOptionsSelected, userSub, url } = this.state;
        const currentDate = new Date().toISOString();
        const publishDate = new Date(this.state.publishDate).toISOString();
        if (!title || title === " ") errors.push('Incorrect title');
        if (!description || description === " ") errors.push('Incorrect description');
        if (!content || content === " ") errors.push('Incorrect content');
        if (!categoryOptionsSelected.value || categoryOptionsSelected.value === "ALL") errors.push('Select category');
        if (!languageOptionsSelected.value || languageOptionsSelected.value === "ALL") errors.push('Select language');
        if (!url || url === " ") errors.push('Incorrect url');

        if (errors.length === 0) {
            // Push the new article
            try {
                let input = {
                    slang: this.state.slang,
                    title: title,
                    description: description,
                    content: content,
                    imgUrl: url,

                    publishDate: publishDate,
                    updateDate: currentDate,

                    status: status,
                    category: categoryOptionsSelected.value,
                    language: languageOptionsSelected.value,

                    owner: userSub,
                }

                await API.graphql(graphqlOperation(mutations.updateArticle, { input: input }));
                window.location.reload()
            } catch (e) {
                console.log("Fail to update article")
                console.log(e)
            }
        } else {
            this.setState({ errors: errors, });
        }

        this.setState({
            loading: false,
        });
    }
    
    async delete() { 
        try {
            let input = {
                slang: this.state.slang,
            }

            await API.graphql(graphqlOperation(mutations.deleteArticle, { input: input }));
            window.location.replace(BlogRoute)
        } catch (e) {
            console.log("Fail to delete article")
            console.log(e)
        }
    }

    toogleEditMode() {
        this.setState({
            editMode: !this.state.editMode,
        })
    }

    render() {
        const {
            description,
            url,
            content,
            loading,
            title,
            categoryOptions,
            categoryOptionsSelected,
            languageOptions,
            languageOptionsSelected,
            editMode,
            userSub,
            errors,
            onlineStatus,
            publishDate,
            isOwner,
            isModo,
        } = this.state;
        let date = new Date(publishDate).toDateString()
        return (

            <Box className="Article">
                {
                    editMode && isModo ?
                        <Box>
                            <Container maxWidth="lg">
                                <Box marginBottom={3}>
                                    <Typography style={{ marginBottom: "1em", marginTop: "2em", color: 'white' }} component="h2" variant="h2">
                                        Write a new article
                                    </Typography>
                                </Box>
                            </Container>
                            <Box className="container">
                                <Container maxWidth="md">
                                    <Box marginBottom={5}>
                                        <Typography>{date}</Typography>
                                    </Box>

                                    <Box marginBottom={5} display='flex' flexDirection="row" flexWrap="wrap" alignItems='center'>
                                        <Box display='flex' alignItems='center' marginRight={2}>
                                            <Box marginRight={2}><LanguageIcon /></Box>
                                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                                <Select isDisabled={loading} options={languageOptions} value={languageOptionsSelected} onChange={this.handleLanguageChange} />
                                            </Box>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            <Box marginRight={2}><LabelIcon /></Box>
                                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em" color={'black'}>
                                                <Select isDisabled={loading} options={categoryOptions} value={categoryOptionsSelected} onChange={this.handleCategoryChange} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Main image url</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="img_url-new-article"
                                                label="Main image url"
                                                value={url}
                                                onChange={this.handleChange("url")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Title</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="title-new-article"
                                                label="Title (100 characters max)"
                                                value={title}
                                                onChange={this.handleChange("title")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Short description</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="description-new-agreement"
                                                label="Description (500 characters max)"
                                                value={description}
                                                onChange={this.handleChange("description")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 500 }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box marginBottom={5}>
                                        <Typography variant="h5">Content</Typography>
                                        <Box><Divider /></Box>
                                        <Box>
                                            <TextField
                                                disabled={loading}
                                                id="content-new-agreement"
                                                label="Content (10000 characters max)"
                                                value={content}
                                                onChange={this.handleChange("content")}
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                inputProps={{ maxLength: 10000 }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box marginTop={4}>
                                        <Button disabled={loading} variant="contained" color="primary" onClick={() => this.toogleEditMode()} >
                                            Show result
                                        </Button>
                                    </Box>
                                </Container>
                            </Box>
                        </Box>
                        :
                        <Box>
                            <Container className="background-image" style={{ backgroundImage: 'url("' + url + '")', color: 'white' }} maxWidth="lg">
                                <Typography style={{ paddingBottom: "1em", paddingTop: "2em" }} component="h2" variant="h2">
                                    {title}
                                </Typography>
                                <Box display='flex'
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    alignItems='center'>
                                    <UserThumb
                                        userId={userSub}
                                        clickable={true}
                                        noStars={false}
                                    />
                                    <Box flexGrow={1} />
                                    <Typography>{date}</Typography>
                                </Box>
                            </Container>
                            <Box className="container">
                                <Container maxWidth="md">
                                    <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems="center">
                                        <Chip style={{marginRight: '1em'}} label={categoryOptionsSelected.label} />
                                        <Chip label={languageOptionsSelected.label} />
                                        <Box flexGrow={1} />
                                        {
                                            isModo || isOwner ?
                                                <Box marginTop={1} >
                                                    {
                                                        onlineStatus === "online" ?
                                                            <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems="center">
                                                                <Chip label='Online' color="primary" />
                                                                <Button style={{marginLeft: '1em', color: 'red'}} disabled={loading || !isModo} onClick={() => this.publish()} >
                                                                    Set on review
                                                                </Button>
                                                            </Box>
                                                            :
                                                            <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems="center">
                                                                <Chip label='Waiting review' style={{ color: 'red' }} />
                                                                <Button style={{marginLeft: '1em', color: 'green'}} disabled={loading || !isModo} onClick={() => this.publish("online")} >
                                                                    Put online
                                                                </Button>
                                                            </Box>
                                                    }
                                                </Box>
                                                :
                                                null
                                        }

                                    </Box>
                                    <Typography style={{ paddingBottom: "1em", paddingTop: "2em" }} component="h6" variant="h6">
                                        {description}
                                    </Typography>
                                    <Box style={{ paddingBottom: "1em", paddingTop: "2em", fontSize: "1.2em" }}>
                                        <ReactMarkdown source={content} />
                                    </Box>
                                    {
                                        isModo ?
                                            <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems='center' marginTop={4}>
                                                <Button disabled={loading} variant="contained" color="primary" onClick={() => this.toogleEditMode()} >
                                                    Edit
                                                </Button>
                                                <Box flexGrow={1} />
                                                <Button disabled={loading} style={{ backgroundColor: "red" }} variant="contained" color="secondary" onClick={() => this.delete()} >
                                                    Delete
                                                </Button>
                                                <Box flexGrow={1} />
                                                <Button disabled={loading} variant="contained" color="secondary" onClick={() => this.publish()} >
                                                    Publish
                                                </Button>
                                            </Box> :
                                            null
                                    }

                                    <Box style={{ color: 'red' }}>
                                        <ul>
                                            {errors.map(function (item) {
                                                return <li key={uuid()}>{item}</li>;
                                            })}
                                        </ul>
                                    </Box>
                                </Container>
                            </Box>
                        </Box>
                }
                <Footer />
            </Box >
        );
    }
}

export default Article;