import React, { Component } from 'react';
import './Conversations.scss';
import { Box, Typography, Hidden, Container } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Messaging from '../../utils/messagingHelper';
import uuid from 'react-uuid';
import UserThumb from '../../componants/UserThumb/UserThumb';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ConversationRoute } from '../../Routing';
import Utils from '../../utils/Utils';

import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from '../../graphql/subscriptions';

class Conversations extends Component {
    state = {
        listConversations: null,
    }

    // Get the conversation list
    componentDidMount() {
        if (this.state && !this.state.listConversations) {
            this.getListCOnversations()
            this.subscribeUpdateConversation();
            this.subscribeNewConversation();
        }
    }

    componentWillUnmount() {
        if (this.subscriptionUpdate) { this.subscriptionUpdate.unsubscribe() };
        if (this.subscriptionCreate) { this.subscriptionCreate.unsubscribe() };
    }

    async subscribeUpdateConversation() {
        let currentSub = await Utils.getCurrentUserSub();
        this.subscriptionUpdate = API.graphql(
            graphqlOperation(subscriptions.onUpdateConversation, { userSub: currentSub })
        ).subscribe({
            next: (conversationData) => {
                console.log("Update conversation received")
                if (Utils.checkNested(conversationData, 'value', 'data', 'onUpdateConversation')) {
                    let newConversation = conversationData.value.data.onUpdateConversation;
                    this.updateConversation(newConversation);
                }
            }
        });
    }

    async subscribeNewConversation() {
        let currentSub = await Utils.getCurrentUserSub();
        this.subscriptionCreate = API.graphql(
            graphqlOperation(subscriptions.onCreateConversation, { userSub: currentSub })
        ).subscribe({
            next: (conversationData) => {
                console.log("New conversation received")
                if (Utils.checkNested(conversationData, 'value', 'data', 'onCreateConversation')) {
                    let newConversation = conversationData.value.data.onCreateConversation;
                    this.updateConversation(newConversation);
                }
            }
        });
    }

    updateConversation(conversationObj) {
        let idFound = null;
        if (!this.state.listConversations) {
            for (let i = 0; i < this.state.listConversations.length; i++) {
                if (this.state.listConversations[i].id === conversationObj.id) {
                    idFound = i;
                }
            }
        }

        let newListConversations = this.state.listConversations;
        if (idFound !== null) {
            newListConversations.splice(idFound, 1)
        }
        newListConversations.unshift(conversationObj);
        this.setState({
            listConversations: newListConversations,
        })
    }

    async getListCOnversations() {
        let listConversations = await Messaging.getMyConversations();
        this.setState({
            listConversations: listConversations,
        })
    }

    render() {
        const { listConversations } = this.state;

        return (

            <Box className="Conversations">
                <Hidden smDown implementation="css">
                    <Container maxWidth="lg">
                        <Box marginBottom={3}>
                            <Typography variant="h2" style={{ color: "white" }}>My Conversations</Typography>
                        </Box>
                    </Container>
                </Hidden>
                <Container maxWidth="md">
                    {
                        listConversations ?
                            <Box>
                                {
                                    listConversations.length > 0 ?
                                        listConversations.map((conversObj, index) => {
                                            return <Link key={uuid()} className="link-conversation" to={ConversationRoute + conversObj.conversId}>
                                                <Box className="link-conversation-container" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                    <Box className={conversObj.lastMessageRead ? "conversation-normal" : "conversation-new"} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                                        <MailOutlineIcon className="icon" />
                                                    </Box>

                                                    <UserThumb userId={conversObj.user2Sub} clickable={false} />
                                                </Box>
                                            </Link>
                                        })
                                        :
                                        <Box className="basic-box">No conversation yet</Box>
                                }
                            </Box>
                            :
                            <Box className="basic-box">Loading</Box>
                    }
                </Container>
            </Box>
        );
    }
}

export default Conversations;