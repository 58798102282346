export const GoogleAnalyticId = "UA-149296055-1";

export const currencyList = [
    {
        value: "EUR",
        label: "€"
    },
    {
        value: "DOL",
        label: "$"
    },
    {
        value: "CHF",
        label: "chf"
    },];

export const priceLabelList = [
    {
        value: "DAY",
        label: "day"
    },
    {
        value: "HOUR",
        label: "hour"
    },
    {
        value: "EVENT",
        label: "event"
    },
    {
        value: "PIC",
        label: "picture"
    },
    {
        value: "VID",
        label: "video"
    },
];

export const categoryList = [
    {
        value: "MISSION",
        label: "Mission"
    },
    {
        value: "FORMATION",
        label: "Formation"
    },
];

export const tagList = [
    {
        id: "3",
        category: "FORMATION",
        value: "PRO",
        label: "Professional"
    },
    {
        id: "4",
        category: "FORMATION",
        value: "DISCOVERY",
        label: "Discovery"
    },
    {
        id: "5",
        category: "MISSION",
        value: "AERIAL",
        label: "Aerial Imagery and Video"
    },
    {
        id: "6",
        category: "MISSION",
        value: "DOC",
        label: "Documentary"
    },
    {
        id: "7",
        category: "MISSION",
        value: "PROMO",
        label: "Promotional video"
    },
    {
        id: "8",
        category: "MISSION",
        value: "EVENT",
        label: "Event coverage"
    },
    {
        id: "9",
        category: "MISSION",
        value: "SURVEYING",
        label: "Surveying"
    },
    {
        id: "10",
        category: "MISSION",
        value: "FARMING",
        label: "Farming"
    }
];


export const subtagList = [
    {
        id: "4",
        tagId: "9",
        value: "VISUAL",
        label: "Visual surveying"
    },
    {
        id: "5",
        tagId: "9",
        value: "3D",
        label: "3D mapping"
    },
    {
        id: "6",
        tagId: "9",
        value: "THERMAL",
        label: "Thermal"
    },
];

export const languageList = [
    {
        value: "ALL",
        label: "All"
    },
    {
        value: "FR",
        label: "Français"
    },
    {
        value: "EN",
        label: "English"
    },
    {
        value: "ZH",
        label: "中文"
    },
];

export const blogCategoryList = [
    {
        value: "ALL",
        label: "All"
    },
    {
        value: "NEWS",
        label: "News"
    },
    {
        value: "TEST",
        label: "Material test"
    },
    {
        value: "LEGAL",
        label: "Legal considarations"
    },
    {
        value: "HT",
        label: "How to"
    },
];
