// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "clickndrone-20190918111724-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://deqyfpq4i45w7.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:e8d86d12-e527-4486-aecd-312eb5698c9f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_31oZsY5f6",
    "aws_user_pools_web_client_id": "48hc4bcl1ojmtd42ocv5qolpbb",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gvkvcgf7f5fa3kqiwt77dxpi5e.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "clickndronea19fb780e9264c84b4ba48166fdaecd8-master",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
