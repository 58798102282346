import React, { Component } from 'react';
import './PostCategoryWrapper.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';
import SelectCategory from '../SelectCategory/SelectCategory';

class PostCategoryWrapper extends Component {
    state = {
        category: null,
        tagId: null,
        subtagId: null,
        editable: false,
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "category") ||
            Utils.checkNested(this, "props", "tagId") ||
            Utils.checkNested(this, "props", "subtagId") ||
            Utils.checkNested(this, "props", "editable")) {
            this.resetFromProps()
        }
    }

    componentDidUpdate(prevProps) {
        let categoryFromProps = Utils.checkNested(this, "props", "category");
        let categoryFromPrevProps = Utils.checkNested(prevProps, 'category');

        let tagIdFromProps = Utils.checkNested(this, "props", "tagId");
        let tagIdFromPrevProps = Utils.checkNested(prevProps, 'tagId');

        let subtagIdFromProps = Utils.checkNested(this, "props", "subtagId");
        let subtagIdFromPrevProps = Utils.checkNested(prevProps, 'subtagId');

        let editableFromProps = Utils.checkNested(this, "props", "editable");
        let editableFromPrevProps = Utils.checkNested(prevProps, 'editable');

        if (categoryFromProps !== categoryFromPrevProps ||
            tagIdFromProps !== tagIdFromPrevProps ||
            subtagIdFromProps !== subtagIdFromPrevProps ||
            editableFromProps !== editableFromPrevProps) {
            this.resetFromProps();
        }
    }

    resetFromProps() {
        this.setState({
            category: this.props.category,
            tagId: this.props.tagId,
            subtagId: this.props.subtagId,
            editable: this.props.editable,
        })
    }

    async onUpdate(categoryObj) {
        if (!Utils.checkNested(this.props, 'postId')) return
        if (!Utils.checkNested(categoryObj, 'category')) return
        if (!Utils.checkNested(this.props, 'country')) return
        let tagId = Utils.checkNested(categoryObj, 'tag', 'id');
        let subtagId = Utils.checkNested(categoryObj, 'subtag', 'id');
        let date = new Date().toISOString();
        try {
            let input = {
                id: this.props.postId,
                country: this.props.country,
                city: this.props.cityId,
                category: categoryObj.category.value,
                tag: tagId,
                subTag: subtagId,
                updatedAt: date,
            }

            let updateCategory = await API.graphql(graphqlOperation(mutations.updateServiceCategory, { input: input }));
            if (Utils.checkNested(updateCategory, "data", "updateService", "category")) {
                this.setState({
                    category: updateCategory.data.updateService.category,
                    tagId: updateCategory.data.updateService.tag,
                    subtagId: updateCategory.data.updateService.subTag,
                })

                if (this.props.onUpdate) {
                    this.props.onUpdate(updateCategory.data.updateService)
                }
            }
        } catch (e) {
            console.log("Fail on update category")
            console.log(e)
        }
    }

    render() {
        const { category, tagId, subtagId } = this.state;
        const { editable } = this.props;

        return (
            <Box className="PostLocationWrapper">
                <SelectCategory
                    onChange={this.onUpdate}
                    category={category}
                    tagId={tagId}
                    subtagId={subtagId}
                    editable={editable}
                    loading={false}
                />
            </Box>
        );
    }
}

PostCategoryWrapper.propTypes = {
    postId: PropTypes.string,
    country: PropTypes.string, // Needed for the update
    cityId: PropTypes.string, // Needed for the update
    editable: PropTypes.bool,
    category: PropTypes.string,
    tagId: PropTypes.string,
    subtagId: PropTypes.string,

    onUpdate: PropTypes.func,
};

export default PostCategoryWrapper;