import React, { Component } from 'react';
import './Admin.scss';
import { Typography, Button, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { cities } from './CitiesList'
import { API, graphqlOperation, Auth } from "aws-amplify";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../customGraphql/queries';
import uuid from 'react-uuid'
import Utils from '../../utils/Utils';

class Admin extends Component {
    state = {
        isAdmin: false,
    }
    componentDidMount() {
        if (this.state) {
            this.setAdmin();
        }
    }

    async setAdmin() {
        let session = await Auth.currentSession();
        let groups = Utils.checkNested(session, "idToken", "payload", "cognito:groups");
        if (groups !== null && groups.length > 0) {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === "Admin") {
                    this.setState({
                        isAdmin: true,
                    })
                }
            }
        }
    }

    async getAllCountry() {
        function uniq(a) {
            var seen = {};
            return a.filter(function (item) {
                return seen.hasOwnProperty(item) ? false : (seen[item] = true);
            });
        }

        function save(data, filename) {
            if (!data) {
                console.error('Console.save: No data')
                return;
            }

            if (!filename) filename = 'console.json'

            if (typeof data === "object") {
                data = JSON.stringify(data, undefined, 4)
            }

            var blob = new Blob([data], { type: 'text/json' }),
                e = document.createEvent('MouseEvents'),
                a = document.createElement('a')

            a.download = filename
            a.href = window.URL.createObjectURL(blob)
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
            e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(e)
        }

        let listCountriesDuplicates = [];
        let citiesLength = cities.length;
        let progression = 0;
        let finalList = [];
        for (let i = 0; i < citiesLength; i++) {
            listCountriesDuplicates.push(cities[i].country);
            if (i % 1000 === 0) {
                progression = Math.trunc(i * 100 / citiesLength);
                console.log("Create duplicated list: " + progression + "%")
            }
        }

        let uniqueList = uniq(listCountriesDuplicates).sort()
        for (let i = 0; i < uniqueList.length; i++) {
            let item = {
                value: uniqueList[i].toUpperCase(),
                label: uniqueList[i]
            }
            finalList.push(item);
        }
        console.log(finalList)
        save(finalList.sort(), "test.txt")
    }

    async pushCities() {
        let errorCities = [];
        let notFound = 0;
        let found = 0;
        let add = 0;
        let duplicatedList = [];
        let nbrCity = cities.length;
        let forcePush = false;
        let onlyFrance = false;
        console.log("This will push " + nbrCity + " to the db");
        for (let i = 0; i < cities.length; i++) {
            let city = {
                id: uuid(),
                name: cities[i].name.toUpperCase(),
                country: cities[i].country.toUpperCase()
            };

            if (onlyFrance && city.country !== "FRANCE") {
                console.log("Not France")
            } else {
                let getCity = await API.graphql(graphqlOperation(queries.cityShortByCountryAndName, { country: city.country, name: { eq: city.name } }));
                let items = Utils.checkNested(getCity, "data", "cityByCountry", "items")
                if (!forcePush && items && items.length > 0) {
                    console.log("Found")
                    found++;
                    if (items.length > 1) {
                        console.log("Duplicated");
                        duplicatedList.push(items[0]);
                        for (let i = 1; i < items.length; i++) {
                            try {
                                await API.graphql(graphqlOperation(mutations.deleteCity, { input: { id: items[i].id } }));
                            }
                            catch (error) {
                                console.log(error)
                            }
                        }
                    }
                } else {
                    notFound++;
                    try {
                        await API.graphql(graphqlOperation(mutations.createCity, { input: city }));
                        add++;
                    }
                    catch (error) {
                        try {
                            await API.graphql(graphqlOperation(mutations.createCity, { input: city }));
                            add++;
                        }
                        catch (error) {
                            console.log(error)
                            errorCities.push(city);
                        }
                    }
                }
            }
            console.log("check " + i + "/" + nbrCity + ". Not found: " + notFound + ". Add: " + add + ". Duplicated" + duplicatedList.length);
        }
        console.log("Found: " + found);
        console.log("Not found: " + notFound);
        console.log("Add: " + add);
        console.log("Duplicated" + duplicatedList.length);
        console.log(duplicatedList);
        console.log("Fail: " + errorCities.length);
        console.log(errorCities);
    }

    render() {
        return (
            <Container maxWidth="md">
                <Box id="Admin">
                    <Typography variant="h5">Admin</Typography>
                    {
                        this.state.isAdmin ?
                            <Box>
                                <Box marginBottom={2}>
                                    <Button variant="contained" size="small" color="secondary" style={{ backgroundColor: '#CC0000' }} onClick={this.pushCities} >
                                        Push all cities in the db
                                    </Button>
                                </Box>
                                <Box marginBottom={2}>
                                    <Button variant="contained" size="small" color="secondary" style={{ backgroundColor: '#CC0000' }} onClick={this.getAllCountry} >
                                        List all country
                                    </Button>
                                </Box>
                            </Box>
                            :
                            <Typography>forbidden</Typography>
                    }
                </Box>
            </Container>
        );
    }
}

export default Admin;