import React, { Component } from 'react';
import './UserHeader.scss';
import Box from '@material-ui/core/Box';

import Utils from '../../utils/Utils';
import UserShort from '../UserShort/UserShort';
import UserAvatar from '../userAvatar/UserAvatar';

import PropTypes from 'prop-types';

class UserHeader extends Component {
    render() {
        const { ownProfile, userInfoToDisplay, loading } = this.props;
        return (
            <Box id="UserHeader">
                <Box className="flex-user-avatar">
                    <UserAvatar ownProfile={ownProfile} userSub={Utils.checkNested(userInfoToDisplay, 'cognitoSub')} identityId={Utils.checkNested(userInfoToDisplay, 'identityId')} />
                </Box>
                <Box className="flex-user-short">
                    <UserShort ownProfile={ownProfile} loading={loading} userInfo={userInfoToDisplay} />
                </Box>
            </Box>
        );
    }
}

UserHeader.propTypes = {
    userInfoToDisplay: PropTypes.object,
    ownProfile: PropTypes.bool,
    loading: PropTypes.bool,
};

export default UserHeader;