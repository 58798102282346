import React, { Component } from 'react';
import './AgreementContentTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
//import * as mutations from '../../customGraphql/mutations';
import * as mutations from '../../graphql/mutations';
import { Box } from '@material-ui/core';

class AgreementContentTextField extends Component {
    state = {
        content: '',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "content")) {
            this.setContent(this.props.content)
        }
    }

    componentDidUpdate(prevProps) {
        let contentFromProps = Utils.checkNested(this, "props", "content");
        let contentFromPrevProps = Utils.checkNested(prevProps, 'content');

        if (contentFromProps !== contentFromPrevProps) {
            this.setContent(this.props.content);
        }
    }

    setContent(text) {
        this.setState({
            "content": text,
        });
    }

    async onUpdate(newContent) {
        // Update description on the cloud
        try {
            let agreementId = this.props.agreementId;
            if (!agreementId) return;

            if (newContent === "" || newContent === null) newContent = " ";

            let date = new Date().toISOString();
            let input = {
                id: agreementId,
                content: newContent,
                updatedAt: date,
            }

            let updateContent = await API.graphql(graphqlOperation(mutations.updateAgreement, { input: input }));

            // Update the child componant to display the new content
            if (Utils.checkNested(updateContent, "data", "updateAgreement", "content")) {
                this.setContent(updateContent.data.updateAgreement.content)
            }
        } catch (e) {
            console.log("Fail on update content")
            console.log(e)
        }
    }

    render() {
        const { content } = this.state;
        const { editable } = this.props;

        return (
            <Box className="AgreementContentTextField">
                <UpdatableTextField
                    text={content}
                    editable={editable}
                    typography="body1"
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={5000}
                />
            </Box>
        );
    }
}

AgreementContentTextField.propTypes = {
    agreementId: PropTypes.string,
    content: PropTypes.string,
    editable: PropTypes.bool,
};

export default AgreementContentTextField;