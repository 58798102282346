import React, { Component } from 'react';
import './UserLocationWrapper.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';
import SelectCountryCity from '../SelectCountryCity/SelectCountryCity';

class UserLocationWrapper extends Component {
    state = {
        cityId: null,
        country: null,
        editable: false,
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "country") ||
            Utils.checkNested(this, "props", "cityId") ||
            Utils.checkNested(this, "props", "editable")) {
            this.resetFromProps()
        }
    }

    componentDidUpdate(prevProps) {
        let countryFromProps = Utils.checkNested(this, "props", "country");
        let countryFromPrevProps = Utils.checkNested(prevProps, 'country');

        let cityIdFromProps = Utils.checkNested(this, "props", "cityId");
        let cityIdFromPrevProps = Utils.checkNested(prevProps, 'cityId');

        let editableFromProps = Utils.checkNested(this, "props", "editable");
        let editableFromPrevProps = Utils.checkNested(prevProps, 'editable');

        if (countryFromProps !== countryFromPrevProps ||
            cityIdFromProps !== cityIdFromPrevProps ||
            editableFromProps !== editableFromPrevProps) {
            this.resetFromProps();
        }
    }

    resetFromProps() {
        this.setState({
            cityId: this.props.cityId,
            country: this.props.country,
            editable: this.props.editable,
        })
    }

    async onUpdate(location) {
        if (!Utils.checkNested(this.props, 'userId')) return
        if (!Utils.checkNested(location, 'country')) return
        let cityIdToPush = Utils.checkNested(location, 'city', 'id');
        let date = new Date().toISOString();
        try {
            let input = {
                cognitoSub: this.props.userId,
                country: location.country,
                city: cityIdToPush,
                lastActivity: date,
            }
            let updateLocation = await API.graphql(graphqlOperation(mutations.updateUserLocation, { input: input }));
            if (Utils.checkNested(updateLocation, "data", "updateUser", "country")) {
                this.setState({
                    country: updateLocation.data.updateUser.country,
                    cityId: updateLocation.data.updateUser.city,
                })
            }
        } catch (e) {
            console.log("Fail on update location")
            console.log(e)
        }
    }

    render() {
        const { country, cityId } = this.state;
        const { editable } = this.props;

        return (
            <Box className="PostLocationWrapper">
                <SelectCountryCity
                    onChange={this.onUpdate}
                    country={country}
                    cityId={cityId}
                    editable={editable}
                    loading={false}
                />
            </Box>
        );
    }
}

UserLocationWrapper.propTypes = {
    userId: PropTypes.string,
    country: PropTypes.string,
    cityId: PropTypes.string,
    editable: PropTypes.bool,
};

export default UserLocationWrapper;