import React, { Component } from 'react';
import './AgreementTitleTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
//import * as mutations from '../../customGraphql/mutations';
import * as mutations from '../../graphql/mutations';
import { Box } from '@material-ui/core';

class AgreementTitleTextField extends Component {
    state = {
        title: '',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "title")) {
            this.setTitle(this.props.title)
        }
    }

    componentDidUpdate(prevProps) {
        let titleFromProps = Utils.checkNested(this, "props", "title");
        let titleFromPrevProps = Utils.checkNested(prevProps, 'title');

        if (titleFromProps !== titleFromPrevProps) {
            this.setTitle(this.props.title);
        }
    }

    setTitle(text) {
        this.setState({
            "title": text,
        });
    }

    async onUpdate(newTitle) {
        // Update description on the cloud
        try {
            let agreementId = this.props.agreementId;
            if (!agreementId) return;

            if (newTitle === "" || newTitle === null) newTitle = " ";

            let date = new Date().toISOString();
            let input = {
                id: agreementId,
                title: newTitle,
                updatedAt: date,
            }

            let updateTitle = await API.graphql(graphqlOperation(mutations.updateAgreement, { input: input }));

            // Update the child componant to display the new title
            if (Utils.checkNested(updateTitle, "data", "updateAgreement", "title")) {
                this.setTitle(updateTitle.data.updateAgreement.title)
            }
        } catch (e) {
            console.log("Fail on update title")
            console.log(e)
        }
    }

    render() {
        const { title } = this.state;
        const { editable } = this.props;

        return (
            <Box className="AgreementTitleTextField">
                <UpdatableTextField
                    text={title}
                    editable={editable}
                    typography="h5"
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={100}
                />
            </Box>
        );
    }
}

AgreementTitleTextField.propTypes = {
    agreementId: PropTypes.string,
    title: PropTypes.string,
    editable: PropTypes.bool,
};

export default AgreementTitleTextField;