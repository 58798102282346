import React, { Component } from 'react';
import './UserGallery.scss';
import Box from '@material-ui/core/Box';
import Gallery from '../gallery/Gallery';
import Utils from '../../utils/Utils';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../customGraphql/queries';
import * as mutations from '../../customGraphql/mutations';

import PropTypes from 'prop-types';

class UserGallery extends Component {
    state = {
        listPortfolioImgKey: [],
        userSub: null,
    }

    constructor() {
        super();
        this.onAddImg = this.onAddImg.bind(this)
        this.onDeleteImg = this.onDeleteImg.bind(this)
        if (Utils.checkNested(this, "props", "userSub")) {
            this.getUserImgKeys(Utils.checkNested(this, "props", "userSub"));
        }
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "userSub")) {
            this.getUserImgKeys(this.props.userSub)
        }
    }

    componentDidUpdate(prevProps) {
        // If the img key from the prop is update, then we get thenew image from the s3
        let subFromProps = Utils.checkNested(this, "props", "userSub");
        let subFromPrevProps = Utils.checkNested(prevProps, 'userSub');
        if (subFromProps !== subFromPrevProps) {
            this.getUserImgKeys(subFromProps)
        }
    }

    async getUserImgKeys(sub) {
        const oneUser = await API.graphql(graphqlOperation(queries.getUserPortfolio, { cognitoSub: sub }));
        let identityId = await Utils.getIdentityIdFromSub(sub);
        let listKey = Utils.checkNested(oneUser, "data", "getUser", "portfolioImgKey");
        if (!listKey) { listKey = [] }

        this.setState({
            identityId: identityId,
            userSub: sub,
            listPortfolioImgKey: listKey,
        });
    }

    async onAddImg(newKey) {
        if (!this.state.userSub) return

        // Update the user portfolio db with this new key
        let newList = this.state.listPortfolioImgKey;

        let input = {
            cognitoSub: this.state.userSub,
            portfolioImgKey: newList
        }

        try {
            await API.graphql(graphqlOperation(mutations.updateUserPortfolio, { input: input }));
        } catch (e) {
            console.log(e)
        }
    }

    async onDeleteImg(deletedKey) {
        if (!this.state.userSub) return
        // Update the user portfolio db with this new key
        let newList = this.state.listPortfolioImgKey;

        let input = {
            cognitoSub: this.state.userSub,
            portfolioImgKey: newList
        }

        try {
            await API.graphql(graphqlOperation(mutations.updateUserPortfolio, { input: input }));
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { listPortfolioImgKey, identityId } = this.state;
        const { isOwner } = this.props;
        return (
            <Box className="UserGallery">
                <Gallery
                    onAdd={this.onAddImg}
                    onDelete={this.onDeleteImg}
                    listImgKey={listPortfolioImgKey}
                    identityId={identityId}
                    allowAdd={isOwner}
                    allowDelete={isOwner}
                    maxImgAllowed={5}
                />
            </Box>
        );
    }
}

UserGallery.propTypes = {
    userSub: PropTypes.string,
    isOwner: PropTypes.bool,
};

export default UserGallery;