import React, { Component } from 'react';
import './SetPrice.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import Select from "react-select";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import { Box, Typography, Button, TextField } from '@material-ui/core';
import { currencyList, priceLabelList } from '../../utils/Constants';

class SetPrice extends Component {
    state = {
        price: "",
        priceDisplayed: "",
        currencySelected: null,
        currencyText: null,
        labelSelected: null,
        labelText: null,
        editing: false,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "price")) {
            this.setPrice(this.props.price)
        }
        if (Utils.checkNested(this, "props", "currency")) {
            this.setCurrencyFromKey(this.props.currency)
        }
        if (Utils.checkNested(this, "props", "label")) {
            this.setLabelFromKey(this.props.label)
        }
    }

    componentDidUpdate(prevProps) {
        let priceFromProps = Utils.checkNested(this, "props", "price");
        let priceFromPrevProps = Utils.checkNested(prevProps, 'price');

        let currencyFromProps = Utils.checkNested(this, "props", "currency");
        let currencyFromPrevProps = Utils.checkNested(prevProps, 'currency');

        let labelFromProps = Utils.checkNested(this, "props", "label");
        let labelFromPrevProps = Utils.checkNested(prevProps, 'label');

        if (priceFromProps !== priceFromPrevProps) {
            this.setPrice(priceFromProps);
        }
        if (currencyFromProps !== currencyFromPrevProps) {
            this.setCurrencyFromKey(currencyFromProps);
        }
        if (labelFromProps !== labelFromPrevProps) {
            this.setLabelFromKey(labelFromProps);
        }

        let forceValidateFromProps = Utils.checkNested(this, "props", "forceValidate");
        let forceValidateFromPrevProps = Utils.checkNested(prevProps, 'forceValidate');
        if (forceValidateFromProps !== forceValidateFromPrevProps) {
            this.handleValidate();
        }
    }

    setPrice(price) {
        this.setState({
            "price": price,
            "priceDisplayed": price,
        });
    }

    setCurrencyFromKey(currencyValue) {
        let currencyObj = currencyList.find(o => o.value === currencyValue);
        if (!currencyObj) return
        this.setState({
            "currencySelected": currencyObj,
            "currencyText": currencyObj.label,
        });
    }

    setLabelFromKey(labelValue) {
        let labelObj = priceLabelList.find(o => o.value === labelValue);
        if (!labelObj) return
        this.setState({
            "labelSelected": labelObj,
            "labelText": labelObj.label,
        });
    }

    handleCurrencyChange = selectedCurrencyOption => {
        this.setState({ "currencySelected": selectedCurrencyOption });
    };

    handleLabelChange = selectedLabelOption => {
        this.setState({ "labelSelected": selectedLabelOption });
    };

    handlePriceChange = event => {
        if (event.target.value < 0) return
        this.setState({
            "price": event.target.value,
        });
    };


    startLoading() {
        this.setState({
            "loading": true,
        });
    }

    stopLoading() {
        this.setState({
            "loading": false,
        });
    }

    startEditing() {
        this.setState({
            "editing": true,
        });
    }

    finishEditing() {
        this.setState({
            "editing": false,
        });
    }

    async handleValidate() {
        if (this.props.onChange) {
            this.props.onChange({
                price: Utils.checkNested(this.state, "price"),
                currency: Utils.checkNested(this.state, "currencySelected", "value"),
                label: Utils.checkNested(this.state, "labelSelected", "value"),
            });
        }
        this.finishEditing();
    }

    handleCancel() {
        this.finishEditing();
    }

    render() {
        let {
            price,
            priceDisplayed,
            currencySelected,
            currencyText,
            labelSelected,
            labelText,
            editing,
        } = this.state;
        let { editable, loading } = this.props;
        return (
            <Box className="SelectCountryCity" display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                {
                    editing ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginRight="1.5em" >
                            <Box marginRight="1.5em" marginBottom="0.5em">
                                <TextField
                                    disabled={loading}
                                    className="price-field"
                                    id="standard-number"
                                    value={price}
                                    onChange={this.handlePriceChange}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                />
                            </Box>
                            <Box minWidth="200px" marginRight="1.5em" marginBottom="0.5em">
                                <Select isDisabled={loading} options={currencyList} value={currencySelected} onChange={this.handleCurrencyChange} />
                            </Box>
                            <Box minWidth="200px" marginBottom="0.5em">
                                <Select isDisabled={loading} options={priceLabelList} value={labelSelected} onChange={this.handleLabelChange} />
                            </Box>
                        </Box>
                        :
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end">
                            <Box marginRight="0.5em"><Typography>{priceDisplayed ? priceDisplayed : "xx"}</Typography></Box>
                            <Box><Typography>{currencyText ? currencyText : "--"}</Typography></Box>
                            <Box><Typography>{labelText ? "/" + labelText : null}</Typography></Box>
                        </Box>
                }
                {
                    editable ?
                        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" marginLeft="1.5em">
                            {
                                editing ?
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ backgroundColor: "#CC0000", marginRight: "10px" }} onClick={() => this.handleCancel()} >
                                            <ClearIcon />
                                        </Button>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleValidate()} >
                                            <DoneIcon />
                                        </Button>
                                    </Box>
                                    :
                                    <Box>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.startEditing()} >
                                            <EditIcon />
                                        </Button>
                                    </Box>
                            }
                        </Box> : null
                }

            </Box>
        );
    }
}

SetPrice.propTypes = {
    onChange: PropTypes.func,
    price: PropTypes.string,
    currency: PropTypes.string,
    label: PropTypes.string,
    editable: PropTypes.bool,
    loading: PropTypes.bool,
    forceValidate: PropTypes.bool,
};

export default SetPrice;