import React, { Component } from 'react';
import './UserNameTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';

class UserNameTextField extends Component {
    state = {
        username: '...',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "username")) {
            this.setUsername(this.props.username)
        }
    }

    componentDidUpdate(prevProps) {
        let usernameFromProps = Utils.checkNested(this, "props", "username");
        let usernameFromPrevProps = Utils.checkNested(prevProps, 'username');
        if (usernameFromProps !== usernameFromPrevProps) {
            this.setUsername(this.props.username);
        }
    }

    setUsername(text) {
        this.setState({
            "username": text,
        });
    }

    async onUpdate(newUsername) {
        // Update username on the cloud
        try {
            let userSub = await Utils.getCurrentUserSub();
            if (!userSub) return;

            if (newUsername === "" || newUsername === null) newUsername = " ";

            let input = {
                cognitoSub: userSub,
                userName: newUsername
            }

            let updateUsername = await API.graphql(graphqlOperation(mutations.updateUserUsername, { input: input }));

            // Update the child componant to display the new username
            if (Utils.checkNested(updateUsername, "data", "updateUser", "userName")) {
                this.setUsername(updateUsername.data.updateUser.userName)
            }
        } catch (e) {
            console.log("Fail on update username")
            console.log(e)
        }


    }

    render() {
        const { username } = this.state;
        const { editable } = this.props;

        return (
            <UpdatableTextField
                text={username}
                editable={editable}
                typography="h4"
                onUpdate={this.onUpdate}
                maxChars={18}
            />
        );
    }
}

UserNameTextField.propTypes = {
    username: PropTypes.string,
    editable: PropTypes.bool,
};

export default UserNameTextField;