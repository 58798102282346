import React, { Component } from 'react';
import './UpdatableTextField.scss';
import { Typography, Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

class UpdatableTextField extends Component {
    state = {
        editing: false,
        textEdit: null,
        loading: false,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "text")) {
            this.setTextEdit(this.props.text)
        }
    }

    componentDidUpdate(prevProps) {
        let textFromProps = Utils.checkNested(this, "props", "text");
        let textFromPrevProps = Utils.checkNested(prevProps, 'text');
        if (textFromProps !== textFromPrevProps) {
            this.setTextEdit(this.props.text);
        }
    }

    setTextEdit(text) {
        this.setState({
            "textEdit": text,
        });
    }

    startLoading() {
        this.setState({
            "loading": true,
        });
    }

    stopLoading() {
        this.setState({
            "loading": false,
        });
    }

    startEditing() {
        this.setState({
            "editing": true,
        });
    }

    finishEditing() {
        this.setState({
            "editing": false,
        });
    }

    async handleValidate() {
        this.startLoading();

        try {
            let newText = this.state.textEdit;
            if (newText === "") newText = " ";
            if (this.props.onUpdate) {
                await this.props.onUpdate(newText);
            }
        } catch (e) {
            console.log("Fail editing")
            console.log(e)
        }
        this.stopLoading();
        this.finishEditing();
    }

    handleCancel() {
        if (Utils.checkNested(this, "props", "text")) {
            this.setTextEdit(this.props.text);
        }
        this.finishEditing();
    }

    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    render() {
        const { text, editable, multiline, typography, maxChars } = this.props;
        const { editing, loading } = this.state;

        return (
            <Box className="UpdatableTextField" display="flex" flexDirection="row" flexWrap="wrap" width="100%" alignItems="center">

                {
                    editing ?
                        <Box width={multiline ? "100%" : null}>
                            <TextField
                                id="user-text"
                                value={this.state.textEdit}
                                onChange={this.handleChange('textEdit')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                disabled={loading}
                                multiline={multiline}
                                fullWidth={multiline}

                                rowsMax="100"
                                inputProps={{ maxLength: maxChars }}
                            />
                        </Box>
                        :
                        <Box>
                            <Typography variant={typography} align="justify" style={{ whiteSpace: 'pre-line' }} component="span">
                                <ReactMarkdown source={text} />
                            </Typography>
                        </Box>
                }
                {
                    editable ?
                        <Box className={multiline ? "multiline-control" : "one-line-control"}>
                            {
                                editing ?
                                    <Box>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ backgroundColor: "#CC0000", marginRight: "10px" }} onClick={() => this.handleCancel()} >
                                            <ClearIcon />
                                        </Button>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.handleValidate()} >
                                            <DoneIcon />
                                        </Button>
                                    </Box>
                                    :
                                    <Box>
                                        <Button disabled={loading} variant="contained" size="small" color="secondary" onClick={() => this.startEditing()} >
                                            <EditIcon />
                                        </Button>
                                    </Box>
                            }
                        </Box> : null
                }
            </Box>
        );
    }
}

UpdatableTextField.propTypes = {
    onUpdate: PropTypes.func,
    editable: PropTypes.bool,
    multiline: PropTypes.bool,
    text: PropTypes.string,
    typography: PropTypes.string,
    maxChars: PropTypes.number
};

export default UpdatableTextField;