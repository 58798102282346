export const getUserShort = `query GetUser($cognitoSub: String!) {
  getUser(cognitoSub: $cognitoSub) {
    email
    userName
    cognitoSub
    identityId
    lastActivity
    description
    title
    rating
    city
    country
    isEarlyAdopter
  }
}
`;

export const getUserThumb = `query GetUser($cognitoSub: String!) {
  getUser(cognitoSub: $cognitoSub) {
    identityId
    userName
    rating
  }
}
`;

export const getUserPortfolio = `query GetUser($cognitoSub: String!) {
  getUser(cognitoSub: $cognitoSub) {
    cognitoSub
    portfolioImgKey
  }
}
`;

export const cityShortByName = `query CityByName(
  $name: String
  $filter: ModelCityFilterInput
  $limit: Int
  $nextToken: String
) {
  cityByName(
    name: $name
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      country
    }
    nextToken
  }
}
`;

export const cityShortByCountryAndName = `query CityByCountry(
  $country: String
  $name: ModelStringKeyConditionInput
  $filter: ModelCityFilterInput
  $limit: Int
  $nextToken: String
) {
  cityByCountry(
    country: $country
    name: $name
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      country
    }
    nextToken
  }
}
`;

export const servicesByUserShort = `query ServicesByUser(
  $user: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByUser(
    user: $user
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;

export const servicesByCountryShort = `query ServicesByCountry(
  $country: ID
  $categoryUpdatedAt: ModelServiceByCountryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountry(
    country: $country
    categoryUpdatedAt: $categoryUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;

export const servicesByCityShort = `query ServicesByCity(
  $country: ID
  $cityCategoryUpdatedAt: ModelServiceByCityCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCity(
    country: $country
    cityCategoryUpdatedAt: $cityCategoryUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const servicesByCountryTagShort = `query ServicesByCountryTag(
  $country: ID
  $tagUpdatedAt: ModelServiceByCountryTagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountryTag(
    country: $country
    tagUpdatedAt: $tagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const servicesByCityTagShort = `query ServicesByCityTag(
  $country: ID
  $cityTagUpdatedAt: ModelServiceByCityTagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCityTag(
    country: $country
    cityTagUpdatedAt: $cityTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const servicesByCountrySubtagShort = `query ServicesByCountrySubtag(
  $country: ID
  $subTagUpdatedAt: ModelServiceByCountrySubtagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountrySubtag(
    country: $country
    subTagUpdatedAt: $subTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;
export const servicesByCitySubtagShort = `query ServicesByCitySubtag(
  $country: ID
  $citySubTagUpdatedAt: ModelServiceByCitySubtagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCitySubtag(
    country: $country
    citySubTagUpdatedAt: $citySubTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
    }
    nextToken
  }
}
`;


// Blog
export const articlesByStatusShort = `query ArticlesByStatus(
  $status: ArticleStatus
  $publishDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatus(
    status: $status
    publishDate: $publishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      title
      description
      imgUrl
      publishDate
      status
      category
      language
    }
    nextToken
  }
}
`;
export const articlesByStatusCategoryShort = `query ArticlesByStatusCategory(
  $status: ArticleStatus
  $categoryPublishDate: ModelArticleByStatusCategoryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusCategory(
    status: $status
    categoryPublishDate: $categoryPublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      title
      description
      imgUrl
      publishDate
      status
      category
      language
    }
    nextToken
  }
}
`;
export const articlesByStatusLanguageShort = `query ArticlesByStatusLanguage(
  $status: ArticleStatus
  $languagePublishDate: ModelArticleByStatusLanguageCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusLanguage(
    status: $status
    languagePublishDate: $languagePublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      title
      description
      imgUrl
      publishDate
      status
      category
      language
    }
    nextToken
  }
}
`;
export const articlesByStatusCategoryLanguageShort = `query ArticlesByStatusCategoryLanguage(
  $status: ArticleStatus
  $categoryLanguagePublishDate: ModelArticleByStatusCategoryLanguageCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusCategoryLanguage(
    status: $status
    categoryLanguagePublishDate: $categoryLanguagePublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      title
      description
      imgUrl
      publishDate
      status
      category
      language
    }
    nextToken
  }
}
`;