import React, { Component } from 'react';
import PropTypes from 'prop-types';

// UI
import "./ContactModal.scss";

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Messaging from '../../../utils/messagingHelper';
import { Typography, TextField } from '@material-ui/core';
import Utils from '../../../utils/Utils';
import { Auth } from 'aws-amplify';

class ContactModal extends Component {
    state = {
        message: "",
        loading: false,
        error: null,
        isGuest: true,
    };

    componentDidMount() {
        this.isGuest();
    }

    handleCancel = () => {
        this.setState({ loading: false });
        this.props.onClose();
    };

    async handleConfirmation() {
        this.setState({
            loading: true,
        })
        let error = null;
        if (!this.props.recipentsub) {
            console.log("Cannot find the recipent")
            error = "Cannot find the recipent";
        }
        let currentUserSub = await Utils.getCurrentUserSub();
        if (!currentUserSub) {
            console.log("Cannot find the current user")
            error = "Cannot find the current user";
        }
        if (this.state.message == null || this.state.message === "") {
            console.log("Invalid message")
            error = "Invalid message";
        }
        if (error) {
            this.setState({
                error: error,
                loading: false,
            })
            return
        }
        let sendObject = await Messaging.sendMessage(currentUserSub, this.props.recipentsub, this.state.message);
        console.log(sendObject)
        if (Utils.checkNested(sendObject[0], "[0]", "conversId") || Utils.checkNested(sendObject[1], "message")) {
            // Redirect to Conversation
            window.location.replace("/conversation/" + sendObject[0][0].conversId);
        }
        this.props.onClose();
        this.setState({
            error: null,
            loading: false,
        })
    }


    handleChange = stateName => event => {
        this.setState({
            [stateName]: event.target.value,
        });
    };

    async isGuest() {
        let session = await Auth.currentSession();
        let groups = Utils.checkNested(session, "idToken", "payload", "cognito:groups");
        if (groups !== null && groups.length > 0) {
            for (let i = 0; i < groups.length; i++) {
                if (groups[i] === "Users") {
                    this.setState({
                        isGuest: false,
                    })
                }
            }
        }
    }

    render() {
        const {
            loading,
            message,
            isGuest,
        } = this.state;
        const { ...other } = this.props;

        let loadingDisplay;
        if (loading) {
            loadingDisplay = <DialogContent><CircularProgress /></DialogContent>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                fullWidth
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                {...other}
                className="AddPostModal"
            >
                <DialogTitle className="modal-title">Contact</DialogTitle>
                {
                    isGuest ?
                        <div>
                            <DialogContent><Typography>You cannot contact pilot as Guest, please log in first.</Typography></DialogContent>
                            <DialogActions>
                                <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'left' }} onClick={this.handleCancel} >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </div>
                        :
                        <div>
                            <DialogContent>
                                <Typography className="headline" >Message:</Typography>
                                <TextField
                                    disabled={loading}
                                    id="message-post"
                                    label="Message"
                                    value={message}
                                    onChange={this.handleChange("message")}
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    inputProps={{ maxLength: 2000 }}
                                />
                            </DialogContent>
                            {loadingDisplay}
                            <DialogActions>
                                <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'left' }} onClick={this.handleCancel} >
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="contained" size="small" color="secondary" style={{ float: 'right', backgroundColor: '#CC0000' }} onClick={() => this.handleConfirmation()} >
                                    Send
                                </Button>
                            </DialogActions>
                        </div>
                }

            </Dialog>
        );
    }
}

ContactModal.propTypes = {
    onClose: PropTypes.func,
    recipentsub: PropTypes.string,
    postId: PropTypes.string,
};

export default ContactModal;