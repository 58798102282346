import React, { Component } from 'react';
import "./Login.scss";
import { Authenticator, ConfirmSignIn, ConfirmSignUp, VerifyContact, TOTPSetup, SignIn } from 'aws-amplify-react/dist/Auth';
import AuthTheme from '../../utils/AuthTheme';
import { signUpConfig } from '../../utils/ConfigAuth';
import { Auth, Hub } from 'aws-amplify';
import { Box } from '@material-ui/core';
import SignUp from 'aws-amplify-react/dist/Auth/SignUp';
import RequireNewPassword from 'aws-amplify-react/dist/Auth/RequireNewPassword';
import ForgotPassword from 'aws-amplify-react/dist/Auth/ForgotPassword';
import Loading from 'aws-amplify-react/dist/Auth/Loading';
import { UserRoute, TouRoute } from '../../Routing';
import Utils from '../../utils/Utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Login extends Component {
  constructor() {
    super();
    Auth.signOut();
  }

  componentDidMount() {
    Hub.listen('auth', this.hubListener);
  }
  
  componentWillUnmount() {
    Hub.remove('auth', this.hubListener);
  }

  async hubListener(data) {
    if (data.payload.event === "signIn") {
      let sub = await Utils.getCurrentUserSub();
      window.location.replace(UserRoute + sub);
    }
  }

  render() {
    return (
      <div className="Login">
        <header id="header">
          <div className="container">
            <div id="logo">
              <h1>CLICK n DRONE</h1>
            </div>
          </div>
        </header>
        <Box marginTop={15}>
          <Authenticator
            //onStateChange={(authState) => this.authStatusChange(authState)}
            theme={AuthTheme}
            hideDefault={true}
          >
            <SignIn />
            <ConfirmSignIn />
            <RequireNewPassword />
            <SignUp signUpConfig={signUpConfig} />
            <ConfirmSignUp />
            <VerifyContact />
            <ForgotPassword />
            <TOTPSetup />
            <Loading />
          </Authenticator>

          <Box className="disclamer">By using ClicknDrone, you fully accept the <Link to={TouRoute}>Terms of use</Link></Box>
        </Box>
      </div >
    );
  }
}

//export default withAuthenticator(Login, false, [], null, AuthTheme, signUpConfig);
export default Login;