import React, { Component } from 'react';
import './Credits.scss';
import { Typography, Container } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import bridgeImg from "./../../img/portfolio/bridge.jpg"
import beachImg from "./../../img/portfolio/beach.jpg"
import cropImg from "./../../img/portfolio/crop.jpg"
import hideImg from "./../../img/portfolio/hide.jpg"
import houseImg from "./../../img/portfolio/house.jpg"
import logisticImg from "./../../img/portfolio/logistic.jpg"
import mavicImg from "./../../img/portfolio/mavic.jpg"
import teachImg from "./../../img/portfolio/teach.jpg"
import backgroundImg from "./../../img/aerial2Dark50.jpg"
import Footer from '../../componants/Footer/Footer';

class Credits extends Component {
    componentDidMount() {
        let node = document.querySelector("html");
        node.scrollTop = 0;
    }

    render() {
        return (
            <Container maxWidth="md" id="Credits">
                <Typography style={{ color: 'white' }} variant="h2">Credits</Typography>
                <Box className="container" >
                    <Box marginBottom={3}>
                        <Typography variant="body1">
                            ClicknDrone has been made possible thanks to hundreds of amazing people
                            giving some of their time to produce data or tools and making them available for all at no fees.
                            These ressources greatly increase the productivity and creativity on the web. This page is made to
                            thank them.
                        </Typography>
                    </Box>
                    <Box marginBottom={3}>
                        <Typography variant="h5">Pictures</Typography>
                        <Box display="flex" alignItems="center" marginTop={1} className="pictureCredit">
                            <img src={backgroundImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Image by <a href="https://pixabay.com/users/Pexels-2286921/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1850130">Pexels</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1850130">Pixabay</a></Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={bridgeImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by Pixabay from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={beachImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by
<a href="https://www.pexels.com/@tomas-malco-malik-793526?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels">Tomáš Malčo Malík</a>
                                from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={cropImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by
<a href="https://www.pexels.com/@tomfisk?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels">Tom Fisk</a>
                                from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={hideImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by Francisco Echevarria from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={houseImg} alt="" width="150" height="150" />
                            <Typography variant="body1">
                                Photo by thanhhoa tran from Pexels
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={logisticImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by sergio souza from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={mavicImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by Vitaly Vlasov from Pexels</Typography>
                        </Box>
                        <Box display="flex" alignItems="center" className="pictureCredit">
                            <img src={teachImg} alt="" width="150" height="150" />
                            <Typography variant="body1">Photo by The Lazy Artist Gallery from Pexels</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h5">Libraries</Typography>
                        <Typography>This website has been developped using mainly: <br />
                            <a href="https://reactjs.org/">React</a>, <br />
                            <a href="https://aws-amplify.github.io/docs/">Amplify</a>, <br />
                            <a href="https://material-ui.com/">Material ui</a>, <br />
                            <a href="https://www.npmjs.com/package/react-router-dom">React Router Dom</a></Typography>
                    </Box>
                </Box>
                <Footer />
            </Container>
        );
    }
}

export default Credits;