import React, { Component } from 'react';
import './PostTitleTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';

class PostTitleTextField extends Component {
    state = {
        title: 'No Title',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "title")) {
            this.setTitle(this.props.title)
        }
    }

    componentDidUpdate(prevProps) {
        let titleFromProps = Utils.checkNested(this, "props", "title");
        let titleFromPrevProps = Utils.checkNested(prevProps, 'title');
        if (titleFromProps !== titleFromPrevProps) {
            this.setTitle(this.props.title);
        }
    }

    setTitle(text) {
        this.setState({
            "title": text,
        });
    }

    async onUpdate(newtitle) {
        // Update title on the cloud
        if (!Utils.checkNested(this.props, "postId")) return
        let date = new Date().toISOString();
        try {
            if (newtitle === "" || newtitle === null) return;
            if (newtitle.length < 20) return;
            let input = {
                id: this.props.postId,
                title: newtitle,
                
                // The sort key has to be provide, and I want to update the updatedAt field so I have to provide all sort fields
                country: this.props.country,
                city: this.props.cityId,
                category: this.props.category,
                tag: this.props.tagId,
                subTag: this.props.subtagId,
                updatedAt: date,
            }

            let updateTitle = await API.graphql(graphqlOperation(mutations.updateServiceTitle, { input: input }));

            // Update the child componant to display the new title
            if (Utils.checkNested(updateTitle, "data", "updateService", "title")) {
                this.setTitle(updateTitle.data.updateService.title)
                if (this.props.onUpdate) {
                    this.props.onUpdate(updateTitle.data.updateService)
                }
            }

            
        } catch (e) {
            console.log("Fail on update title")
            console.log(e)
        }
    }

    render() {
        const { title } = this.state;
        const { editable } = this.props;

        return (
            <Box className="UserTitleTextField">
                <UpdatableTextField
                    text={title}
                    editable={editable}
                    typography="h4"
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={150}
                />
            </Box>
        );
    }
}

PostTitleTextField.propTypes = {
    title: PropTypes.string,
    editable: PropTypes.bool,
    postId: PropTypes.string,

    country: PropTypes.string, // Needed for the update
    cityId: PropTypes.string, // Needed for the update
    category: PropTypes.string, // Needed for the update
    tagId: PropTypes.string, // Needed for the update
    subtagId: PropTypes.string, // Needed for the update

    onUpdate: PropTypes.func,
};

export default PostTitleTextField;