/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($cognitoSub: String!) {
  getUser(cognitoSub: $cognitoSub) {
    email
    userName
    cognitoSub
    identityId
    portfolioImgKey
    lastActivity
    description
    title
    rating
    country
    city
    isEarlyAdopter
  }
}
`;
export const listUsers = `query ListUsers(
  $cognitoSub: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    cognitoSub: $cognitoSub
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      userName
      cognitoSub
      identityId
      portfolioImgKey
      lastActivity
      description
      title
      rating
      country
      city
      isEarlyAdopter
    }
    nextToken
  }
}
`;
export const getService = `query GetService($id: ID!) {
  getService(id: $id) {
    id
    user
    title
    description
    country
    city
    price
    currency
    priceLabel
    category
    tag
    subTag
    updatedAt
    imageKey
  }
}
`;
export const listServices = `query ListServices(
  $id: ID
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listServices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const getCity = `query GetCity($id: ID!) {
  getCity(id: $id) {
    id
    name
    country
    population
    geoHash
  }
}
`;
export const listCitys = `query ListCitys(
  $id: ID
  $filter: ModelCityFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCitys(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      country
      population
      geoHash
    }
    nextToken
  }
}
`;
export const getConversation = `query GetConversation($id: ID!) {
  getConversation(id: $id) {
    id
    userSub
    updatedAt
    lastMessageRead
    user2Sub
    conversId
    cryptKey
  }
}
`;
export const listConversations = `query ListConversations(
  $id: ID
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listConversations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      userSub
      updatedAt
      lastMessageRead
      user2Sub
      conversId
      cryptKey
    }
    nextToken
  }
}
`;
export const getMessage = `query GetMessage($id: ID!) {
  getMessage(id: $id) {
    id
    conversId
    updatedAt
    userSub
    recipentSub
    message
  }
}
`;
export const listMessages = `query ListMessages(
  $id: ID
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMessages(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      conversId
      updatedAt
      userSub
      recipentSub
      message
    }
    nextToken
  }
}
`;
export const getAgreement = `query GetAgreement($id: ID!) {
  getAgreement(id: $id) {
    id
    user1Sub
    user2Sub
    nameUser1
    nameUser2
    user1Mail
    user2Mail
    creationDate
    updatedAt
    content
    title
    validationDateUser1
    validationDateUser2
    deletableBy
    updatableByUser1
    updatableByUser2
  }
}
`;
export const listAgreements = `query ListAgreements(
  $id: ID
  $filter: ModelAgreementFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAgreements(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user1Sub
      user2Sub
      nameUser1
      nameUser2
      user1Mail
      user2Mail
      creationDate
      updatedAt
      content
      title
      validationDateUser1
      validationDateUser2
      deletableBy
      updatableByUser1
      updatableByUser2
    }
    nextToken
  }
}
`;
export const getReview = `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    agreementId
    userFromSub
    userToSub
    postedAt
    content
    rating
  }
}
`;
export const listReviews = `query ListReviews(
  $id: ID
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReviews(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      agreementId
      userFromSub
      userToSub
      postedAt
      content
      rating
    }
    nextToken
  }
}
`;
export const getArticle = `query GetArticle($slang: String!) {
  getArticle(slang: $slang) {
    slang
    articleId
    title
    description
    content
    imgUrl
    publishDate
    updateDate
    status
    category
    language
    owner
  }
}
`;
export const listArticles = `query ListArticles(
  $slang: String
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listArticles(
    slang: $slang
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const getArticleComment = `query GetArticleComment($id: ID!) {
  getArticleComment(id: $id) {
    id
    content
    articleSlang
    publishDate
    owner
  }
}
`;
export const listArticleComments = `query ListArticleComments(
  $id: ID
  $filter: ModelArticleCommentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listArticleComments(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      content
      articleSlang
      publishDate
      owner
    }
    nextToken
  }
}
`;
export const usersByUsername = `query UsersByUsername(
  $userName: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByUsername(
    userName: $userName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      email
      userName
      cognitoSub
      identityId
      portfolioImgKey
      lastActivity
      description
      title
      rating
      country
      city
      isEarlyAdopter
    }
    nextToken
  }
}
`;
export const servicesByUser = `query ServicesByUser(
  $user: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByUser(
    user: $user
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCategory = `query ServicesByCategory(
  $category: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCategory(
    category: $category
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCountry = `query ServicesByCountry(
  $country: ID
  $categoryUpdatedAt: ModelServiceByCountryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountry(
    country: $country
    categoryUpdatedAt: $categoryUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCity = `query ServicesByCity(
  $country: ID
  $cityCategoryUpdatedAt: ModelServiceByCityCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCity(
    country: $country
    cityCategoryUpdatedAt: $cityCategoryUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCountryTag = `query ServicesByCountryTag(
  $country: ID
  $tagUpdatedAt: ModelServiceByCountryTagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountryTag(
    country: $country
    tagUpdatedAt: $tagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCityTag = `query ServicesByCityTag(
  $country: ID
  $cityTagUpdatedAt: ModelServiceByCityTagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCityTag(
    country: $country
    cityTagUpdatedAt: $cityTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCountrySubtag = `query ServicesByCountrySubtag(
  $country: ID
  $subTagUpdatedAt: ModelServiceByCountrySubtagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCountrySubtag(
    country: $country
    subTagUpdatedAt: $subTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const servicesByCitySubtag = `query ServicesByCitySubtag(
  $country: ID
  $citySubTagUpdatedAt: ModelServiceByCitySubtagCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  servicesByCitySubtag(
    country: $country
    citySubTagUpdatedAt: $citySubTagUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      title
      description
      country
      city
      price
      currency
      priceLabel
      category
      tag
      subTag
      updatedAt
      imageKey
    }
    nextToken
  }
}
`;
export const cityByName = `query CityByName(
  $name: String
  $sortDirection: ModelSortDirection
  $filter: ModelCityFilterInput
  $limit: Int
  $nextToken: String
) {
  cityByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      country
      population
      geoHash
    }
    nextToken
  }
}
`;
export const cityByCountry = `query CityByCountry(
  $country: String
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCityFilterInput
  $limit: Int
  $nextToken: String
) {
  cityByCountry(
    country: $country
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      country
      population
      geoHash
    }
    nextToken
  }
}
`;
export const conversationsByUser = `query ConversationsByUser(
  $userSub: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  conversationsByUser(
    userSub: $userSub
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userSub
      updatedAt
      lastMessageRead
      user2Sub
      conversId
      cryptKey
    }
    nextToken
  }
}
`;
export const conversationsByConversId = `query ConversationsByConversId(
  $conversId: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  conversationsByConversId(
    conversId: $conversId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userSub
      updatedAt
      lastMessageRead
      user2Sub
      conversId
      cryptKey
    }
    nextToken
  }
}
`;
export const messagesByConversation = `query MessagesByConversation(
  $conversId: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMessageFilterInput
  $limit: Int
  $nextToken: String
) {
  messagesByConversation(
    conversId: $conversId
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conversId
      updatedAt
      userSub
      recipentSub
      message
    }
    nextToken
  }
}
`;
export const agreementsByUser1 = `query AgreementsByUser1(
  $user1Sub: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAgreementFilterInput
  $limit: Int
  $nextToken: String
) {
  agreementsByUser1(
    user1Sub: $user1Sub
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user1Sub
      user2Sub
      nameUser1
      nameUser2
      user1Mail
      user2Mail
      creationDate
      updatedAt
      content
      title
      validationDateUser1
      validationDateUser2
      deletableBy
      updatableByUser1
      updatableByUser2
    }
    nextToken
  }
}
`;
export const agreementsByUser2 = `query AgreementsByUser2(
  $user2Sub: ID
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAgreementFilterInput
  $limit: Int
  $nextToken: String
) {
  agreementsByUser2(
    user2Sub: $user2Sub
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user1Sub
      user2Sub
      nameUser1
      nameUser2
      user1Mail
      user2Mail
      creationDate
      updatedAt
      content
      title
      validationDateUser1
      validationDateUser2
      deletableBy
      updatableByUser1
      updatableByUser2
    }
    nextToken
  }
}
`;
export const reviewsByUserFrom = `query ReviewsByUserFrom(
  $userFromSub: ID
  $postedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByUserFrom(
    userFromSub: $userFromSub
    postedAt: $postedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agreementId
      userFromSub
      userToSub
      postedAt
      content
      rating
    }
    nextToken
  }
}
`;
export const reviewsByUserTo = `query ReviewsByUserTo(
  $userToSub: ID
  $postedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByUserTo(
    userToSub: $userToSub
    postedAt: $postedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agreementId
      userFromSub
      userToSub
      postedAt
      content
      rating
    }
    nextToken
  }
}
`;
export const reviewsByAgreementId = `query ReviewsByAgreementId(
  $agreementId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByAgreementId(
    agreementId: $agreementId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      agreementId
      userFromSub
      userToSub
      postedAt
      content
      rating
    }
    nextToken
  }
}
`;
export const articlesByArticleId = `query ArticlesByArticleId(
  $articleId: ID
  $publishDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByArticleId(
    articleId: $articleId
    publishDate: $publishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByStatus = `query ArticlesByStatus(
  $status: ArticleStatus
  $publishDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatus(
    status: $status
    publishDate: $publishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByStatusCategory = `query ArticlesByStatusCategory(
  $status: ArticleStatus
  $categoryPublishDate: ModelArticleByStatusCategoryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusCategory(
    status: $status
    categoryPublishDate: $categoryPublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByStatusLanguage = `query ArticlesByStatusLanguage(
  $status: ArticleStatus
  $languagePublishDate: ModelArticleByStatusLanguageCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusLanguage(
    status: $status
    languagePublishDate: $languagePublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByStatusCategoryLanguage = `query ArticlesByStatusCategoryLanguage(
  $status: ArticleStatus
  $categoryLanguagePublishDate: ModelArticleByStatusCategoryLanguageCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusCategoryLanguage(
    status: $status
    categoryLanguagePublishDate: $categoryLanguagePublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByStatusOwner = `query ArticlesByStatusOwner(
  $status: ArticleStatus
  $ownerPublishDate: ModelArticleByStatusOwnerCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByStatusOwner(
    status: $status
    ownerPublishDate: $ownerPublishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      slang
      articleId
      title
      description
      content
      imgUrl
      publishDate
      updateDate
      status
      category
      language
      owner
    }
    nextToken
  }
}
`;
export const articlesByArticleSlang = `query ArticlesByArticleSlang(
  $articleSlang: String
  $publishDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelArticleCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  articlesByArticleSlang(
    articleSlang: $articleSlang
    publishDate: $publishDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      content
      articleSlang
      publishDate
      owner
    }
    nextToken
  }
}
`;
