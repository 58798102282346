import React, { Component } from 'react';
import './Gallery.scss';

import { Box, Button } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import Utils from '../../utils/Utils';
import defaultImg from '../../utils/image-not-found.jpg';
import uuid from 'react-uuid';

import PropTypes from 'prop-types';
import ImgDisplay from '../imgDisplay/ImgDisplay';
import Resizer from 'react-image-file-resizer';

// DB
import { Storage } from "aws-amplify";

const defaultMaxHeight = 500;
const defaultMaxWidth = 500;

class Gallery extends Component {
  state = {
    listImgKey: [],
    loading: false,
  }

  constructor(props) {
    super(props)
    this.handleAdd = this.handleAdd.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  handleAdd(event) {
    // Check new image
    const file = event.target.files[0];
    if (!file) return

    // Resize
    let maxHeight = defaultMaxHeight;
    let maxWidth = defaultMaxWidth;
    if (this.props.maxHeight) { maxHeight = this.props.maxHeight }
    if (this.props.maxWidth) { maxHeight = this.props.maxWidth }
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPG',
      100,
      0,
      uri => {
        this.pushNewImgOnS3(uri)
      },
      'blob'
    );
  }

  async pushNewImgOnS3(uri) {
    if (uri === null) return

    // Push the image
    let returnedkey = await Storage.put(uuid(), uri, {
      level: 'protected',
      contentType: 'image/*'
    })
      .then(result => { return result.key })
      .catch(err => console.log(err));

    // Update the list
    let newList = this.state.listImgKey;
    newList.push(returnedkey);

    this.setState({
      listImgKey: newList,
    });

    /// Notify parent (db logic not handle here)
    if (this.props.onAdd) { this.props.onAdd(returnedkey) };
  }


  onDelete(deletedKey) {
    // Update the list without the deleted image
    let newList = this.state.listImgKey;

    let index = newList.indexOf(deletedKey);
    if (index > -1) {
      newList.splice(index, 1);
    }

    this.setState({
      listImgKey: newList,
    });

    // Call the parent to handle the db side if necessary
    if (this.props.onDelete) { this.props.onDelete(deletedKey) };
  }

  componentDidUpdate(prevProps) {
    // If the img key from the prop is update, then we get thenew image from the s3
    let listImgKeyFromProps = Utils.checkNested(this.props, 'listImgKey');
    let listImgKeyFromPrevProps = Utils.checkNested(prevProps, 'listImgKey');
    if (listImgKeyFromProps !== listImgKeyFromPrevProps) {
      this.updateListImgKeyState(listImgKeyFromProps)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let listImgKeyFromProps = Utils.checkNested(this.props, 'listImgKey');
    let listImgKeyFromNextProps = Utils.checkNested(nextProps, 'listImgKey');
    if (listImgKeyFromProps !== listImgKeyFromNextProps ||
      this.props.identityId !== nextProps.identityId ||
      this.props.allowAdd !== nextProps.allowAdd ||
      this.props.allowDelete !== nextProps.allowDelete ||
      this.props.onAdd !== nextProps.onAdd ||
      this.props.onDelete !== nextProps.onDelete ||
      this.props.maxImgAllowed !== nextProps.maxImgAllowed ||
      this.state !== nextState) {
      return true;
    } else {
      return false;
    }
  }

  updateListImgKeyState(newList) {
    this.setState({
      "listImgKey": newList,
    });
  }

  render() {
    const { listImgKey, loading } = this.state;
    const { allowAdd, allowDelete, maxImgAllowed, identityId } = this.props;
    return (
      <Box className="Gallery">
        {listImgKey.map(key => (
          <Box key={uuid()} className="flex-imgGallery">
            <Box className="img-container">
              <ImgDisplay
                onDelete={this.onDelete}
                allowDelete={allowDelete}
                defaultImgPath={defaultImg}
                imgKey={key}
                identityId={identityId}
              />
            </Box>
          </Box>
        ))}
        {
          allowAdd ?
            <Box key={uuid()} className="flex-imgGallery">
              <Box className="add-container">
                <input
                  accept="image/jpeg"
                  style={{ display: 'none' }}
                  id="button-add-picture-user"
                  multiple
                  type="file"
                  onChange={this.handleAdd}
                  disabled={loading || listImgKey.length > maxImgAllowed}
                />
                <label htmlFor="button-add-picture-user">
                  <Button disabled={loading || listImgKey.length >= maxImgAllowed} fullWidth className="add-button" variant="contained" size="large" color="secondary" component="span">
                    <AddAPhotoIcon />
                  </Button>
                </label>
              </Box>
            </Box>
            :
            null
        }
      </Box>
    );
  }
}

Gallery.propTypes = {
  listImgKey: PropTypes.array,
  identityId: PropTypes.string,
  allowAdd: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  maxImgAllowed: PropTypes.number
};

export default Gallery;