import React, { Component } from 'react';
import './UserThumb.scss';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import ImgDisplay from '../imgDisplay/ImgDisplay';
import { Typography, CardActionArea } from '@material-ui/core';
import Utils from '../../utils/Utils';
import defaultAvatar from '../../utils/defaultAvatar.png'
import { UserRoute } from '../../Routing';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../customGraphql/queries';
import DisplayStars from '../DisplayStars/DisplayStars';

class UserThumb extends Component {
    state = {
        rating: null,
        userName: null,
        identityId: null,
    };

    componentDidMount() {
        if (Utils.checkNested(this, "props", "userId")) {
            this.loadInfo(this.props.userId)
        }
    }

    componentDidUpdate(prevProps) {
        let userIdFromProps = Utils.checkNested(this, "props", "userId");
        let userIdFromPrevProps = Utils.checkNested(prevProps, 'userId');
        if (userIdFromProps !== userIdFromPrevProps) {
            this.loadInfo(userIdFromProps);
        }
    }

    async loadInfo(userId) {
        if (!userId) return;
        try {
            const getUser = await API.graphql(graphqlOperation(queries.getUserThumb, { cognitoSub: userId }));
            if (!Utils.checkNested(getUser, "data", "getUser")) return
            let userData = getUser.data.getUser;
            this.setState({
                userName: userData.userName,
                rating: userData.rating,
                identityId: userData.identityId,
            })
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { userName, rating, identityId } = this.state;
        const { userId, clickable, noStars } = this.props;
        return (

            <Box className="UserThumb">
                {
                    clickable ?
                        <CardActionArea component={Link} to={UserRoute + userId} width="100%">
                            <Box className="inner-container" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                                <Box className="avatar" marginRight="0.5em">
                                    <ImgDisplay
                                        defaultImgPath={defaultAvatar}
                                        imgKey={userId}
                                        identityId={identityId}
                                    />
                                </Box>
                                <Box marginRight="1em"><Typography>{userName}</Typography></Box>
                                {
                                    noStars === true ?
                                        null
                                        :
                                        <Box className="stars"><DisplayStars rating={rating} /></Box>
                                }
                            </Box>
                        </CardActionArea> :
                        <Box className="inner-container" display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                            <Box className="avatar" marginRight="0.5em">
                                <ImgDisplay
                                    defaultImgPath={defaultAvatar}
                                    imgKey={userId}
                                    identityId={identityId}
                                />
                            </Box>
                            <Box marginRight="1em"><Typography>{userName}</Typography></Box>
                            {
                                noStars === true ?
                                    null
                                    :
                                    <Box className="stars"><DisplayStars rating={rating} /></Box>
                            }

                        </Box>
                }

            </Box>
        );
    }
}


UserThumb.propTypes = {
    userId: PropTypes.string,
    clickable: PropTypes.bool,
    noStars: PropTypes.bool,
};

export default UserThumb;
