import React, { Component } from 'react';
import './UserDescriptionTextField.scss';
import Utils from '../../utils/Utils';
import PropTypes from 'prop-types';
import UpdatableTextField from '../UpdatableTextField/UpdatableTextField';

// DB
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../customGraphql/mutations';
import { Box } from '@material-ui/core';

class UserDescriptionTextField extends Component {
    state = {
        description: 'No description',
    };

    constructor() {
        super();
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        if (Utils.checkNested(this, "props", "description")) {
            this.setDescription(this.props.description)
        }
    }

    componentDidUpdate(prevProps) {
        let descriptionFromProps = Utils.checkNested(this, "props", "description");
        let descriptionFromPrevProps = Utils.checkNested(prevProps, 'description');
        if (descriptionFromProps !== descriptionFromPrevProps) {
            this.setDescription(this.props.description);
        }
    }

    setDescription(text) {
        this.setState({
            "description": text,
        });
    }

    async onUpdate(newDescription) {
        // Update description on the cloud
        try {
            let userSub = await Utils.getCurrentUserSub();
            if (!userSub) return;

            if (newDescription === "" || newDescription === null) newDescription = " ";

            let input = {
                cognitoSub: userSub,
                description: newDescription
            }

            let updateDescription = await API.graphql(graphqlOperation(mutations.updateUserDescription, { input: input }));

            // Update the child componant to display the new username
            if (Utils.checkNested(updateDescription, "data", "updateUser", "description")) {
                this.setDescription(updateDescription.data.updateUser.description)
            }
        } catch (e) {
            console.log("Fail on update description")
            console.log(e)
        }
    }

    render() {
        const { description } = this.state;
        const { editable } = this.props;

        return (
            <Box className="UserDescriptionTextField">
                <UpdatableTextField
                    text={description}
                    editable={editable}
                    typography="body1"
                    onUpdate={this.onUpdate}
                    multiline={true}
                    maxChars={5000}
                />
            </Box>
        );
    }
}

UserDescriptionTextField.propTypes = {
    description: PropTypes.string,
    editable: PropTypes.bool,
};

export default UserDescriptionTextField;